import Api from "../../Api";
import { handleError } from "./commonAction";
import {
  ENDPOINTS,
  ACTION_TYPE,
  APPLICATION_CONSTANTS,
  ROUTES,
} from "../../Services/index";
import {
  createUserWithEmailAndPasswordHandler,
  authWithCustomToken,
  signOut,
} from "../../Services/Util/authFirebase";
// import { useHistory } from "react-router-dom";

export const login = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.LOGIN, body);
    // await createUserWithEmailAndPasswordHandler(
    //   "auth@signage.com",
    //   "r&BFWXB_+k59D,[^"
    // );
    if (res.data && res.data.user && res.data.user.fbToken) {
      let fbToken = res.data.user.fbToken;
      let idToken = await authWithCustomToken(fbToken);

      await dispatch({
        type: ACTION_TYPE.LOGIN,
        payload: { data: res.data, idToken },
      });
      return res;
    }
    // await dispatch({
    //   type: ACTION_TYPE.LOGIN,
    //   payload: { data: res.data },
    // });
    // return res;
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};

export const signUp = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.SIGNUP, body);

    dispatch({
      type: ACTION_TYPE.SIGNUP,
      payload: res.data,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    if (res?.data?.status) {
      return res?.data?.status;
    }
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};
export const contactUs = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.ContactUs, body);

    dispatch({ type: "LOADING_COMPLETED" });
    if (res?.data?.status) {
      return res?.data?.status;
    }
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};
// export const signUp = (body) => async (dispatch) => {

export const logOut = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });

    await signOut();

    dispatch({
      type: ACTION_TYPE.LOGOUT_USER,
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (err) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(err));
  }
};

export const matchToken = () => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    // try {
    let userData = await JSON.parse(
      localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA)
    );
    let token = localStorage.getItem(APPLICATION_CONSTANTS.TOKEN);
    let idToken = localStorage.getItem(APPLICATION_CONSTANTS.ID_TOKEN);

    Api.get(
      ENDPOINTS.MATCH_TOKEN +
        `?email=${userData?.email}&token=${token}&idToken=${idToken}&id=${userData?.id}`
    )
      .then(async (res) => {
        if (res.data.status === false) {
          dispatch({ type: "LOGOUT_USER" });

          resolve(res?.data?.status);
        }
        let fbToken = res.data.user.fbToken;
        let newIdToken = await authWithCustomToken(fbToken);

        await dispatch({
          type: "LOGIN",
          payload: { data: res.data, idToken: newIdToken },
        });

        resolve(res?.data?.status);
      })
      .catch((err) => {
        dispatch(handleError(err));
        // // const history = useHistory();
        // // history.push(ROUTES.Login);
        // dispatch({ type: "LOGOUT_USER" });
      });
  });
};
