import React, { useState } from "react";
import Contctimage from "../../Images/commonImages/contactImgRight.png";
import "./ContactUs.css";
// import { AuthAction } from "../../ReduxStore/Actions/index";
import { AuthAction } from "../../ReduxStore/Actions/index";
import { useDispatch } from "react-redux";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { validateContactForm } from "./Validation";
import { useHistory } from "react-router";
import { ROUTES } from "../../Services/Constant/index";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const Contact = () => {
	const [state, setState] = useState({ fullName: "", email: "" });
	const dispatch = useDispatch();
	const history = useHistory();
	const [errorData, setErrorData] = useState({
		fullName: "",
		email: "",
		message: "",
	});

	const sendInfo = async (e) => {
		e.preventDefault();
		const result = await validateContactForm(state);
		if (result?.isFormValid) {
			setErrorData({
				fullName: "",
				email: "",
				message: "",
			});
			const status = await dispatch(AuthAction.contactUs(state));
			if (status) history.push(ROUTES.Home);
		} else {
			setErrorData(result.error);
		}
		// if (state && state.email && state.email !== "" && state.email !== " ") {
		//
		// }
	};
	function handleChange(event) {
		const { name, value } = event.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	}
	return (
		<div className="contact">
			<Header trans="white" color="black" boxShadow="0px 0px 6px 2px #d9cbcb" />

			<div className="contactdiv p-t-90">
				<div className="container">
					<div className="oclear d-flex shadowdiv newShadowDiv">
						<div className="flexdivs newContactFormLHS">
							{/* <img src={Contctimage} alt="Contact us" className="contctimage" /> */}
							<div className="newContactUS">
								<p className="newContactHeading">CONTACT US</p>
								<h5 className="newContactSubheading">
									We would love to hear from you
								</h5>
								<MailOutlineIcon
									style={{ color: "#F8C446", fontSize: "40px" }}
								/>{" "}
								&nbsp; info@savvsignage.com
							</div>
						</div>
						<div className="flexdivs flexdiv2 newContactFormRHS">
							{/* <h1 className="mainhead">CONTACT US</h1> */}
							<form>
								<div className="form-group">
									<label>Name</label>
									<input
										type="text"
										onChange={handleChange}
										className="form-control"
										name="fullName"
									/>
									{errorData?.fullName ? (
										<p className="error-validation">{errorData?.fullName}</p>
									) : null}
								</div>

								<div className="form-group">
									<label>Email</label>
									<input
										type="email"
										onChange={handleChange}
										className="form-control"
										name="email"
									/>
									<p className="error-validation">{errorData?.email}</p>
								</div>
								<div className="form-group">
									<label>Message</label>
									<textarea
										onChange={handleChange}
										rows="3"
										className="form-control"
										name="message"
									></textarea>
									<p className="error-validation">{errorData?.message}</p>
								</div>
								<div className="form-group1 text-center">
									<button className="submitbtn" onClick={sendInfo}>
										{" "}
										Send
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
export default Contact;
