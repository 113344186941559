import React, { Component } from "react";
import "../Home.css";
import CancelIcon from "@material-ui/icons/Cancel";
// import { Player } from "video-react";

const PopUp = (props) => {
  console.log("inside POpuppp", props);

  return (
    <section>
      <div>
        <div className="modall">
          <div className="modallinside">
            <CancelIcon
              style={{
                color: "#fdc400",
                float: "right",
                cursor: "pointer",
                fontSize: "25px !important",
              }}
              onClick={() => {
                props.setPopup(false);
              }}
            ></CancelIcon>
            <video
              className="iframee"
              disablePictureInPicture="true"
              playsInline
              autoPlay
              loop
              muted
            >
              <source
                src="https://savvsignagefiles.s3.us-east-2.amazonaws.com/website/homePageVideo.mp4"
                type="video/mp4"
              />
            </video>

            {/* <div>
                    <iframe className="iframee" src="https://www.youtube.com/embed/GqcjLOR6PU8?rel=0&enablejsapi=1" ></iframe>
                    </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};
export default PopUp;
