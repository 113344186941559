import React, { useEffect, useState } from "react";
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";
import "./widgetModal.css";
import LocationOnSharpIcon from "@material-ui/icons/LocationOnSharp";
import { useSelector, useDispatch } from "react-redux";
import { TemplateAction } from "../../../ReduxStore/Actions";
import Loader from "../../../Services/Util/loader";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { ROUTES } from "../../../Services";
const WidgetModal = (props) => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.authReducer.data);

  const [screenName, setScreenName] = useState();

  const scheduleList = useSelector((state) => state.scheduleReducer.list);

  const [error, setError] = useState();
  const loading = useSelector((state) => state.commonReducer.loading);
  const [locationSelected, setLocationSelected] = useState(false);
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const handleSelect = async (value) => {
    setError("");
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setLocationSelected(true);
    setAddress(value);
    setCoordinates(latLng);
  };

  const handleSave = async (e) => {
    if (screenName == undefined || screenName === "") {
      console.log("insss");
      setError({
        screenName: "Enter Name",
      });
      return;
    } else if (locationSelected === false) {
      setError({
        screenName: "Choose a location from the suggestion",
      });
      return;
    }
    const params = {
      templateName: screenName,
      userId: data,
      lat: coordinates.lat,
      long: coordinates.lng,
    };

    dispatch(TemplateAction.saveWidget(params)).then(() => {
      props.onSave();
    });
  };
  const handleChange = (e) => {
    console.log(e, "hhh");
    const a = locationSelected == true ? setLocationSelected(false) : null;
    setAddress(e);
  };

  return (
    <div className="widgetScreen-modal ">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="widgetScreen-modal-container"
      >
        <Modal.Header className="col-md-12 " closeButton>
          <Modal.Title
            className="widgetScreen-modal-1"
            id="contained-modal-title-vcenter"
          >
            Edit Widget
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="widgetScreen-modal-body">
          <div className="d-flex flex-column">
            <div className="d-flex  align-items-baseline justify-content-end mb-3 inputbox5 ">
              <label>Name*</label>
              <input
                className="widgetScreen-modal-input"
                type="text"
                value={screenName}
                onChange={(e) => {
                  setError("");
                  setScreenName(e.target.value);
                }}
              />
            </div>
            <p className="errMsgModal text-center">{error?.screenName}</p>

            {/* <p className="errMsgModal">{error?.media}</p> */}

            <PlacesAutocomplete
              value={address}
              onChange={(e) => handleChange(e)}
              onSelect={handleSelect}
              // onClickAway={handleClickAway}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="d-flex flex-row align-items-baseline justify-content-end mb-3">
                  <label>Location*</label>
                  <div
                    className="d-flex flex-column align-items-baseline justify-content-end"
                    style={{ width: "74%" }}
                  >
                    <input
                      {...getInputProps({ placeholder: "Type address" })}
                      placeholder="Type address"
                      className="widgetScreen-modal-input-location"
                    />
                    <div
                      style={
                        address !== "" && suggestions.length > 0
                          ? {
                              fontSize: 14,
                              width: "100%",
                              overflow: "hidden",
                              boxShadow: "0px 0px 4px 2px #bab7aa",
                              display: "block",
                            }
                          : null
                      }
                    >
                      {loading ? <div>...loading</div> : null}

                      {suggestions.map((suggestion, index) => {
                        const className = "justify-content-start";
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#f7f7f7"
                            : "#fff",
                          borderBottom: "1px solid #f8f8f8",
                          borderLeft: "1px solid #f8f8f8",
                          borderRight: "1px solid #f8f8f8",
                          textAlign: "Left",
                          justifyContent: "flex-start",
                          padding: 3,
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          position: "relative",
                          margin: "0 5px",
                          width: "calc(97%)",
                        };
                        if (index <= 5)
                          return (
                            <div
                              className="d-flex flex-row"
                              {...getSuggestionItemProps(suggestion, {
                                style,
                                // className,
                              })}
                            >
                              <LocationOnSharpIcon style={{ color: "grey" }} />{" "}
                              <div
                                style={{ fontWeight: "bold", width: "auto" }}
                              >
                                {suggestion.description.split(", ")[0]}
                              </div>
                              <div className="description">
                                {", "}{" "}
                                {suggestion.description
                                  .split(", ")
                                  .splice(1)
                                  .join(", ")}
                              </div>
                            </div>
                          );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex widgetScreen-button justify-content-center">
            <div className="col-sm-6 text-center">
              <Button className="widgetScreenButton3" onClick={props.onHide}>
                Cancel
              </Button>
              <Button className="widgetScreenButton4" onClick={handleSave}>
                {loading ? <Loader loading={loading} /> : "Save"}
              </Button>
              {/* <p className="errMsg">{error}</p> */}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default WidgetModal;
