import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TemplateAction } from "../../ReduxStore/Actions";
import { Toast } from "react-bootstrap";
import { Divider, Grid, Popover } from "@material-ui/core";
import "./index.css";
import FavoriteIcon from "@material-ui/icons/Delete";
import HearderBar from "../../component/headerBar/Header";
import Loader from "../../Services/Util/loader";
import Playlist from "../../Images/commonImages/Playlist.png";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SelectScreen from "../modal/SelectScreenModal/selectScreen";
import AlertDialog from "../../component/AlertDialog/alertDialog";
import AddCircleSharpIcon from "@material-ui/icons/AddCircleSharp";
import EditIcon from "@material-ui/icons/Edit";

import PreviewTemplate from "../Template/previewTemplate";
const MyDesigns = (props) => {
  const dispatch = useDispatch();

  const [toast, setToast] = React.useState(false);
  const template = useSelector((state) => state.templateReducer);
  const data = useSelector((state) => state.authReducer.data);
  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const loading = useSelector((state) => state.commonReducer.loading);
  const [selectTemplate, setSelectedTemplate] = useState();
  const [showSlectScreen, setShowSelectScreen] = useState(false);
  const [screenModalData, setScreenModalData] = useState();
  const [open, setOpen] = React.useState({
    state: false,
    user: "none",
  });
  const [privewModal, setPrivewModal] = useState({
    state: false,
    user: "none",
  });
  useEffect(() => {
    if (data != "") dispatch(TemplateAction.getUserTemplate({ userId: data }));
  }, [data]);

  const handleDelete = async (e, template) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen({
      state: false,
    });

    setSelectedTemplate(template.id);
    let params = {
      templateId: template.id,
      userId: data,
    };

    const res = await dispatch(TemplateAction.deleteTemplate(params));
    res
      ? await dispatch(TemplateAction.getUserTemplate({ userId: data }))
      : setToast(true);
  };

  const handleShowPreview = (e, templateDetails) => {
    e.preventDefault();
    e.stopPropagation();
    setPrivewModal({
      state: true,
      user: templateDetails,
    });
  };
  return (
    <div className="template">
      <div className="row">
        <div className="col-md-2 col-sm-2 col-lg-2">{/* <SideNav /> */}</div>
        <div className="col-md-10 col-lg-10 col-sm-9 myDesignTab">
          <div>
            <HearderBar title="My Designs" searchd="none" />
          </div>
          <Divider className="mt-4" />
          <div className="template-body">
            <Toast
              className="TemplateToast"
              show={toast}
              onClose={() => setToast(false)}
              delay={9000}
              autohide
            >
              <Toast.Header className="TemplateToastBody">
                <strong className="mr-auto">{errorMessage}</strong>
              </Toast.Header>
            </Toast>
            <AlertDialog
              open={open}
              handleClose={() =>
                setOpen({
                  state: false,
                  user: "none",
                })
              }
              message="Are you sure you want to Delete?"
              button1="Yes"
              button2="No"
              handleDelete={handleDelete}
            />
            <PreviewTemplate
              //   className="screen-modal1"
              show={privewModal.state}
              onHide={() => {
                setPrivewModal(false);
              }}
              template={privewModal.user}
            />
            <SelectScreen
              // className="content-modal"
              show={showSlectScreen}
              screenModalData={screenModalData}
              onHide={() => setShowSelectScreen(false)}
            />
            <Grid
              className="data ml-2 myDesignTab"
              container
              item
              xs={12}
              spacing={3}
            >
              {template?.dataUser?.data?.length > 0
                ? template?.dataUser?.data.map((user) => (
                    <div className="card1">
                      <img
                        className="myTempImg"
                        onClick={(e) => handleShowPreview(e, user)}
                        src={user.url}
                        alt="John"
                      />

                      <div className="templateDesc px-2 py-2 d-flex">
                        {" "}
                        {user.name}
                        <div className="d-flex ">
                          {user.component !== null ? (
                            <EditIcon
                              className="editicon7"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                props.history.push({
                                  pathname: `/design/${user.id}`,

                                  state: {
                                    type: "user",
                                    component: JSON.parse(user?.component),
                                    name: user.name,
                                    id: user.id,
                                  },
                                });
                              }}
                            />
                          ) : null}

                          {loading && selectTemplate === user.id ? (
                            <Loader loading={loading} />
                          ) : (
                            <FavoriteIcon
                              className="del0"
                              style={{ color: "red", cursor: "pointer" }}
                              onClick={() =>
                                setOpen({
                                  state: true,
                                  user: user,
                                })
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                : null}

              {template?.dataUser?.data?.length === 0 ? (
                <div className="playlistBody col-md-12 text-center mt-5 mb-3">
                  <h2>Organize your Designs.</h2>
                  <h5>
                    Maintain your Designs with your Templates funtionality.
                  </h5>
                  <img
                    className="playlistImg mt-5"
                    src={Playlist}
                    alt="content img"
                  />
                </div>
              ) : null}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDesigns;
