import React, { Component } from "react";
import { v4 } from "uuid";
import { fabric } from "fabric";

import EventHandler from "./eventHandler";

class Handler extends Component {
  constructor(options) {
    super(options);
    this.handler = null;
    this.canvas = null;
    this.id = null;
    this.width = null;
    this.height = null;
    this.onSelect = null;
    // this.onModified = null;
    // this.onRemove = null;
    this.eventHandler = null;

    this.initialize(options);

    this.state = {
      id: v4(),
    };
  }

  initialize = (options) => {
    this.initOption(options);
    this.initCallback(options);
    this.initHandler();
  };

  initOption = (options) => {
    this.id = options.id;
    this.canvas = options.canvas;
    this.width = options.width;
    this.height = options.height;
  };

  initCallback = (options) => {
    this.onAdd = options.onAdd;
    // console.log("onModified  callback before:>> ", this.onModified);
    // this.onTooltip = options.onTooltip;
    // this.onZoom = options.onZoom;
    // this.onContext = options.onContext;
    // this.onClick = options.onClick;
    this.onModified = options.onModified;
    // console.log("onModified  callback after:>> ", this.onModified);
    // this.onDblClick = options.onDblClick;
    this.onSelect = options.onSelect;
    this.onSave = options.onSave;
    // this.onRemove = options.onRemove;
    // this.onTransaction = options.onTransaction;
    // this.onInteraction = options.onInteraction;
    // this.onLoad = options.onLoad;
  };

  initHandler = () => {
    // this.drawingHandler = new DrawingHandler(this)
    // this.workareaHandler = new WorkareaHandler(this);
    // this.imageHandler = new ImageHandler(this);
    // this.chartHandler = new ChartHandler(this);
    // this.elementHandler = new ElementHandler(this);
    // this.cropHandler = new CropHandler(this);
    // this.animationHandler = new AnimationHandler(this);
    // this.contextmenuHandler = new ContextmenuHandler(this);
    // this.tooltipHandler = new TooltipHandler(this);
    // this.zoomHandler = new ZoomHandler(this);
    // this.interactionHandler = new InteractionHandler(this);
    // this.transactionHandler = new TransactionHandler(this);
    // this.gridHandler = new GridHandler(this);
    // this.portHandler = new PortHandler(this);
    // this.linkHandler = new LinkHandler(this);
    // this.nodeHandler = new NodeHandler(this);
    // this.alignmentHandler = new AlignmentHandler(this);
    // this.guidelineHandler = new GuidelineHandler(this);
    this.eventHandler = new EventHandler(this);
    // this.shortcutHandler = new ShortcutHandler(this);
    console.log("this.canvas :>> ", this.canvas);
  };

  DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  saveCanvasImage = (
    option = { name: "New Image", format: "png", quality: 1 }
  ) => {
    const { onSave } = this;
    const dataUrl = this.canvas.toDataURL(option);

    if (dataUrl) {
      const file = this.DataURIToBlob(dataUrl);
      onSave(file);

      //   const anchorEl = document.createElement("a");
      //   anchorEl.href = dataUrl;
      //   anchorEl.download = `${option.name}.png`;
      //   document.body.appendChild(anchorEl); // required for firefox
      //   anchorEl.click();
      //   anchorEl.remove();
    }
  };

  onRemove = (target) => {
    console.log(
      "this.canvas.getActiveObject() :>> ",
      this.canvas.getActiveObject()
    );
    const activeObject = this.canvas.getActiveObject();
    // this.canvas.loadFromJSON(
    //   require("./sample.json"),
    //   function () {
    //     // this.canvas.renderAll();
    //   },
    //   function (o, object) {
    //     console.log(o, object);
    //   }
    // );
    this.canvas.remove(activeObject);
    const { onSelect } = this;
    onSelect(null);
  };

  add = (obj, centered) => {
    const { editable, onAdd, gridOption, objectOption, onSelect } = this;
    console.log("in add :>> ", obj);
    this.addTarget(obj).then((createdObj) => {
      console.log("createdObj :>> ", obj, createdObj);
      if (createdObj) this.canvas.setActiveObject(createdObj);
      this.canvas.renderAll();
      // var json = this.canvas.toObject(["id"]);
      // console.log("json :>> ", JSON.stringify(json));

      // onAdd(createdObj);

      return createdObj;
    });

    // if(obj.type === "image"){
    //     this.onAdd(obj)
    //     // this.addImage(obj)
    // }
  };

  set = (key, value) => {
    const { onSelect } = this;
    console.log("key,value :>> ", key, value);
    const activeObject = this.canvas.getActiveObject();
    console.log("activeObject in set :>> ", activeObject);
    if (!activeObject) {
      return;
    }
    activeObject.set(key, value);
    activeObject.setCoords();
    this.canvas.requestRenderAll();

    // this.onModified(key, value, activeObject);
    onSelect(activeObject);

    // const { id, superType, type, player, width, height } = activeObject
    // if (superType === 'element') {
    //     if (key === 'visible') {
    //         if (value) {
    //             activeObject.element.style.display = 'block';
    //         } else {
    //             activeObject.element.style.display = 'none';
    //         }
    //     }
    //     const el = this.elementHandler.findById(id);
    //     // update the element
    //     this.elementHandler.setScaleOrAngle(el, activeObject);
    //     this.elementHandler.setSize(el, activeObject);
    //     this.elementHandler.setPosition(el, activeObject);
    //     if (type === 'video' && player) {
    //         player.setPlayerSize(width, height);
    //     }
    // }
  };

  //   select = (obj, find) => {
  //     let findObject = obj;
  //     if (find) {
  //         findObject = this.find(obj);
  //     }
  //     if (findObject) {
  //         this.canvas.discardActiveObject();
  //         this.canvas.setActiveObject(findObject);
  //         this.canvas.requestRenderAll();
  //     }
  // };

  // find = (obj) => this.findById(obj.id);

  //   addImage = (obj) => {
  //     const { objectOption } = this;
  //     const { filters = [], ...otherOption } = obj;
  //     const image = new Image();
  //     if (obj.src) {
  //         image.src = obj.src;
  //     }
  //     const createdObj = new fabric.Image(image, {
  //         ...objectOption,
  //         ...otherOption,
  //     })
  //     createdObj.set({
  //         filters: this.imageHandler.createFilters(filters),
  //     });
  //     this.setImage(createdObj, obj.src || obj.file);
  //     return createdObj;
  // };

  addTarget = (target) => {
    // console.log("target inb onAdd in handler :>> ", target.type, this.id);
    return new Promise((resolve, reject) => {
      switch (target.type) {
        case "iText":
          console.log("target inb onAdd in handler :>> ");

          const iText = new fabric.IText("Tap and Type", {
            left: 50,
            top: 100,
            fontFamily: "arial",
            fill: "#333",
            fontSize: 50,
            transparentCorners: false,
            ...target,
          });
          console.log("target selected at start :>> ", target);
          this.canvas.add(iText);
          // this.canvas.renderAll();
          resolve(iText);
          break;

        case "rect":
          var rect = new fabric.Rect({
            height: 280,
            width: 200,
            fill: "#fdc400",
            transparentCorners: false,
            ...target,
          });
          this.canvas.add(rect);
          // this.canvas.renderAll();
          resolve(rect);
          break;

        case "circle":
          var circle = new fabric.Circle({
            radius: 100,
            fill: "#F48024",
            //   scaleY: 0.5,
            top: 100,
            left: 100,
            transparentCorners: false,
            ...target,
          });
          this.canvas.add(circle);

          resolve(circle);
          break;

        case "image":
          let image;
          if (target["imgType"] === "img") {
            image = new fabric.Image.fromURL(
              target["idBase64"],
              (img) => {
                console.log(
                  "this.canvas.getWidth() / img.width :>> ",
                  this.canvas.getWidth() / img.width,
                  img
                );
                img.set({
                  scaleX: this.canvas.getWidth() / 2 / img.width, //new update
                  scaleY: this.canvas.getHeight() / 2 / img.height, //new update
                  transparentCorners: false,
                  ...target,
                });
                this.canvas.add(img);
                resolve(img);
              },
              { crossOrigin: "Anonymous" }
            );
          } else if (target["imgType"] === "bg") {
            image = new fabric.Image.fromURL(
              target["idBase64"],

              (img) => {
                img.set({
                  // width: this.canvas.getWidth(),
                  // height: this.canvas.getHeight(),
                  originX: "left",
                  scaleX: this.canvas.getWidth() / img.width, //new update
                  scaleY: this.canvas.getHeight() / img.height, //new update
                  transparentCorners: false,
                  originY: "top",
                  ...target,
                });
                this.canvas.setBackgroundImage(
                  img,
                  this.canvas.renderAll.bind(this.canvas)
                );
                resolve();
              },
              { crossOrigin: "Anonymous" }
            );
          }

          break;
      }
    });
  };
}

export default Handler;
