import firebase from "./firebase";

export const createUserWithEmailAndPasswordHandler = async (
  email,
  password
) => {
  try {
    const user = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    console.log("user :>> ", user);
    return;
  } catch (error) {
    console.log("error in firebase auth :>> ", error);
    return;
  }
};

export const authWithCustomToken = async (token) => {
  console.log(
    "token in method :>> ",

    typeof token,
    token
  );

  try {
    const userCredential = await firebase.auth().signInWithCustomToken(token);
    const user = userCredential.user;
    console.log(
      "user in authWithCustomToken :>> ",

      userCredential
    );
    const idToken = await firebase.auth().currentUser.getIdToken();
    return idToken;
  } catch (error) {
    var errorCode = error.code;
    var errorMessage = error.message;
    console.log("error in authWithCustomToken :>> ", errorMessage);
    return error;
  }
};

export const signOut = async () => {
  try {
    const signedOut = await firebase.auth().signOut();
    console.log(" firebase signedOut :>> ", signedOut);
    return true;
  } catch (error) {
    console.log("error in signOut :>> ", error);
    return error;
  }
};
