import { act } from "react-dom/cjs/react-dom-test-utils.production.min";
import { ACTION_TYPE } from "../../Services/index";
export const initialState = {
  data: [],
  databyid: [],
  status: [],
};

export const brandingReducer = (state = initialState, action) => {
  switch (action.type) {
    // case ACTION_TYPE.CONTENT_POST:
    // return {
    // ...state,
    // data: action.payload,
    // };
    case ACTION_TYPE.ADD_BRANDING:
      return {
        ...state,
        status: action.payload,
      };
    case ACTION_TYPE.GET_BRANDING:
      return {
        ...state,
        data: action.payload,
      };

    case ACTION_TYPE.GET_BRANDING_ID:
      return {
        ...state,
        databyid: action.payload,
      };
    default:
      return state;
  }
};
