import { ACTION_TYPE, APPLICATION_CONSTANTS } from "../../Services/index";
export const initialState = {
  data: [],
};

export const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    // case ACTION_TYPE.CONTENT_POST:
    //   return {
    //     ...state,
    //     data: action.payload,
    //   };
    case ACTION_TYPE.CONTENT_GET:
      return {
        ...state,
        data: action.payload.data,
      };

    default:
      return state;
  }
};
