import React, { Component } from "react";
import { Route, Redirect, useLocation, useRouteMatch } from "react-router-dom";
// import SideBar from "../DashboardModule/sideNav/SideNav";
import { APPLICATION_CONSTANTS, ROUTES } from "../Services/index";
// import Sidenav from "../DashboardModule/sideNav/SideNav";
import Sidenav from "../DashboardModule/sideNav/SideNav";

const dontShowAfterLogin = [];
const CMS = [];
let match, match2;
const admin = [];
// let location = useLocation();
class PrivateGuard extends Component {
  dontShowAfterLogin = [
    ROUTES.Signup,
    ROUTES.Login,
    ROUTES.EmailVerify,
    ROUTES.ForgetPassword,
    ROUTES.ResetPassword,
  ];
  CMS = [
    ROUTES.Screen,
    ROUTES.Content,
    ROUTES.Playlists,
    ROUTES.Template,
    ROUTES.Branding,
    ROUTES.Scheduling,
    ROUTES.Profile,
    ROUTES.EditTemplate,
    ROUTES.NewPlaylist,
    ROUTES.EditPlaylist,
    ROUTES.AddEvent,
  ];
  admin = [ROUTES.ViewUser, ROUTES.UserProfile];

  render() {
    const route = this.props.location?.pathname;
    console.log("route in privateRoutes line :>> ", route);

    // match2 = useRouteMatch("/playlist/edit/:id");
    // let match = useRouteMatch("/template/edit/:id");

    let returnData = "";
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("userData") &&
      localStorage.getItem(APPLICATION_CONSTANTS.ID_TOKEN) &&
      localStorage.getItem("Role") == 2 &&
      !this.CMS.includes(route)
    ) {
      returnData = (
        <>
          <Route {...this.props} />
          {console.log("0j")}
        </>
      );
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("userData") &&
      localStorage.getItem(APPLICATION_CONSTANTS.ID_TOKEN)
    ) {
      if (
        !this.dontShowAfterLogin.includes(route) &&
        !this.CMS.includes(route) &&
        !this.admin.includes(route)
      ) {
        returnData = (
          <>
            {console.log("06", route, this.props, ROUTES.UserProfile)}

            <Route {...this.props} />
          </>
        );
      } else if (this.CMS.includes(route)) {
        console.log("route included :>> ", route);
        returnData = (
          <>
            <Sidenav />
            <Route {...this.props} />
          </>
        );
      } else {
        returnData = (
          <>
            {console.log("0", localStorage.getItem("Role"))}

            <Redirect
              to={{
                pathname: ROUTES.Home,
                state: { from: this.props.location },
              }}
            />
          </>
        );
      }
    } else {
      console.log("line 96");
      if (this.dontShowAfterLogin.includes(route)) {
        returnData = (
          <>
            {console.log("09")}

            <Route {...this.props} />
          </>
        );
      } else {
        console.log("line106");
        returnData = (
          <>
            {console.log("06")}

            <Redirect
              to={{
                pathname: ROUTES.Login,
                state: { from: this.props.location },
              }}
            />
          </>
        );
      }
    }

    return <div>{returnData}</div>;
  }
}

export default PrivateGuard;
