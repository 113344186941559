import Api from "../../Api";

import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";
//post content
export const postSchedule = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.SCHEDULEPOST, params)
      .then((res) => {
        console.log("profile ", res);
        // console.log(res, params, "res in contentpost");

        dispatch({
          type: ACTION_TYPE.SCHEDULE_POST,
          payload: res.data,
        });
        resolve(res?.data);
        console.log(res.data, "kk");
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};

export const getSchedule = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.SCHEDULEGET, params)
      .then((res) => {
        console.log("profile ", res);
        // console.log(res, params, "res in contentpost");

        dispatch({
          type: ACTION_TYPE.SCHEDULE_GET,
          payload: res.data,
        });
        resolve(res?.data);
        console.log(res.data, "kk");
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};

export const getScheduleById = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    console.log(params, "iii");
    Api.post(ENDPOINTS.SCHEDULEGETBYID, params)
      .then((res) => {
        console.log("profile ", res);
        // console.log(res, params, "res in contentpost");

        dispatch({
          type: ACTION_TYPE.SCHEDULE_GET_BY_ID,
          payload: res.data,
        });
        resolve(res?.data);
        console.log(res.data, "kk");
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};

export const updateSchedule = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    console.log(params, "iii");
    Api.post(ENDPOINTS.SCHEDULEUPDATE, params)
      .then((res) => {
        console.log("profile ", res);
        // console.log(res, params, "res in contentpost");

        // dispatch({
        //   type: ACTION_TYPE.SCHEDULE_GET_BY_ID,
        //   payload: res.data,
        // });
        resolve(res?.data);
        console.log(res.data, "kk");
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
