import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatColorTextIcon from "@material-ui/icons/FormatColorText";
import FormatStrikethroughIcon from "@material-ui/icons/FormatStrikethrough";
import PremEditTemplate from "./premEditTemplate";
import { HexColorPicker } from "react-colorful";
import { Link } from "react-router-dom";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import imageBlue from "../../../Images/commonImages/imageBlue.jpg";
import dblTap from "../../../Images/commonImages/dblTap2.png";
import dblTapContent from "../../../Images/commonImages/dblTapContent.png";
import axios from "axios";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "./editTemplate.css";
import { TemplateAction } from "../../../ReduxStore/Actions";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../../Services";
import { Prompt } from "react-router";
const EditTemplateNew = (props) => {
  const logoRef = useRef();
  const contentLogoRef = useRef();
  const dispatch = useDispatch();
  const imgRef = React.createRef();
  const [exit, setExit] = useState(true);
  const [selectedFile, setSelectedFile] = useState();
  const [selectedContentFile, setSelectedContentFile] = useState();
  const [profileImagePreview, setProfileImagePreview] = useState();
  // const [fontSize, setFontSize] = React.useState("25px");
  // const [fontWeight, setFontWeight] = React.useState("normal");
  // const [decoration, setDecoration] = React.useState("none");
  // const [style, setStyle] = React.useState("normal");
  // const [textAlign, setTextAlign] = React.useState("left");
  // const [strike, setStrike] = React.useState("none");
  const [text, setText] = React.useState("Enter Title");
  const [content, setContent] = useState("Enter Content");
  // const [textColor, setTextColor] = useState("black");
  // const [font, setFont] = React.useState({
  // fontFamilyName: [],
  // });
  // const [family, setfamily] = useState("poppins");
  const [width, setwidth] = useState({
    width: "",
    height: "",
  });

  const [boxes, setBoxes] = useState([
    { x: 220, y: 20, w: 0, h: 50, text: text, type: "title" },

    {
      x: 10,
      y: 10,
      w: 100,
      h: 100,
      url: dblTap,
      type: "logo",
    },
    { x: 220, y: 120, w: 0, h: 200 },
  ]);
  // const [color, setColor] = React.useState("#000000");
  const [isTitleColorDisplay, setTitleColorDisplay] = React.useState("none");
  const [isContentColorDisplay, setContentColorDisplay] =
    React.useState("none");
  const [tName, setTName] = useState("");
  const [error, setError] = useState("");
  const [state, setState] = useState({
    fontWeight: "normal",
    fontStyle: "normal",
    fontFamily: "poppins",
    fontSize: "25px",
    fontColor: "#000000",
    contentFontWeight: "normal",
    contentFontStyle: "normal",
    contentFontFamily: "poppins",
    contentFontSize: "25px",
    contentFontColor: "#000000",
  });
  const [setIsBackButtonClicked, isBackButtonClicked] = useState(false);
  const data = useSelector((state) => state.authReducer.data);
  const { id } = useSelector((state) => state.authReducer.authData);

  const templateById = useSelector(
    (state) => state.templateReducer.dataById[0]
  );

  useEffect(() => {
    setContent(templateById?.Content);
    setText(templateById?.Title);
  }, [templateById]);
  console.log(templateById, "tempkjh :>>");

  const onBackButtonEvent = (e) => {
    e.preventDefault();

    if (!isBackButtonClicked) {
      if (window.confirm("Do you want to save your changes")) {
        setIsBackButtonClicked(true);
        // Your custom logic to page transition, like react-router-dom history.push()
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setIsBackButtonClicked(false);
      }
    }
  };

  const alertUser = (e) => {
    console.log("alerted");
    e.preventDefault();

    e.returnValue = "";
  };

  useEffect(() => {
    // let body = { templateId: props.match.params.id };
    // dispatch(TemplateAction.getAdminTemplateById(body));
    window.addEventListener("beforeunload", alertUser);
    // window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);

    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);
  useEffect(() => {
    console.log("userId :>> ", id);
    if (typeof id !== "undefined") {
      console.log("userId :>> ", id);
      let body = { templateId: props.match.params.id };
      dispatch(TemplateAction.getAdminTemplateById(body));
    }
  }, [id]);

  var img = new Image();
  useEffect(() => {
    img.src = templateById?.url;
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      console.log("this.width :>> ", this.width);
      if (templateById.type === "image") {
        let newState = [...boxes];
        newState[2].w = this.width;
        newState[2].y = this.height - newState[2].h;
        newState[2].x = this.width / 2 - newState[2].w / 2;
        // newState[2].h = this.height / 2;
        setBoxes(newState);
      }
      // else if (templateById.type === "text") {
      //   let newState = [...boxes];
      //   newState[2].w = 120;
      //   newState[2].x = this.width / 2 - newState[2].w / 2;
      //   // newState[2].y = this.height - newState[2].h;
      //   // newState[2].h = this.height / 2;
      //   setBoxes(newState);
      // }
      if (templateById) {
        if (templateById.type === "text") {
          let newState = [...boxes];
          newState[2].text = templateById?.Content;
          newState[2].type = "contentText";
          newState[2].x = this.width / 2 - newState[2].w / 2;
          setBoxes(newState);
        } else if (templateById.type === "image") {
          let newState = [...boxes];
          newState[2].url = dblTapContent;
          newState[2].type = "contentImage";
          setBoxes(newState);
          // setBoxes({ ...boxes[2], url: dblTap });
        }
      }
      setwidth({
        width: this.width,
        height: this.height,
      });
    };
  }, [templateById?.url]);
  // to live the bold function
  const handleStyle = (e, name) => {
    name == "fontStyle"
      ? state.fontStyle == "normal"
        ? setState({ ...state, [name]: "italic" })
        : setState({ ...state, [name]: "normal" })
      : state.fontWeight == "normal"
      ? setState({ ...state, [name]: "bold" })
      : setState({ ...state, [name]: "normal" });
  };

  //to get text on template
  const handleChange = (e) => {
    console.log("in habndlechange", e.target.name);
    if (e.target.name === "title") setText(e.target.value);
    else if (e.target.name === "content") setContent(e.target.value);
    // handleTextWidth(e.target.value)
  };
  // console.log(state, "state");
  const handleTextWidth = (width) => {
    const newIds = boxes.slice(); //copy the array
    newIds[0]["text"] = text; //execute the manipulations
    newIds[0]["w"] = width;

    // newIds[2]["w"] = width;
    // newIds[2]["w"] = width;
    setBoxes(newIds);
  };

  const handleContentWidth = (width, type) => {
    console.log("width,type in handlecontentwidth :>> ", width, type);
    const newIds = boxes.slice();
    console.log("width, type :>> ", width, type);
    if (type === "text") {
      newIds[2]["text"] = content;
      newIds[2]["w"] = width;
    }

    setBoxes(newIds);
    // else if(type === "image")
  };

  // set the style of color palet
  const handleContentColorClick = () => {
    isContentColorDisplay == "none"
      ? setContentColorDisplay("flex")
      : setContentColorDisplay("none");
  };

  // set the style of color palet
  const handleTitleColorClick = () => {
    isTitleColorDisplay == "none"
      ? setTitleColorDisplay("flex")
      : setTitleColorDisplay("none");
  };
  //when name of templAte is changed
  const handleChangeName = (e) => {
    setTName(e.target.value);
  };

  const showContentPreview = (e) => {
    console.log("selectedFile in showContentPreview :>> ", selectedContentFile);
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedContentFile(dblTapContent);
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedContentFile(e.target.files[0]);
  };

  useEffect(() => {
    console.log(
      "selectedContentFile in useeffect :>> ",
      typeof selectedContentFile
    );
    if (!selectedContentFile) {
      setProfileImagePreview(dblTapContent);
      return;
    }
    if (typeof selectedContentFile == "string") return;
    const objectUrl = URL.createObjectURL(selectedContentFile);
    const newIds = boxes.slice(); //copy the array
    newIds[2]["url"] = objectUrl; //execute the manipulations

    setBoxes(newIds);

    // setProfileImagePreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedContentFile]);

  const showPreview = (e) => {
    console.log("selectedFile in showpreview :>> ", selectedFile);
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(dblTap);
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    console.log("selectedFile in useeffect :>> ", typeof selectedFile);
    if (!selectedFile) {
      setProfileImagePreview(dblTap);
      return;
    }
    if (typeof selectedFile == "string") return;
    const objectUrl = URL.createObjectURL(selectedFile);
    const newIds = boxes.slice(); //copy the array
    newIds[1]["url"] = objectUrl; //execute the manipulations

    setBoxes(newIds);

    // setProfileImagePreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleLogoDbl = (e) => {
    logoRef.current.click();
  };

  const handleContentLogoDbl = (e) => {
    contentLogoRef.current.click();
  };

  const handleBoxChange = (img, index) => {
    const newIds = boxes.slice(); //copy the array
    newIds[index]["objUrl"] = img; //execute the manipulations

    setBoxes(newIds);
  };

  const finishSave = () => {
    // setExit(false);
    setTimeout(() => {
      props.history.push(ROUTES.MyDesign);
    }, 2000);
  };

  const handleNoTemplateName = () => {
    setError("Please Enter Template Name");
  };
  console.log(state, "font");

  // const onBackButtonEvent = (e) => {
  //   e.preventDefault();

  //   if (!isBackButtonClicked) {
  //     if (window.confirm("Do you want to save your changes")) {
  //       setIsBackButtonClicked(true);
  //       // Your custom logic to page transition, like react-router-dom history.push()
  //     } else {
  //       window.history.pushState(null, null, window.location.pathname);
  //       setIsBackButtonClicked(false);
  //     }
  //   }
  // };

  return (
    <div className="edit-template edittempnewuniqclass">
      <Prompt when={exit} message="Changes you made may not be saved" />
      <div className="row">
        <div className="col-md-12">
          <div className="header-edit-template d-flex mt-4 row">
            <div className="col-md-1 text-center  ">
              <Link to={ROUTES.Template}>
                <ArrowBackIcon className="backArrow" />
              </Link>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6 tHead mr-5 ">
              <h4 className="templateName ">{templateById?.name}</h4>
            </div>
          </div>
          <Divider variant="fullWidth" />
          <div className="body-edit-template">
            <div className="row">
              <div className="col-md-9">
                <div className="row tempEditNew">
                  <div className="col-md-12 mt-3 ">
                    {/* <div className="d-flex">
                      <input
                        className="tInput"
                        type="text"
                        onChange={handleChangeName}
                        placeholder="Enter Template Name"
                        value={tName}
                      />
                      <Link to={ROUTES.Template}>
                        {" "}
                        <Button className="ButtonEtemp">Cancel</Button>
                      </Link>
                      <Link>
                        {" "}
                        <Button
                          className="ButtonEtempsave ml-4 "
                        //  onClick={handleClickSave}
                        >
                          Save
                        </Button>
                      </Link>
                      
                    </div> */}
                  </div>

                  {/* {error != "" &&
{error}
} */}

                  <div className="col-md-10 mt-4" id="theText">
                    <PremEditTemplate
                      // imgBg={props?.location.state?.url}
                      imgBg={templateById?.url}
                      templateType={templateById?.type}
                      fontText={text}
                      contentType={templateById?.type}
                      contentText={content}
                      boxes={boxes}
                      handleBoxChange={handleBoxChange}
                      handleLogoDbl={handleLogoDbl}
                      handleContentLogoDbl={handleContentLogoDbl}
                      finishSave={finishSave}
                      handleExit={() => {
                        console.log("in handleExit");
                        setExit(false);
                      }}
                      handleCancel={() => {
                        setTimeout(() => {
                          props.history.push(ROUTES.Template);
                        }, 2000);
                      }}
                      // width={props.location.state.width}
                      // height={props.location.state.height}
                      // align={textAlign}
                      templateName={tName}
                      handleChangeName={handleChangeName}
                      handleTextWidth={handleTextWidth}
                      handleContentWidth={handleContentWidth}
                      userId={data}
                      handleNoTemplateName={handleNoTemplateName}
                      error={error}
                      // bold={state.fontWeight}
                      // fontSize={state.fontSize}
                      // family={state.fontfamily}
                      // color={state.fontColor}
                      // textStyle={state.fontStyle}
                      state={state}

                      // handleSave={handleSave}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-3 rightNav">
                <h3 className=" headingEdit text-center ">Properties</h3>
                <div className="gridediting d-flex flex-row ">
                  {/* <Grid container className="gridEdit ">
<Button>
<FormatAlignLeftIcon
onClick={(e) => handleText(e, "left")}
/>{" "}
</Button>
{/ <Divider orientation="vertical" flexItem /> /}
{/* <Button>
<FormatAlignCenterIcon
onClick={(e) => handleText(e, "center")}
/>
</Button> */}

                  {/* <Button>
<FormatAlignRightIcon
onClick={(e) => handleText(e, "right")}
/>
</Button> */}

                  <Grid container className="gridEdit">
                    <Button
                      onClick={(e) => handleStyle(e, "fontWeight")}
                      className={
                        state.fontWeight === "bold"
                          ? "btn1grid active"
                          : "btn1grid"
                      }
                    >
                      <FormatBoldIcon />
                    </Button>

                    <Button
                      onClick={(e) => handleStyle(e, "fontStyle")}
                      className={
                        state.fontStyle === "italic"
                          ? "btn2grid active"
                          : "btn2grid"
                      }
                    >
                      <FormatItalicIcon />
                    </Button>

                    {/* <Button>
<FormatStrikethroughIcon onClick={handleStrike} />
</Button> */}
                  </Grid>
                </div>

                <div className="h">
                  <h3>Title text</h3>
                  <div className="Tinput d-flex flex-column">
                    <input
                      id="text"
                      type="text"
                      value={text}
                      placeholder="Enter Title"
                      name="title"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="htitle titleip">
                    <select
                      name="Title font family"
                      // onChange={(e) => fontSelected(e, "theTitle")}
                      onChange={(e) =>
                        setState({ ...state, fontFamily: e.target.value })
                      }
                      // setfamily(e.target.value)}
                    >
                      {/* {font.fontFamilyName.map((user) => (
<option value={user}>{user}</option>
))} */}
                      <option value="default">Title font family</option>
                      <option value="emoji">Emoji</option>
                      <option value="math">Math</option>
                      <option value="monospace">Monospace</option>
                      <option value="fangsong">fangsong</option>
                      <option value="fantasy">Fantasy</option>
                      <option value="inherit">Inherit</option>
                      <option value="poppins">Poppins</option>
                      <option value="cursive">cursive</option>
                      <option value="Courier">Courier</option>
                      <option value="unset">Unset</option>
                      <option value="ui-serif">Ui-serif</option>
                      <option value="sans-serif">sans-serif</option>
                      <option value="Verdana">Verdana</option>
                      <option value="Tahoma">Tahoma</option>
                      <option value="revert">Revert</option>
                    </select>
                  </div>
                  <div class="halfip">
                    <div className="halfdiv fontSizeTag d-flex">
                      <div className="righttxt titleip">
                        <select
                          className=""
                          placeholder="size"
                          onChange={(e) =>
                            setState({ ...state, fontSize: e.target.value })
                          }
                        >
                          <option value="25px" selected>
                            Font size
                          </option>
                          <option value="60px">60</option>
                          <option value="50px">50</option>
                          <option value="40px">40</option>
                          <option value="35px">35</option>
                          <option value="30px">30</option>
                          <option value="28px">28</option>
                          <option value="26px">26</option>
                          <option value="24px">24</option>
                        </select>
                      </div>
                    </div>
                    <div className="halfdiv Tcolor">
                      <input type="text" value={state.fontColor} />
                      <ColorLensIcon onClick={handleTitleColorClick} />
                      <HexColorPicker
                        style={{ display: isTitleColorDisplay }}
                        color={state.fontColor}
                        onChange={(e) => setState({ ...state, fontColor: e })}
                      />
                    </div>
                  </div>

                  {templateById?.type === "text" && <h3>Content text</h3>}
                  {templateById?.type === "text" && (
                    <div className="Tinput ">
                      <input
                        id="text"
                        type="text"
                        value={content}
                        placeholder="Enter Content"
                        name="content"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  )}
                  {templateById?.type === "text" && (
                    <div className="htitle contntip">
                      <select
                        name="Title font family"
                        // onChange={(e) => fontSelected(e, "theTitle")}
                        onChange={(e) =>
                          setState({
                            ...state,
                            contentFontFamily: e.target.value,
                          })
                        }
                        // setfamily(e.target.value)}
                      >
                        {/* {font.fontFamilyName.map((user) => (
<option value={user}>{user}</option>
))} */}
                        <option value="default">Content font family</option>
                        <option value="emoji">Emoji</option>
                        <option value="math">Math</option>
                        <option value="monospace">Monospace</option>
                        <option value="fangsong">fangsong</option>
                        <option value="fantasy">Fantasy</option>
                        <option value="inherit">Inherit</option>
                        <option value="poppins">Poppins</option>
                        <option value="cursive">cursive</option>
                        <option value="Courier">Courier</option>
                        <option value="unset">Unset</option>
                        <option value="ui-serif">Ui-serif</option>
                        <option value="sans-serif">sans-serif</option>
                        <option value="Verdana">Verdana</option>
                        <option value="Tahoma">Tahoma</option>
                        <option value="revert">Revert</option>
                      </select>
                    </div>
                  )}
                  <div class="halfip">
                    {templateById?.type === "text" && (
                      <div className="fontSizeTag halfdiv d-flex">
                        <div className="righttxt contntip">
                          <select
                            className=""
                            placeholder="size"
                            onChange={(e) =>
                              setState({
                                ...state,
                                contentFontSize: e.target.value,
                              })
                            }
                          >
                            <option value="25px" selected>
                              Font size
                            </option>
                            <option value="60px">60</option>
                            <option value="50px">50</option>
                            <option value="40px">40</option>
                            <option value="35px">35</option>
                            <option value="30px">30</option>
                            <option value="28px">28</option>
                            <option value="26px">26</option>
                            <option value="24px">24</option>
                          </select>
                        </div>
                      </div>
                    )}
                    {templateById?.type === "text" && (
                      <div className="Tcolor halfdiv">
                        <input
                          type="text"
                          value={state.contentFontColor}
                          placeholder="Content Text color"
                        />
                        <ColorLensIcon onClick={handleContentColorClick} />
                        <HexColorPicker
                          style={{ display: isContentColorDisplay }}
                          color={state.contentFontColor}
                          onChange={(e) =>
                            setState({ ...state, contentFontColor: e })
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="Tinput" hidden>
                    <label className="mt-4">Upload Logo</label>
                    <input
                      type="file"
                      id="imgfile"
                      accept="image/*"
                      ref={logoRef}
                      onChange={showPreview}
                      hidden
                    />
                  </div>

                  {templateById?.type === "image" && (
                    <div className="Tinput" hidden>
                      <label>Upload Content Image</label>
                      <input
                        type="file"
                        id="imgfile"
                        accept="image/*"
                        ref={contentLogoRef}
                        onChange={showContentPreview}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditTemplateNew;
