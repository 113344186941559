import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import "./userProfile.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AdminAction } from "../ReduxStore/Actions";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
const UserProfile = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.adminReducer.userDetails);
  console.log(props, "props");

  useEffect(() => {
    let params = {
      userId: props.match.params.id,
    };
    dispatch(AdminAction.getUserById(params));
  }, []);

  return (
    <div
      className="admin2 "
      style={{
        marginLeft: "25%",
      }}
    >
      <div
        className="profileForm  ml-4 "
        style={{
          marginTop: "50px",
          marginLeft: "50px",
          width: "700px",
          minHeight: "80vh",
        }}
      >
        <div className="head d-flex flex-row " style={{ paddingTop: "40px" }}>
          <button className="but3 mr-4">
            <Link
              to={{
                pathname: `/viewUser`,
              }}
            >
              <ArrowBackIcon style={{ color: "white" }} />{" "}
            </Link>
          </button>
          <h4>User Profile</h4>
        </div>

        <Form>
          <Form.Row className="mt-4">
            <div className="col-md-6">
              <Form.Label>First Name : </Form.Label> {user?.firstName}
            </div>

            <div className="col-md-6">
              <Form.Label>Last Name : </Form.Label> {user?.lastName}
            </div>
          </Form.Row>
          <Form.Row className="mt-4">
            <div className="col-md-6">
              <Form.Label>Email : </Form.Label> {user?.email}
            </div>

            <div className="col-md-6">
              <Form.Label>Phone No : </Form.Label> {user?.phone}
            </div>
          </Form.Row>

          <Form.Row className="mt-4">
            <div className="col-md-12 text-center " style={{ fontWeight: 600 }}>
              <Form.Label>Price Plan</Form.Label>
            </div>
          </Form.Row>
          <Form.Row>
            <div className="col-md-12 text-center pricePlan">
              {user?.payment}
            </div>
          </Form.Row>

          <div className="row"></div>
        </Form>
      </div>
    </div>
  );
};
export default UserProfile;
