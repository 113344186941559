import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./modal.css";
import firebase from "../../Services/Util/firebase";
import { useDispatch } from "react-redux";
import { ScreenAction } from "../../ReduxStore/Actions/index";
// import { AuthAction } from "./ReduxStore/Actions/index";
import { useSelector } from "react-redux";

import AddScreen from "./AddScreen";
import Loader from "../../Services/Util/loader";

function Modalpop(props) {
  const dispatch = useDispatch();
  const [modalShow2, setModalShow2] = React.useState(false);
  const [firebaseScreenId, setFirebaseScreenId] = React.useState();
  const loading = useSelector((state) => state.commonReducer.loading);
  console.log(loading, "loadingabc");
  const handleState = () => {
    setModalShow2(false);
    props.onHide();
  };

  const handlePairClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    console.log("code in hpc :>> ", props.code);
    const db = firebase.database();
    // const codesRef = db.ref("codes");
    const screenRef = db.ref("screens");
    screenRef
      .orderByChild("code")
      .equalTo(props.code)
      .once("value", (snapshot) => {
        console.log("snapshot :>> ", snapshot.exists());

        // console.log(
        //   "snapshot.val() :>> ",
        //   snapshot.val(),
        //   snapshot.val()["active"]
        // );

        if (!snapshot.exists()) {
          console.log("Incorrect Code");
          props.handleError("Incorrect Code");
        } else {
          let screenKey;
          // let isActive;

          snapshot.forEach((data) => {
            // console.log("data.key :>> ", data.key, data.val()["active"]);
            screenKey = data.key;
            // isActive = data.val()["active"];
          });
          // console.log("isActive :>> ", isActive);

          // if (isActive === true) {
          //   props.handleError("Incorrect Code");
          //   return;
          // }

          screenRef.child(screenKey).update({
            active: true,
          });

          setFirebaseScreenId(screenKey);

          dispatch(ScreenAction.setCode(props.code));

          setModalShow2(true);
        }
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="screen-modal-1"
          id="contained-modal-title-vcenter"
        >
          Add Screen
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="screen-modal-body">
        <h4 className="addscreenhead">
          {" "}
          Type a 6 character pairing code, which you see
          <br />
          on your SavvSignage Player.
        </h4>
        <span className="tvbg">
          <input
            className="screen-modal-input1"
            type="text"
            value={props.code}
            onChange={props.handleCodeChange}
          />
        </span>
      </Modal.Body>

      <Modal.Footer className="text-center">
        {/* <Button className="button11 addscreenbtn">Help</Button> */}
        <Button className="button21 addscreenbtn2" onClick={props.onHide}>
          Cancel
        </Button>
        <Button className="button31 addscreenbtn" onClick={handlePairClick}>
          Pair <Loader loading={loading} />
        </Button>
        <AddScreen
          show={modalShow2}
          className="screen-modal2"
          firebaseScreenId={firebaseScreenId}
          onHide={
            handleState
            // () => {
            // setModalShow2(false);
          }
          handleError={props.handleError}
        />
      </Modal.Footer>
    </Modal>
  );
}
export default Modalpop;
