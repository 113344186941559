import React, { useEffect, useState } from "react";
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";
import "./AddScreen.css";
import Content from "./content/content";
import Template from "./Template/template";
import { useSelector, useDispatch } from "react-redux";
import { ScreenAction, ScheduleAction } from "../../ReduxStore/Actions";
import firebase from "../../Services/Util/firebase";
import SaveInFirebase from "../../Services/Util/saveFirebase";
import PlaylistModal from "./playlistModal/PlaylistModal";
import Loader from "../../Services/Util/loader";

const AddScreen = (props) => {
	const dispatch = useDispatch();
	const [state, setState] = React.useState();
	const [selected, setSelected] = useState();
	const { id: userId } = useSelector((state) => state.authReducer.authData);
	const data = useSelector((state) => state.authReducer.data);

	const [showModal, setModal] = React.useState({
		Template: false,
		Content: false,
		Playlist: false,
	});
	const [typeName, setTypeName] = React.useState();
	const [media, setMedia] = useState();
	const [mediaType, setMediaType] = useState();
	const [screenName, setScreenName] = useState();
	const code = useSelector((state) => state.screenReducer.code);
	const scheduleList = useSelector((state) => state.scheduleReducer.list);
	const [scheduleSelected, setScheduleSelected] = useState();
	const [error, setError] = useState();
	const loading = useSelector((state) => state.commonReducer.loading);
	//   screenName: "",
	//   media: "",
	// }
	// );
	console.log(loading, "loadingabc");
	useEffect(() => {
		setTypeName(data.name);
	}, [data]);
	//To close the  child modal popup
	const handleChangeModal = (e, name) => {
		setModal({
			[name]: false,
		});
	};

	// useEffect(() => {
	//   setMediaName(props.selected);
	// }, [props.selected]);
	// to open the child modal
	const handleClick = (e, name) => {
		setModal({
			...showModal,
			[name]: true,
		});
	};
	//to Set the state accordingto the change in type input and display respective modal
	const handleChange = (e) => {
		setMediaType(e.target.value);
		if (e.target.value === "Schedule") {
			dispatch(ScheduleAction.getSchedule({ userId: data }));
		}
		setState({
			[e.target.name]: e.target.value,
		});
		let name = "show" + e.target.value;
		setSelected("");
	};
	const handleChangeSchedule = (e) => {
		let nodeId;
		for (let node of e.target.children) {
			if (node.value === e.target.value) {
				console.log(
					"node.getAttribute(-id) :>> ",
					node.getAttribute("data-id")
				);
				nodeId = node.getAttribute("data-id");
				// this.setState({
				//   selected: node.getAttribute('data-id')
				// });
			}
		}
		console.log("in handleChangeschedule", e);
		let params = {
			// userId: data,
			id: nodeId,
			// mediaType: "schedule",
			// screenName,
		};
		console.log(params, "<::params");
		setScheduleSelected(e.target.value);
		setMedia(params);
	};

	const onMediaSelect = (e, selectedMedia) => {
		e.preventDefault();
		e.stopPropagation();
		console.log("media :>> ", selectedMedia);
		setSelected(selectedMedia.name || selectedMedia.mediaName);

		setMedia(selectedMedia);
	};

	const handleSave = async (e) => {
		// if (data["payment"].trim() === "Basic") {
		//   props.handleError("Please Buy a plan to Create Screens");
		//   return;
		// }

		e.preventDefault();
		e.stopPropagation();

		let mediaId;
		if (screenName == undefined) {
			console.log("insss");
			setError({
				screenName: "Enter Screen name",
			});
			return;
		} else if (media == undefined) {
			setError({
				media: "Please select media",
			});
			return;
		} else if (mediaType.toLowerCase() === "playlist") {
			mediaId = media.playlistId;
		} else mediaId = media.id;
		let params = {
			mediaId,
			screenType: mediaType,
			userId: userId,
			firebase_screen_id: props.firebaseScreenId,
			screenName,
		};
		console.log(params);

		// const apiParams = await SaveInFirebase(params);
		// console.log(apiParams, "pppp");
		await dispatch(ScreenAction.addScreen(params));
		console.log("after add screen");

		let getParams = {
			userId,
		};
		dispatch(ScreenAction.getScreen(getParams));

		// console.log(props, "props");
		// console.log(ddd, "dddd");
		props.onHide();
	};
	return (
		<div className="addScreen-modal editPublishScreen">
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header className="col-md-12 " closeButton>
					<Modal.Title
						className="Addscreen-modal-1 pairingScreenModal"
						id="contained-modal-title-vcenter"
					>
						Add Screen
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="Addscreen-modal-body">
					<div className="d-flex flex-column">
						<div className="d-flex  align-items-baseline justify-content-end mb-3 inputbox5 pairingScreenFields">
							<label>Screen Name*</label>
							<input
								className="Addscreen-modal-input"
								type="text"
								value={screenName}
								onChange={(e) => setScreenName(e.target.value)}
							/>
						</div>
						<p className="errMsgModal">{error?.screenName}</p>

						<div className="d-flex flex-row  align-items-baseline justify-content-end mb-3 pairingScreenFields">
							<label>Tag</label>
							<input className="Addscreen-modal-input" type="text" />
						</div>
						<div className="d-flex flex-row  align-items-baseline justify-content-end mb-3 inputbox5 pairingScreenFields">
							<label>Type*</label>
							<select
								name="Type"
								id="type"
								className="Addscreen-modal-input"
								placeholder="Type"
								onChange={(e) => handleChange(e)}
							>
								<option value="" disabled selected>
									Type
								</option>
								<option className="content-option" value="Content">
									Content
								</option>
								<option value="Playlist">Playlist</option>
								<option value="Template">Template</option>
								{/* <option value="Schedule">Schedule</option> */}
							</select>
						</div>
						<p className="errMsgModal">{error?.media}</p>
						{state?.Type?.length > 0 ? (
							<div className="d-flex flex-row align-items-baseline justify-content-end mb-3 pairingScreenFields">
								<label>Selected {state.Type}*</label>
								{state.Type === "Schedule" ? (
									<select
										name="Type"
										id="type"
										className="Addscreen-modal-input"
										placeholder=""
										// value={scheduleSelected}
										onChange={(e) => handleChangeSchedule(e)}
									>
										{scheduleList.length > 0 &&
											scheduleList.map((item) => {
												return (
													<option
														value={item.id}
														key={item.id}
														data-id={item.id}
													>
														{item.name}
													</option>
												);
											})}
									</select>
								) : (
									<InputGroup
										className="mb-3"
										className="Addscreen-modal-input"
										style={{ padding: "0px" }}
										name={state.Type}
									>
										<FormControl
											value={selected}
											aria-describedby="basic-addon2"
										/>
										<Button
											name={state.Type}
											variant="outline-secondary"
											onClick={(e) => handleClick(e, e.target.name)}
										>
											Change
										</Button>
									</InputGroup>
								)}
							</div>
						) : null}

						{/*} <div className="d-flex flex-row align-items-baseline justify-content-end mb-3">
              <label>Orientation*</label>
              <select
                name="Orientation"
                placeholder="Orientation"
                className="Addscreen-modal-input"
              >
                {" "}
                <option value="" disabled selected>
                  Type
                </option>
                <option value="Landscape">Landscape</option>
                <option value="rotated 90 degrees">rotated 90 degrees</option>
                <option value="rotated 180 degrees">rotated 180 degrees</option>
                <option value="rotated 270 degrees">rotated 270 degrees</option>
                </select> */}
						{/* <input className="Addscreen-modal-input" type="text" /> */}
						{/*</div> */}
					</div>
				</Modal.Body>

				<Modal.Footer>
					<div className="d-flex Addscreen-button justify-content-center">
						{/* <div className="col-sm-6">
              <Button className="addScreenButton1">Help</Button>
              <Button
                className="addScreenButton2"
                // onClick={() => {
                //   setmodal(true);
                // }}
              >
                Preview
              </Button>
            </div> */}
						<div className="col-sm-6 text-center">
							<Content
								className="modal-class"
								show={showModal.Content}
								onHide={(e) => handleChangeModal(e, "Content")}
								onMediaSelect={onMediaSelect}
							/>
							<Template
								className="modal-class"
								show={showModal.Template}
								onHide={(e) => handleChangeModal(e, "Template")}
								onMediaSelect={onMediaSelect}
							/>
							<PlaylistModal
								className="modal-class"
								show={showModal.Playlist}
								onHide={(e) => handleChangeModal(e, "Playlist")}
								onMediaSelect={onMediaSelect}
							/>
							<Button className="addScreenButton3" onClick={props.onHide}>
								Cancel
							</Button>
							<Button className="addScreenButton4" onClick={handleSave}>
								{loading ? <Loader loading={loading} /> : "Save"}
							</Button>
							{/* <p className="errMsg">{error}</p> */}
						</div>
					</div>
				</Modal.Footer>
			</Modal>
		</div>
	);
};
export default AddScreen;
