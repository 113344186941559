import { ACTION_TYPE, APPLICATION_CONSTANTS } from "../../Services/index";

export const initialState = {
  data: "",
  orderDetails: {},
};
export const bookReducer = (state = initialState, action) => {
  // console.log("action",action)
  switch (action.type) {
    case ACTION_TYPE.ADD_BILLING_INFO:
      return {
        ...state,
        data: action.payload,
      };
    case ACTION_TYPE.ORDER_DETAILS:
      return {
        ...state,
        orderDetails: action.payload.data,
      };

    default:
      return state;
  }
};
