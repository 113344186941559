import React, { useEffect, useState } from "react";
import Header from "../../../component/headerBar/Header";
import "./EditPlaylist.css";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DeleteIcon from "@material-ui/icons/Delete";
import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import play from "../../../Images/commonImages/play.jpg";
import { Divider, Card, CardContent, Grid } from "@material-ui/core";
import { Button, Toast } from "react-bootstrap";
import docImage from "../../../Images/commonImages/docImage.png";
import { useDispatch, useSelector } from "react-redux";
import playlisticon from "../../../Images/commonImages/playlisticon.png";
import {
  ContentAction,
  PlaylistAction,
  TemplateAction,
} from "../../../ReduxStore/Actions";
import { useHistory, useLocation } from "react-router";
import { ROUTES } from "../../../Services";
import SideBar from "../../sideNav/SideNav";
import AlertDialog from "../../../component/AlertDialog/alertDialog";

const Playlists = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [display, setDisplay] = React.useState("content");
  const [expandPlaylist, setExpandPlaylist] = React.useState(true);
  const [playlistDetails, SetPlaylistDetails] = React.useState({
    duration: "1 sec",
    playlistName: " New Playlist",
    editDuration: false,
    editName: false,
  });
  const [duration, setDuration] = React.useState();
  const [toast, setToast] = React.useState(false);
  const [position, setPosition] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [error, setError] = useState();
  const data = useSelector((state) => state.authReducer.data);
  const contentGet = useSelector((state) => state.contentReducer.data);
  const userTemplate = useSelector((state) => state.templateReducer.dataUser);
  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const [delOpen, setDelOpen] = useState({
    state: false,
    user: "none",
  });
  const playlistData = useSelector(
    (state) => state.playlistReducer.playlistData.data
  );
  useEffect(() => {
    (async () => {
      let params = { userId: data };
      let body = { userId: data, playlistId: props.match.params.id };
      let dummy = await dispatch(ContentAction.getContent(params));

      await dispatch(TemplateAction.getUserTemplate(params));

      dummy = props.match.params.id
        ? await dispatch(PlaylistAction.getPlaylistById(body))
        : null;
    })();
  }, [data]);

  const handleChange = () => {
    setExpandPlaylist(!expandPlaylist);
  };
  const handleEditClick = (e, name) => {
    SetPlaylistDetails({
      ...playlistDetails,
      [name]: !playlistDetails[name],
    });
  };
  //to edit duration
  const handleChangeDuration = (e, position) => {
    let newArray = [...selected];
    newArray[position - 1]["duration"] = e.target.value;
    setSelected(newArray);
  };
  const handleChangevalue = (e, position) => {
    SetPlaylistDetails({
      ...playlistDetails,
      [e.target.name]: e.target.value,
    });
  };
  let k = 0;
  const handleToggle = (e, value) => {
    display === value ? (k = 9) : setDisplay(value);
  };

  let body = "";
  let aooo = "";

  //to add content to playlist
  const handleClickCard = async (e, user, type) => {
    if (location.pathname === ROUTES.NewPlaylist) {
      // if(type === "content"){
      setPosition(position + 1);
      setSelected([
        ...selected,
        {
          id: user.id,
          type: type,
          duration: "5 sec",
          position: selected.length + 1,
          url: user.url,
          name: user.name,
          ts: user.created_ts,
          typeContent: user.type,
        },
      ]);
    } else {
      body = {
        userId: data,
        playlistId: props.match.params.id,
        addMediaList: [
          {
            id: user.id,
            type: type,
            position: playlistData["media"]?.length + 1,
            duration: "5 sec",
          },
        ],
      };
      aooo = await dispatch(PlaylistAction.addToPlaylist(body));
      await dispatch(
        PlaylistAction.getPlaylistById({
          userId: data,
          playlistId: props.match.params.id,
        })
      );
    }
  };

  //to publish
  const handlePublish = async (e) => {
    console.log(selected, "data");
    if (location.pathname === ROUTES.NewPlaylist) {
      if (playlistDetails.playlistName === "") {
        setError("Please Enter Name");
        setToast(true);
        return;
      } else if (selected.length === 0) {
        setError("Please select media");
        setToast(true);
        return;
      }
    }
    let params = {
      userId: data,
      name: playlistDetails.playlistName,
      mediaList: selected,
    };
    await dispatch(PlaylistAction.createPlaylist(params));
    history.push("/playlist");
  };

  let content_type = "application/pdf";
  //to delete from a playlist

  const handleDelete = async (e, task) => {
    console.log("task in handleDelete :>> ", task, playlistData);
    let params = {
      userId: data,
      playlistId: props.match.params.id,
      removeMediaList: [
        {
          playlistMediaId: task.mediaId,
        },
      ],
    };
    const delteee =
      playlistData.media.length > 1
        ? await dispatch(PlaylistAction.deleteFromPlaylist(params))
        : (setError("Playlist cannot be empty"), setToast(true));
    let body = { userId: data, playlistId: props.match.params.id };
    await dispatch(PlaylistAction.getPlaylistById(body));
  };
  const handleDeletePlaylist = async () => {
    setDelOpen({
      state: false,
      user: "none",
    });
    console.log("DELETED", "in maindel");
    let params = {
      userId: data,
      playlistId: props.match.params.id,
    };

    const res = (await dispatch(PlaylistAction.deletePlaylist(params)))
      ? true
      : false;
    if (res) {
      history.push("/playlist");
    }
    setError(errorMessage);
    setToast(true);
  };

  //to delete from new playlist(that is from seleted array)
  const handleDeleteFromNewplaylist = (e, task) => {
    console.log("delete.hitted");
    const newTodos = selected.filter((t) => t.position !== task.position);
    console.log("del 2");
    for (var i = task.position - 1; i < newTodos.length; i++) {
      console.log(
        i,
        newTodos[i]["position"],
        newTodos[i]["position"] - 1,
        "todaos"
      );
      newTodos[i]["position"] = newTodos[i]["position"] - 1;
    }
    console.log("del 3");

    setSelected(newTodos);
  };
  const handlePlaylistAlertCard = (e, playlistData) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("DELETED", playlistData);
    setDelOpen({
      state: true,
      user: playlistData,
    });
  };
  return (
    <div className="playlist playlistnew">
      <div className="row">
        <div className="col-md-2">
          <SideBar />
        </div>
        <div className="col-md-10 editListTab">
          <div className="playlistHeader mt-3 mb-4">
            <Header
              title="Playlist"
              buttonName="Save"
              searchd="none"
              placeholder=" Search Playlist"
              handleChange={(e) => handlePublish(e)}
            />
          </div>
          <Divider variant="fullWidth" />
          <div className="row">
            <div className="col-md-9 bigBox">
              <Toast
                className="playlistToast"
                // show={errorStatus === false ? true : false}
                show={toast}
                onClose={() => setToast(false)}
                delay={6000}
                autohide
              >
                <Toast.Header className="PlaylistToastHeader">
                  <strong className="mr-auto">{error}</strong>
                </Toast.Header>
              </Toast>
              <AlertDialog
                open={delOpen}
                handleClose={() =>
                  setDelOpen({
                    state: false,
                    content: "none",
                  })
                }
                message="Are you sure you want to Delete?"
                button1="Yes"
                button2="No"
                handleDelete={handleDeletePlaylist}
              />

              {location.pathname === ROUTES.NewPlaylist ? (
                <div className="newPlaylist">
                  <div className="playist-Screen-body expandabledivId mt-3 ml-2 d-flex">
                    <img src={play} alt="logo" />
                    <div className=" col-md-5 align-self-center d-flex flex-column ml-3 ">
                      <div className=" playlistEditmain d-flex flex-row">
                        <input
                          classsName="inputOfeditPlaylist"
                          type="text"
                          name="playlistName"
                          value={playlistDetails.playlistName}
                          onChange={(e) => handleChangevalue(e)}
                        />
                        <EditTwoToneIcon className="editIconPlaylist" />
                      </div>

                      <p>Total items: {selected.length}</p>
                    </div>
                    <div className="absdownArr">
                      {expandPlaylist === false ? (
                        <KeyboardArrowDownIcon
                          onClick={handleChange}
                          className="dropdwnicon"
                        />
                      ) : (
                        <ExpandLessIcon
                          onClick={handleChange}
                          className="dropdwnicon"
                        />
                      )}
                    </div>
                  </div>

                  <div className="expandableStart mt-5">
                    <div className="text-center  imgPlaylist">
                      {selected?.length == 0 ? (
                        <div>
                          <h4 className="emptypageLine">
                            Choose your Contents and Templates to add them in
                            Playlist.
                          </h4>
                          <img src={playlisticon} alt="img" />
                        </div>
                      ) : null}
                    </div>
                    {expandPlaylist === true
                      ? selected?.map((task) => {
                          console.log("task in selected :>> ", task);
                          return (
                            <div className="playlistExpand row mt-4 mr-3 ml-3 cardOfEdit">
                              <div
                                className="col-md-2 "
                                style={{ padding: "0" }}
                              >
                                <img
                                  className="img"
                                  style={{ width: "120px", height: "90px" }}
                                  src={
                                    task.typeContent == content_type
                                      ? docImage
                                      : task.url
                                  }
                                />
                                <div></div>
                              </div>
                              <div className="col-md-3 mt-4 ml-3">
                                <p className="playlistEditName">
                                  {task.mediaName}
                                </p>
                                <p className="playlistExpandDate">
                                  uploaded : {task.ts.split("T")[0]}
                                </p>
                              </div>
                              <div className="col-md-2 ml-2 text-center"></div>
                              <div className="col-md-3 ml-3 DurationEdit d-flex flex-row">
                                <input
                                  type="text"
                                  name="duration"
                                  className="doo"
                                  value={task.duration}
                                  onChange={(e) =>
                                    handleChangeDuration(e, task.position)
                                  }
                                />

                                <EditTwoToneIcon className="editIconPlaylist  " />
                              </div>
                              <div className="col-md-1 mt-4 text-right">
                                <DeleteIcon
                                  onClick={(e) =>
                                    handleDeleteFromNewplaylist(e, task)
                                  }
                                />
                              </div>
                              <Divider />
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              ) : (
                <div className="playist-Screen-body expandabledivId mt-3 ml-2 d-flex">
                  <img src={play} alt="logo" />
                  <div className=" col-md-5 d-flex flex-column ml-3 align-self-center ">
                    <div className=" playlistEdit d-flex flex-row">
                      <input
                        type="text"
                        name="playlistName"
                        value={playlistData && playlistData["name"]}
                      />
                    </div>

                    <p>
                      Total items:{" "}
                      {playlistData && playlistData["media"]?.length}
                    </p>
                  </div>
                  <div className=" col-md-4 text-center align-self-center deleteEditPlaylistIcon">
                    <DeleteIcon
                      onClick={(e) => handlePlaylistAlertCard(e, playlistData)}
                    />
                  </div>
                  <div className="absdownArr">
                    {expandPlaylist === false ? (
                      <KeyboardArrowDownIcon
                        onClick={handleChange}
                        className="dropdwnicon"
                      />
                    ) : (
                      <ExpandLessIcon
                        onClick={handleChange}
                        className="dropdwnicon"
                      />
                    )}
                  </div>
                </div>
              )}

              {expandPlaylist === true ? (
                <div className="expandableStart mt-5" id="div1">
                  {playlistData &&
                    playlistData["media"]?.map((task) => {
                      return (
                        <div className="row mb-3 ml-2 mr-2 mt-4  cardOfEdit">
                          <div className="col-md-2 col-5 img ">
                            <img
                              style={{ width: "120px", height: "90px" }}
                              src={task.url}
                            />
                            <div></div>
                          </div>
                          <div className="col-md-3 col-7 removeMarginNew mt-4 ml-3">
                            <p className="playlistEditName">{task.mediaName}</p>
                            <div className="uploaded">
                              <p className="playlistExpandDate"></p>
                            </div>
                          </div>
                          <div className="col-md-2 col-5 removeMargin mt-4 ml-2 text-center">
                            {task.mediaType}
                          </div>
                          <div className="col-md-3 col-5 removeMargin mt-4 ml-3 playlistEdit">
                            {task.duration} Sec
                          </div>
                          <div className="col-md-1 col-2 removeMargin mt-4 pt-1text-center">
                            <DeleteIcon
                              onClick={(e) => handleDelete(e, task)}
                            />
                          </div>
                          <Divider />
                        </div>
                      );
                    })}
                </div>
              ) : null}
            </div>
            <div className="col-md-3 tabplaylist  ">
              <div className="buttoneditPlaylist d-flex">
                <Button
                  className={display === "content" ? "btab " : "btab"}
                  onClick={(e) => handleToggle(e, "content")}
                >
                  Content
                </Button>
                <Button
                  className={display === "template" ? "btab " : "btab"}
                  onClick={(e) => handleToggle(e, "template")}
                >
                  {" "}
                  Template
                </Button>
              </div>

              {display === "content" ? (
                <div className="playlistscroll">
                  <div className=" mt-2 text-center">
                    <h6>Click on card to add it in your playlist </h6>
                  </div>
                  <div className="cardP">
                    <Grid className="data" container item xs={12}>
                      {contentGet
                        ?.filter(
                          (user) =>
                            user.type === "image/jpeg" ||
                            user.type === "image/png"
                        )
                        .map((user) => (
                          <div className="card8 mt-3">
                            <Card
                              id={user.id}
                              className="d-flex flex-row mr-2"
                              onClick={(e) =>
                                handleClickCard(e, user, "content")
                              }
                            >
                              {user.type === "image/jpeg" ||
                              user.type === "image/png" ? (
                                <img
                                  src={user.url}
                                  style={{
                                    objectFit: "cover",
                                  }}
                                />
                              ) : null}
                              <CardContent>
                                <div className="d-flex flex-column   selectPlaylist">
                                  <h5 className="mt-1">{user.name}</h5>
                                  <p>{user.created_ts.split("T")[0]}</p>
                                </div>
                              </CardContent>
                            </Card>
                          </div>
                        ))}
                    </Grid>
                  </div>
                </div>
              ) : (
                <div className="p-3 ">
                  <div className="  text-center">
                    <h6>Click on card to add it in your playlist </h6>
                  </div>
                  <div className="cardP mt-3">
                    <Grid className="data" container item xs={12} spacing={3}>
                      {userTemplate?.data
                        ?.filter(
                          (user) =>
                            user.type === "image/jpeg" ||
                            user.type === "image/png"
                        )
                        .map((user) => (
                          <div className="card8 mt-3">
                            <Card
                              id={user.id}
                              className="d-flex flex-row mr-2"
                              onClick={(e) =>
                                handleClickCard(e, user, "template")
                              }
                            >
                              <img
                                src={user.url}
                                style={{
                                  height: "100",
                                  objectFit: "cover",
                                }}
                              />

                              <CardContent>
                                <div className="d-flex flex-column   selectPlaylist">
                                  <h5 className="mt-1">{user.name}</h5>
                                  <p>{user.created_ts.split("T")[0]}</p>
                                </div>
                              </CardContent>
                            </Card>
                          </div>
                        ))}
                    </Grid>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Playlists;
