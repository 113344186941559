import "./confirmation.css";
import Tick from "../../../Images/commonImages/tick.png";
import { useEffect } from "react";
import { BookAction, StripeAction } from "../../../ReduxStore/Actions/index";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const Confirmation = (props) => {
  let dispatch = useDispatch();
  const data = useSelector((state) => state.authReducer.authData);
  useEffect(() => {
    (async () => {
      await dispatch(
        StripeAction.confirmStripe({
          billingId: props.match.params.orderNumber,
        })
      );
    })();
  }, []);
  console.log("props in Payment Receipt", props, data);
  return (
    <div>
      <section className=" confirmationpage p-t-90">
        <div className="container">
          <h2 className="">Order Confirmation</h2>

          <div className="gradientBox">
            <div className="tickdiv">
              <img src={Tick} className="tickicon" alt="Tick" />
            </div>
            <h3>We've recieved your order</h3>
            <h3>
              <b>Order no: #{props?.match?.params.orderNumber}</b>
            </h3>
            <h4 className="text-center">
              <i>A copy of your receipt has been sent to {data?.email}</i>
            </h4>
            <div className="widthConf border1 text-center">
              <h4 className="noborder">
                <b>Order summery</b>
              </h4>
              {/* <div className="col-sm-6 col-xs-12 col-md-4 leftdiv col"> */}
              <div className="">
                <h5>Delivery for</h5>
                <p>
                  {data?.firstName} {data?.lastName}
                  <br /> Phone no: {data?.phone}
                </p>
                {/* </div> */}
              </div>
              {/* <div className="hidden-sm col-md-5 col">&nbsp;</div> */}
            </div>
            <div className="widthConf">
              <div className=" totaldiv d-flex justify-content-space-between">
                {/* <h5>Total</h5>
                <h5>{</h5> */}
              </div>
            </div>
            <div className=" d-flex justify-content-center">
              <Button
                className="bckBtn "
                onClick={() => {
                  props.history.push("/");
                }}
              >
                Back
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Confirmation;
