import { ACTION_TYPE, APPLICATION_CONSTANTS } from "../../Services/index";
export const initialState = {
  data: "",
  isRegistered: "",
  isEmailVerified: "",
  isLoggedIn: "",
  message: "",
  authData: {},
  status: null,
  insertId: undefined,
  signupData: {},
  // checkIsPayment: localStorage.getItem("userData")
  //   ? JSON.parse(localStorage.getItem("userData")).is_payment
  //   : null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.SIGNUP:
      // localStorage.setItem(
      //   APPLICATION_CONSTANTS.USER_DATA,
      //   JSON.stringify(action.payload.data.user)
      // );
      // localStorage.setItem(
      //   APPLICATION_CONSTANTS.TOKEN,
      //   action.payload.data.user.token
      // );
      console.log(action.payload, "signup");
      return {
        ...state,
        status: action.payload.data.status,
        message: action.payload.data.message,
        insertId: action.payload.data.insertId,
        signupData: action.payload,
      };
    case "CLEAR_LOGIN":
      return {
        ...state,
        message: "",
        status: null,
      };

    case ACTION_TYPE.LOGIN:
      localStorage.setItem(
        APPLICATION_CONSTANTS.USER_DATA,
        JSON.stringify(action.payload.data.user)
      );
      localStorage.setItem(
        APPLICATION_CONSTANTS.TOKEN,
        action.payload.data.user.token
      );
      localStorage.setItem(
        APPLICATION_CONSTANTS.Role,
        action.payload.data.user.role
      );
      localStorage.setItem(
        APPLICATION_CONSTANTS.ID_TOKEN,
        action.payload.idToken
      );
      return {
        ...state,
        authData: action.payload.data.user,
        data: action.payload.data.user.id,
      };
    case ACTION_TYPE.LOGOUT_USER:
      localStorage.removeItem(APPLICATION_CONSTANTS.USER_DATA);
      localStorage.removeItem(APPLICATION_CONSTANTS.TOKEN);
      localStorage.removeItem(APPLICATION_CONSTANTS.ID_TOKEN);
      // let cartItemsRestore = localStorage.getItem("cartItems");
      // localStorage.clear();
      // localStorage.setItem("cartItems", cartItemsRestore);
      return {
        ...state,
        message: "",
        // authData:{},
        // data:""
      };

    default:
      return state;
  }
};
