import { ACTION_TYPE, APPLICATION_CONSTANTS } from "../../Services/index";
export const initialState = {
  data: [],
  userDetails: {},
};

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LIST":
      console.log(action.payload, "adminred");
      return {
        ...state,
        data: action.payload.data,
      };

    case "GET_USER_DETAILS":
      console.log(action.payload, "adminred");
      return {
        ...state,
        userDetails: action.payload.data,
      };

    default:
      return state;
  }
};
