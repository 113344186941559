import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Services";
import home from "../../Images/HomePage/home.png";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import LaptopWindowsIcon from "@material-ui/icons/LaptopWindows";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import Footer from "../../component/footer/Footer";
import playstore from "../../Images/HomePage/playstore.png";
import apple from "../../Images/HomePage/apple.png";
import Header from "../../component/header/Header";
import PopUp from "./components/PopUp";
const Home = () => {
	const [style, setStyle] = React.useState("block");
	const [popup, setPopup] = React.useState(false);
	var prevScrollpos = 700;
	window.onscroll = function () {
		var currentScrollPos = window.pageYOffset;
		if (prevScrollpos > currentScrollPos) {
			setStyle("block");
		} else {
			setStyle("none");
		}
		prevScrollpos = 700;
	};

	return (
		<div>
			<div id="header" style={{ display: style }}>
				<Header trans="tran" color="white" />
			</div>
			{popup === true ? <PopUp setPopup={setPopup} popup={popup} /> : null}

			<section className="HomeTop">
				<div className="table">
					<div className="table-cell newTableCell">
						<div className="contentLeft1 newContentLeft1">
							<p className="headingMain newHeadingMain">
								Easy to use digital signage for increased customer engagement
							</p>
							<div className="subTagline newSubTagLine">
								<h4>
									SAVV signage makes it easy to manage your screens from
									anywhere and anytime.
								</h4>
								{localStorage.getItem("token") &&
								localStorage.getItem("userData") ? (
									<Link to={ROUTES.Screen}>
										{" "}
										<button className="searchbb newSearch">Get Started</button>
									</Link>
								) : (
									<Link to={ROUTES.Login}>
										{" "}
										<button className="searchbb newSearch">Get Started</button>
									</Link>
								)}
							</div>
						</div>

						<div
							className="videoWrapper newVideoWrapper"
							onClick={() => setPopup(true)}
						>
							<div className="videoBox newVideoBox">
								<video
									onClick={() => setPopup(true)}
									className="iframee"
									disablePictureInPicture="true"
									playsInline
									autoPlay
									loop
									muted
								>
									<source src="https://savvsignagefiles.s3.us-east-2.amazonaws.com/website/homePageVideo.mp4" />
								</video>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="HomeSection1 ">
				<div className="sub-section1 text-center mt-3">
					<h2>HOW IT WORKS</h2>
					<img
						className="section-img"
						alt="logo"
						style={{ width: "90%" }}
						src={home}
					></img>
				</div>
			</section>
			<section className="HomeSection2 newHomeSection2">
				<h2 className="heading mt-5" style={{ textAlign: "center" }}>
					KEY FEATURES
				</h2>
				<div className="row newRow" style={{ marginRight: 0 }}>
					<div className="col-md-3 col col-sm-3 Benefits newLeftOfTextCenter"></div>
					<div className="col-md-7 col-sm-7 text-center newTextCenter">
						<div className="text-left  d-flex mt-4 ">
							<PlaylistPlayIcon
								className="homeSection2Icons homeSection2Icons1"
								style={{ fontSize: "60" }}
							/>
							<div className="d-flex flex-column ml-2">
								<h3>Playlist</h3>
								<h5>
									Upload content to SAVV signage CMS
									<br />
									Create a playlist of images/videos uploaded to the CMS
								</h5>
							</div>
						</div>
						<div className=" text-left  d-flex mt-4 ">
							<LaptopWindowsIcon
								className="homeSection2Icons homeSection2Icons2"
								style={{ fontSize: "60" }}
							/>
							<div className="d-flex flex-column ml-2">
								<h3>Broadcast</h3>
								<h5>
									Assign content/playlist/ template to a screen
									<br />
									View the screen on any Android device, or Amazon FireStick
								</h5>
							</div>
						</div>
						<div className="text-left d-flex mt-3 ">
							<ColorLensIcon
								className="homeSection2Icons homeSection2Icons3"
								style={{ fontSize: "60" }}
							/>
							<div className="d-flex flex-column ml-2">
								<h3>Edit</h3>
								<h5>
									Choose from our library of custom templates
									<br />
									Create your own design using SAVV signage's simple editor
									functionalities for a unique customized display
								</h5>
							</div>
						</div>
					</div>
					<div className="col-md-2 col-sm-2 newRightOfTextCenter"></div>
				</div>

				{/* <div className="col-md-12 buttonFeature">
          <button className="buttonfea">See all features</button>
        </div> */}
			</section>
			<section className="homeSection3 newHomeSection3">
				<h2 className="heading mt-5" style={{ textAlign: "center" }}>
					Trusted By
				</h2>
				<div className="row mt-5" style={{ marginRight: 0 }}>
					<div className="col-md-2 col-sm-2 text-center">
						<div className="logoTrusted">Logo Here</div>
					</div>
					<div className="col-md-2 col-sm-2 text-center">
						<div className="logoTrusted">Logo Here</div>
					</div>
					<div className="col-md-2 col-sm-2 text-center">
						<div className="logoTrusted">Logo Here</div>
					</div>
					<div className="col-md-2 col-sm-2 text-center">
						<div className="logoTrusted">Logo Here</div>
					</div>
					<div className="col-md-2 col-sm-2 text-center">
						<div className="logoTrusted">Logo Here</div>
					</div>
				</div>
			</section>
			<section className="homeSection4 newHomeSection4 mt-5">
				<h2 className="heading py-5" style={{ textAlign: "center" }}>
					Ready To get started?
				</h2>
				<p
					style={{
						fontWeight: 100,
						marginTop: "15px",
						lineHeight: 1.6,
						width: "90%",
						margin: "auto",
						fontSize: "1.4em",
						textAlign: "justify",
					}}
				>
					{" "}
					Download the SAVV Signage App from the Google play store and Amazon
					firestore. Our signage player works on Amazon FireStick and any
					Android device or Android TV. Support for Roku and other platforms
					coming soon.
				</p>
				<div className="col-md-12 buttonFeature">
					{localStorage.getItem("token") && localStorage.getItem("userData") ? (
						<Link to={ROUTES.Screen}>
							{" "}
							<button className="buttonfea">Get Started</button>
						</Link>
					) : (
						<Link to={ROUTES.Login}>
							{" "}
							<button className="buttonfea">Get Started</button>
						</Link>
					)}
				</div>
				<div
					className="col-md-12  text-center d-flex newSeconsLastBottom"
					style={{ justifyContent: " center" }}
				>
					<img
						className="mt-3"
						alt="logo"
						src={playstore}
						style={{ height: "6%", width: "21%" }}
					/>
					<img src={apple} alt="logo" style={{ height: "6%", width: "21%" }} />
				</div>
			</section>
			<Footer></Footer>
		</div>
	);
};

export default Home;
