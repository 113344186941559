import React, { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { v4 } from "uuid";
import iconOne from "../images/icon1.png";
import iconTwo from "../images/Background.png";
import iconThree from "../images/Text.png";
import iconFour from "../images/Rectangle.png";
import iconFive from "../images/Circle.png";

const ImageMapItems = ({ canvasRef }) => {
  const bgRef = useRef();
  const imgRef = useRef();

  const handlers = {
    onAddItem: (item, centered) => {
      console.log("item :>> ", item);
      let opt = {
        option: { type: item },
      };
      // const { canvasRef } = this.props;
      //   if (canvasRef.handler.interactionMode === "polygon") {
      //     // message.info("Already drawing");
      //     return;
      //   }
      const id = v4();
      const option = Object.assign({}, opt.option, { id });
      //   if (item.option.superType === "svg" && item.type === "default") {
      //     this.handlers.onSVGModalVisible(item.option);
      //     return;
      //   }
      console.log("option :>> ", option);
      canvasRef.current.handler.add(option, centered);
    },
    onAddImage: (url, idBase64, imgType, centered) => {
      console.log("idBase64", idBase64);
      let opt = {
        option: { type: "image", imgType, url, idBase64 },
      };

      const id = v4();
      const option = Object.assign({}, opt.option, { id });
      canvasRef.current.handler.add(option, centered);
    },
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const handleInputChange = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    console.log("e.target :>> ", e.target.id);
    let id = e.target.id;
    let file = e.target.files[0];

    console.log("file :>> ", file);
    let idBase64 = "";
    getBase64(file, (result) => {
      idBase64 = result;
      let url = URL.createObjectURL(file);
      e.target.value = null;
      handlers.onAddImage(url, idBase64, id);
    });

    console.log("resule", idBase64);
    // console.log("resule", url);
  };

  return (
    <ul className="properties__nav d-flex sideBar">
      <div>
        <div className="upper__buttons">
          <div
            className="individual__buttons"
            onClick={() => bgRef.current.click()}
          >
            <div>
              <img className="image" src={iconTwo} />
            </div>
            <div>
              <li className="text-center">
                <Button className="button">Background</Button>
                <input
                  type="file"
                  id="bg"
                  ref={bgRef}
                  style={{ display: "none" }}
                  onChange={handleInputChange}
                />
              </li>
            </div>
          </div>
          <div
            className="individual__buttons"
            onClick={() => imgRef.current.click()}
          >
            <div>
              <img className="image" src={iconOne} />
            </div>
            <div>
              <li className="text-center">
                <Button className="button">Image</Button>
                <input
                  type="file"
                  id="img"
                  ref={imgRef}
                  style={{ display: "none" }}
                  onChange={handleInputChange}
                />
              </li>
            </div>
          </div>
          <div
            className="individual__buttons"
            onClick={() => handlers.onAddItem("iText")}
          >
            <div>
              <img className="image" src={iconThree} />
            </div>
            <div>
              <li className="text-center">
                {" "}
                <Button className="button">Text</Button>
              </li>
            </div>
          </div>
        </div>
        <div className="lower__buttons">
          <div
            className="individual__Lowerbuttons"
            onClick={() => handlers.onAddItem("rect")}
          >
            <div>
              <img className="image" src={iconFour} />
            </div>
            <div>
              <li className="text-center">
                {" "}
                <Button className="button">Rectangle</Button>
              </li>
            </div>
          </div>
          <div
            className="individual__Lowerbuttons"
            onClick={() => handlers.onAddItem("circle")}
          >
            <div>
              <img className="image" src={iconFive} />
            </div>
            <div>
              <li className="text-center">
                <Button className="button">Circle</Button>
              </li>
            </div>
          </div>
        </div>
      </div>
    </ul>
  );
};

export default ImageMapItems;
