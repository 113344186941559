import { React } from "react";
const ContactUs = () => {
  return (
    <div>
      <h2>Contact Us</h2>
      <h5>We Would Love to hear from you</h5>
    </div>
  );
};
export default ContactUs;
