import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./playlistModal.css";
import play from "../../../Images/commonImages/play.jpg";
import { useDispatch, useSelector } from "react-redux";
import { PlaylistAction } from "../../../ReduxStore/Actions";

const ModalPlaylist = (props) => {
  const data = useSelector((state) => state.authReducer.data);
  const playlist = useSelector((state) => state.playlistReducer?.data?.data);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      await dispatch(PlaylistAction.getPlaylist({ userId: data }));
    })();
  }, [data]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="playlist-modal-1"
          id="contained-modal-title-vcenter"
        >
          Playlist
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="playlistScreen-modal-body ">
        <div className="playlist-Screen-head d-flex flex-row row"></div>

        {playlist
          ? playlist.map((list, i) => (
              <div
                className="playist-Screen-body mt-3 ml-2 d-flex"
                name="playlist"
                onClick={(e) => {
                  console.log("list in map :>> ", list);
                  props.onMediaSelect(e, list, "playlist");
                  props.onHide();
                }}
              >
                <img src={play} alt="logo" />
                <div className=" col-md-5 d-flex flex-column ml-3">
                  <div className=" playlistEdit d-flex flex-row">
                    <p>{list.mediaName}</p>
                  </div>
                  <p className=""> Total items: {list.totalItems}</p>
                </div>
                <div className=" col-md-4 text-center "></div>
              </div>
            ))
          : null}
      </Modal.Body>
    </Modal>
  );
};
export default ModalPlaylist;
