export const initialState = {
  data: "",
  edit: "",
};
export const modalReducer = (state = initialState, action) => {
  // console.log("action",action)
  switch (action.type) {
    case "MODAL":
      return {
        ...state,
        data: action.payload,
      };
    case "EDIT":
      return {
        ...state,
        edit: action.payload,
      };
    default:
      return state;
  }
};
