import Api from "../../Api";
import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";
import { handleError } from "./commonAction";

//post content
export const getAdminTemplate = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.get(ENDPOINTS.GETADMINTEMPLATE)
      .then((res) => {
        console.log("resss", res);
        dispatch({
          type: ACTION_TYPE.TEMPLATE_GET_ADMIN,
          payload: res.data.data,
        });
        resolve(res?.data);
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
//userTemplate
export const getUserTemplate = (body) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.GETUSERTEMPLATE, body)

      .then((res) => {
        dispatch({
          type: ACTION_TYPE.TEMPLATE_GET_USER,
          payload: res.data,
        });
        resolve(res?.data);
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
// export const deleteTemplate = (params) => async (dispatch) => {
//   return new Promise((resolve, reject) => {
//     dispatch({ type: "LOADING_STARTED" });
//     Api.post(ENDPOINTS.DELETETEMPLATE, params)
//       .then((res) => {
//         console.log(res, "resin action");

//         dispatch({
//           type: ACTION_TYPE.DELETE_TEMPLATE,
//           payload: res.data,
//         });
//         resolve(res?.data.status);
//         dispatch({ type: "LOADING_COMPLETED" });
//       })
//       .catch((err) => {
//         dispatch({ type: "LOADING_FAILURE" });
//         dispatch(handleError(err));
//         reject(err);
//       });
//   });
// };
export const deleteTemplate = (params) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.DELETETEMPLATE, params);
    console.log(res, "re:>>");

    dispatch({
      type: ACTION_TYPE.DELETE_TEMPLATE,
      payload: { data: res.data },
    });
    if (res?.data) {
      return res?.data;
    }
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};
//get template by id
export const getAdminTemplateById = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.GETUSERTEMPLATEBYID, params)
      .then((res) => {
        dispatch({
          type: ACTION_TYPE.TEMPLATE_GET_ADMIN_BY_ID,
          payload: res,
        });
        resolve(res?.data);
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
export const getUserTemplateById = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.GETTEMPLATEBYID, params)
      .then((res) => {
        dispatch({
          type: ACTION_TYPE.TEMPLATE_GET_USER_BY_ID,
          payload: res,
        });
        resolve(res?.data);
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
export const subNavvalue = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    await dispatch({
      type: "SUB_NAV_VALUE",
      payload: body,
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};
//to save template
export const saveTemplate = (body) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    console.log("ressss", body);
    Api.post(ENDPOINTS.SAVETEMPLATE, body)

      .then((res) => {
        dispatch({ type: "LOADING_COMPLETED" });

        // dispatch({
        //   type: ACTION_TYPE.TEMPLATE_GET_USER,
        //   payload: res.data,
        // });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
//to update
export const updateTemplate = (body) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });

    Api.post(ENDPOINTS.UPDATETEMPLATE, body)

      .then((res) => {
        dispatch({ type: "LOADING_COMPLETED" });

        // dispatch({
        //   type: ACTION_TYPE.TEMPLATE_GET_USER,
        //   payload: res.data,
        // });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
//to save compinents of Template
export const saveComponentTemplate = (body) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    console.log("ressss", body);
    Api.post(ENDPOINTS.SAVECOMPONENTTEMPLATE, body)

      .then((res) => {
        dispatch({ type: "LOADING_COMPLETED" });

        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
export const saveWidget = (body) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.SAVEWIDGET, body)

      .then((res) => {
        dispatch({ type: "LOADING_COMPLETED" });

        // dispatch({
        //   type: ACTION_TYPE.TEMPLATE_GET_USER,
        //   payload: res.data,
        // });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
export const boxesValue = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.get(ENDPOINTS.BOXESVALUE)
      .then((res) => {
        // console.log("inTemp", res);
        dispatch({
          type: ACTION_TYPE.BOXESVALUE,
          payload: res.data,
        });
        resolve(res?.data);
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
