import React, { useEffect, useState } from "react";
import "./Content.css";
import { Form, Tab, Tabs, Toast } from "react-bootstrap";
import Header from "../../component/headerBar/Header";
import Modal1 from "./browseContent";
import DeleteIcon from "@material-ui/icons/Delete";
import { Divider, Grid } from "@material-ui/core";
import { ContentAction } from "../../ReduxStore/Actions";
import { useDispatch, useSelector } from "react-redux";
import content from "../../Images/commonImages/content.png";
import { mp4Thumbnail } from "../../Services/Constant/index";
import CardGrid from "../../component/cardViewGrid/cardGrid";
import SelectScreen from "../modal/SelectScreenModal/selectScreen";
import AlertDialog from "../../component/AlertDialog/alertDialog";

const Content = () => {
  const dispatch = useDispatch();
  const [showModal, setModal] = React.useState(false);
  const [showSlectScreen, setShowSelectScreen] = useState(false);
  const [toast, setToast] = React.useState(false);
  const [contentData, setContentData] = React.useState();
  const data = useSelector((state) => state.authReducer.data);
  const contentGet = useSelector((state) => state.contentReducer);
  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const loading = useSelector((state) => state.commonReducer.loading);
  const [select, setSelect] = useState();
  const [screenModalData, setScreenModalData] = useState();
  const [delOpen, setDelOpen] = useState({
    state: false,
    user: "none",
  });
  useEffect(() => {
    (async () => {
      if (data != "")
        await dispatch(ContentAction.getContent({ userId: data }));
    })();
  }, [data]);
  useEffect(() => {
    setContentData(contentGet.data);
  }, [contentGet]);

  const contentUploaded = async (e) => {
    let params = { userId: data };

    const form_Data = new FormData();
    form_Data.append("data", e.target.files[0]);
    form_Data.append("userId", data);
    await dispatch(ContentAction.postContent(form_Data));
    await dispatch(ContentAction.getContent({ userId: data }));
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleChangeList = (data) => {
    setContentData(data);
  };
  const clear = () => {
    setContentData(contentGet.data);
  };
  const handleClickDel = async (e, id) => {
    setSelect(id);
    e.preventDefault();
    e.stopPropagation();
    setDelOpen({
      state: false,
      user: "none",
    });
    let params = {
      userId: data,
      contentId: id,
    };
    const res = await dispatch(ContentAction.deleteContent(params));
    if (res) {
      await dispatch(ContentAction.getContent({ userId: data }));
    } else setToast(true);
  };
  const handleClickSetting = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setScreenModalData({
      userId: data,
      type: "content",
      typeId: id,
    });
    setShowSelectScreen(true);
  };
  const handleCardDelete = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setDelOpen({
      state: true,
      user: id,
    });
  };
  return (
    <div className="contentStart contentunique">
      <div className="row">
        <div className="col-md-2 col-sm-2 col-lg-2"></div>
        <div
          className="col-md-12 col-sm-10 col-lg-10 contentPadding"
          style={{ minHeight: "100vh" }}
        >
          <div className="contentstart">
            <div>
              <Header
                title="Contents"
                buttonName="Upload"
                dataList={contentData}
                handleChangeList={handleChangeList}
                placeholder=" Search Content"
                handleChange={() => setModal(true)}
                clear={clear}
              />

              <SelectScreen
                // className="content-modal"
                show={showSlectScreen}
                screenModalData={screenModalData}
                onHide={() => setShowSelectScreen(false)}
              />
              <Modal1
                className="content-modal"
                show={showModal}
                onHide={() => setModal(false)}
              />
              <AlertDialog
                open={delOpen}
                handleClose={() =>
                  setDelOpen({
                    state: false,
                    content: "none",
                  })
                }
                message="Are you sure you want to Delete?"
                button1="Yes"
                button2="No"
                handleDelete={handleClickDel}
              />
            </div>
          </div>

          <Divider className="mt-3" />
          <div className="content-body">
            <Toast
              className="ContentToast"
              show={toast}
              onClose={() => setToast(false)}
              delay={5000}
              autohide
            >
              <Toast.Header className="ContentToastBody">
                <strong className="mr-auto">{errorMessage}</strong>
              </Toast.Header>
            </Toast>

            {contentData?.length == 0 ? (
              <div className="content-body  text-center mt-5 mb-3">
                <h2>Content section contain your Uploaded files.</h2>
                <h5>No content, start Uploading some files.</h5>
                <img
                  className="content-img mt-5"
                  src={content}
                  alt="content img"
                />
              </div>
            ) : (
              <Tabs
                className="content-body1"
                defaultActiveKey="All"
                id="uncontrolled-tab-example"
              >
                <Tab eventKey="All" title="All" selected>
                  <div className="contenttab mt-5">
                    <div className="d-flex">
                      <Form className=" d-flex col-md-6"></Form>
                    </div>
                    <div className="content-value">
                      <div>
                        <Grid
                          className="data ml-2"
                          container
                          item
                          xs={12}
                          spacing={3}
                        >
                          {contentData?.map((user) => (
                            // <div></div>
                            <CardGrid
                              url={
                                user.type === "video/mp4"
                                  ? mp4Thumbnail
                                  : user.type === "image/jpeg" ||
                                    user.type === "image/png"
                                  ? user.url
                                  : null
                              }
                              handleClickSetting={handleClickSetting}
                              loadSpinnerfor={select}
                              id={user.id}
                              name={user.name}
                              timeStam={user.created_ts.split("T")[0]}
                              handleDel={handleCardDelete}
                            />
                          ))}
                        </Grid>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Video" title="Video">
                  <div className="content-value">
                    <div className="d-flex mt-5">
                      <Grid
                        className="data ml-3"
                        container
                        item
                        xs={12}
                        spacing={3}
                      >
                        {contentData?.map((user) =>
                          user.type === "video/mp4" ? (
                            <CardGrid
                              loadSpinnerfor={select}
                              id={user.id}
                              url={mp4Thumbnail}
                              name={user.name}
                              timeStam={user.created_ts.split("T")[0]}
                              handleDel={handleCardDelete}
                            />
                          ) : null
                        )}
                      </Grid>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="Images" title="Images">
                  <div className="content-value">
                    <div className="d-flex mt-5">
                      <Grid
                        className="data ml-3"
                        container
                        item
                        xs={12}
                        spacing={3}
                      >
                        {contentData?.map((user) =>
                          user.type === "image/jpeg" ||
                          user.type === "image/png" ? (
                            <CardGrid
                              loadSpinnerfor={select}
                              url={user.url}
                              id={user.id}
                              handleDel={handleCardDelete}
                              name={user.name}
                              timeStam={user.created_ts.split("T")[0]}
                            />
                          ) : null
                        )}
                      </Grid>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Content;
