import Api from "../../Api";
import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";
import { handleError } from "./commonAction";
//post content
export const postContent = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    // dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.POSTCONTENT, params)
      .then((res) => {
        console.log("inn post content", res, params);
        // dispatch({
        //   type: ACTION_TYPE.CONTENT_POST,
        //   payload: res.data,
        // });
        resolve(res?.data);
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
export const getContent = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    // dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.GETCONTENT, params)
      .then((res) => {
        dispatch({
          type: ACTION_TYPE.CONTENT_GET,
          payload: res.data,
        });
        resolve(res?.data.status);
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
// export const deleteContent = (params) => async (dispatch) => {
//   return new Promise((resolve, reject) => {
//     dispatch({ type: "LOADING_STARTED" });
//     Api.post(ENDPOINTS.DELETE_CONTENT, params)
//       .then((res) => {
//         console.log(res, "resin action");
//         dispatch({
//           type: ACTION_TYPE.CONTENT_DELETE,
//           payload: res.data,
//         });
//         resolve(res?.data.status);
//         dispatch({ type: "LOADING_COMPLETED" });
//       })
//       .catch((err) => {
//         dispatch({ type: "LOADING_FAILURE" });
//         dispatch(handleError(err));

//         reject(err);
//       });
//   });
// };
export const deleteContent = (params) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.DELETE_CONTENT, params);
    console.log(res, "re:>>");

    dispatch({
      type: ACTION_TYPE.CONTENT_DELETE,
      payload: { data: res.data },
    });
    if (res?.data) {
      return res?.data;
    }
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};
// export const getContent = (id) => async (dispatch) => {
//   try {
//     dispatch({ type: "LOADING_STARTED" });
//     const res = await Api.post(ENDPOINTS.GETCONTENT, id);
//     dispatch({
//       type: ACTION_TYPE.CONTENT_GET,
//       payload: { data: res.data },
//     });
//     resolve(res?.data.status);
//     dispatch({ type: "LOADING_COMPLETED" });
//   } catch (error) {
//     dispatch({ type: "LOADING_FAILURE" });
//     // dispatch(handleError(error));
//   }
// };
