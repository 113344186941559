import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { modalReducer } from "./modalReducer";
import { contentReducer } from "./contentReducer";
import { playlistReducer } from "./playlistReducer";
import { templateReducer } from "./templateReducer";
import { screenReducer } from "./screenReducer";
import { adminReducer } from "./adminReducer";
import { bookReducer } from "./bookReducer";
import { brandingReducer } from "./brandingReducer";
import { commonReducer } from "./commonReducer";
import { scheduleReducer } from "./scheduleReducer";
import { subscriptionReducer } from "./subscriptionReducer";
const appReducer = combineReducers({
  authReducer,
  modalReducer,
  contentReducer,
  playlistReducer,
  templateReducer,
  screenReducer,
  adminReducer,
  bookReducer,
  commonReducer,
  brandingReducer,
  scheduleReducer,
  subscriptionReducer,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    state = initialState;
  }

  return appReducer(state, action);
};
export default rootReducer;
