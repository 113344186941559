import React from "react";
import { Link } from "react-router-dom";
import "./subNav.css";
import { ROUTES } from "../../Services/Constant";
import { useDispatch, useSelector } from "react-redux";
import { TemplateAction } from "../../ReduxStore/Actions";
import { useEffect } from "react";
const SubNav = ({ selectedTab, changeSelectedTab }) => {
  const dispatch = useDispatch();
  const template = useSelector((state) => state.templateReducer.subNavValue);
  const [dropdowndisplay, setdropdowndisplay] = React.useState("block");

  useEffect(() => {
    dispatch(TemplateAction.subNavvalue("allTemplates"));
  }, []);
  const handleClick = async (e, value) => {
    await dispatch(TemplateAction.subNavvalue(value));
    changeSelectedTab(value);
    if (value === "All") {
      dropdowndisplay === "none"
        ? setdropdowndisplay("block")
        : setdropdowndisplay("none");
    }
  };

  return (
    <div className="subNav">
      <div className="  msb-sub">
        <nav className="navbar " role="navigation">
          <ul style={{ display: dropdowndisplay }} className="mt-2  secondList">
            <li
              className={
                template === "allTemplates" ? "num1  active " : "num1 "
              }
              onClick={(e) => handleClick(e, "allTemplates")}
            >
              <Link to={ROUTES.ViewProduct}>All</Link>
            </li>
            <li
              className={
                template === "Season" ? "num1 mt-2 active " : "num1  mt-2 "
              }
              onClick={(e) => handleClick(e, "Season")}
            >
              <Link to={ROUTES.ViewProduct}>Season</Link>
            </li>
            <li
              className={
                template === "Covid" ? "num1 mt-2 active " : "num1  mt-2 "
              }
              onClick={(e) => handleClick(e, "Covid")}
            >
              <Link to={ROUTES.Schedul}>Covid</Link>
            </li>
            <li
              className={
                template === "Lifestyle" ? "num1 mt-2 active " : "num1  mt-2 "
              }
              onClick={(e) => handleClick(e, "Lifestyle")}
            >
              <Link to={ROUTES.Schedul}>Lifestyle</Link>
            </li>
            <li
              className={
                template === "Education" ? "num1 mt-2 active " : "num1  mt-2 "
              }
              onClick={(e) => handleClick(e, "Education")}
            >
              <Link to={ROUTES.Schedul}>Education</Link>
            </li>
            <li
              className={
                template === "Widgets" ? "num1 mt-2 active " : "num1  mt-2 "
              }
              onClick={(e) => handleClick(e, "Widgets")}
            >
              <Link to={ROUTES.Schedul}>Widgets</Link>
            </li>
            <li
              className={
                template === "Design" ? "num1 mt-2 active " : "num1  mt-2 "
              }
              onClick={(e) => handleClick(e, "Design")}
            >
              <Link to={ROUTES.Schedul}>Design</Link>
            </li>
          </ul>
          {/* </div> */}
        </nav>
      </div>

      {/* //   <div className="col-md-9 col-sm-9"></div> */}
    </div>
  );
};
export default SubNav;
