// import Api from "../../services/baseServices";
// import { handleError } from "./commonAction";
// import { toast } from "../../utils/toast";
// import { ACTION_TYPE } from "../../constants/actionTypes";
import Api from "../../Api";

import {
  ACTION_TYPE,
  APPLICATION_CONSTANTS,
  ENDPOINTS,
} from "../../Services/index";

export function getStripeAction(body) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      console.log("eee", body);

      const res = await Api.post(ENDPOINTS.GET_STRIPE, body);
      console.log("instripeAction", res);

      dispatch({
        type: "GET_STRIPE_CLIENT_KEY",
        payload: { data: res.data.client_secret },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      //   dispatch(handleError(error));
    }
  };
}
export function confirmStripe(body) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });
      console.log("eee", body);

      const res = await Api.post(ENDPOINTS.CONFIRM_STRIPE, body);
      console.log("instripeAction", res);

      dispatch({
        type: ACTION_TYPE.CONFIRM_STRIPE,
        payload: { data: res.data },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      //   dispatch(handleError(error));
    }
  };
}
