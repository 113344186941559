import React from "react";
import { ROUTES } from "../Services/Constant";
import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
  withRouter,
} from "react-router-dom";
import finalScreen from "../DashboardModule/Screens/finalScreen";
import ContactUs from "../DashboardModule/contactUs/contactUs";
import Sidenav from "../DashboardModule/sideNav/SideNav";
import EditTemplateNew from "../DashboardModule/Template/editTemplate/editTemplateNew";
import EditPlaylist from "../DashboardModule/Playlist/component/Editplaylist";
import EditScreen from "../DashboardModule/Screens/components/editScreen";
import NewDesign from "../DashboardModule/NewDesign";
import MyDesigns from "../DashboardModule/MyDesigns";
// import EditSchedule from "../DashboardModule/Scheduling/EditSchedule/editEvent";
import PreviewTemplate from "../DashboardModule/Template/previewTemplate";

const WebsiteModule = () => {
  console.log(
    localStorage.getItem("token") && localStorage.getItem("userData"),
    "lop"
  );

  const showConfirm = (e) => {
    // console.log("showConfirm :>> ", e);
    alert("do you leave");
  };
  return (
    <div>
      {localStorage.getItem("token") && localStorage.getItem("userData") ? (
        <div>
          <Sidenav />
          <Route path={ROUTES.finalScreen} component={finalScreen} />
          <Route path={ROUTES.PreviewTemplate} component={PreviewTemplate} />
          <Route path={ROUTES.ContactUs} component={ContactUs} />
          <Route path={ROUTES.EditPlaylist} component={EditPlaylist} />
          <Route path={ROUTES.EditTemplate} component={EditTemplateNew} exact />
          <Route path={ROUTES.EditScreen} component={EditScreen} exact />
          {/* <Route path={ROUTES.EditSchedule} component={EditSchedule} /> */}
          <Route path={ROUTES.EditDesign} component={NewDesign} exact />

          <Route path={ROUTES.NewDesign} component={NewDesign} exact />
          <Route path={ROUTES.MyDesign} component={MyDesigns} exact />
        </div>
      ) : (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      )}
    </div>
  );
};
export default WebsiteModule;
