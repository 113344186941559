import Api from "../../Api";
import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";
//add branding
export const addBranding = (formData) => async (dispatch) => {
  console.log("updateProfileDetails type hittt", formData);

  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.ADDBRANDING, formData)
      .then((res) => {
        console.log("profile ", res);
        console.log(res, "res in contentpost");

        dispatch({
          type: ACTION_TYPE.ADD_BRANDING,
          payload: res.data,
        });
        resolve(res?.data.status);
        console.log(res.data, "kk");
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
//editBranding
export const editBranding = (params) => async (dispatch) => {
  console.log("updateProfileDetails type hittt", params);

  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.EDITBRANDING, params)
      .then((res) => {
        console.log("profile ", res.data);
        console.log(res, params, "res in contentpost");

        dispatch({
          type: ACTION_TYPE.EDIT_BRANDING,
          payload: res.data,
        });
        resolve(res?.data.status);
        console.log(res.data, "kk");
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
//getBranding
export const getBranding = (params) => async (dispatch) => {
  console.log("updateProfileDetails type hittt", params);

  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.GETBRANDING, params)
      .then((res) => {
        console.log("profile ", res);
        console.log(res, params, "res in contentpost");

        dispatch({
          type: ACTION_TYPE.GET_BRANDING,
          payload: res.data,
        });
        resolve(res?.data.status);
        console.log(res.data, "kk");
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
//getBrandingbyid
export const getBrandingById = (params) => async (dispatch) => {
  console.log("updateProfileDetails type hittt", params);

  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.GETBRANDINGBYID, params)
      .then((res) => {
        console.log("state", res);
        console.log(res, params, "res in contentpost");

        dispatch({
          type: ACTION_TYPE.GET_BRANDING_ID,
          payload: res.data,
        });
        resolve(res?.data.status);
        console.log(res.data, "kk");
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
