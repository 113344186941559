import React, { Component } from "react";
import { v4 } from "uuid";
import { fabric } from "fabric";

class EventHandler extends Component {
  constructor(handler) {
    super(handler);
    this.handler = handler;
    this.initialize();
  }

  initialize = () => {
    // if (this.handler.editable) {
    this.handler.canvas.on({
      // 'object:modified': this.modified,
      // 'object:scaling': this.scaling,
      // 'object:scaled': this.scaled,
      // 'object:moving': this.moving,
      // 'object:moved': this.moved,
      // 'object:rotating': this.rotating,
      // 'object:rotated': this.rotated,
      // 'mouse:wheel': this.mousewheel,
      // 'mouse:down': this.mousedown,
      // 'mouse:move': this.mousemove,
      // 'mouse:up': this.mouseup,
      "selection:cleared": this.selection,
      "selection:created": this.selection,
      "selection:updated": this.selection,
    });
    // } else {
    // 	this.handler.canvas.on({
    // 		'mouse:down': this.mousedown,
    // 		'mouse:move': this.mousemove,
    // 		'mouse:out': this.mouseout,
    // 		'mouse:up': this.mouseup,
    // 		'mouse:wheel': this.mousewheel,
    // 	});
    // }
  };

  selection = (opt) => {
    console.log(
      "this. :>> ",
      this.handler.canvas.getActiveObject()?.get("type")
    );
    console.log("opt :>> ", opt);
    console.log("this.handler :>> ", this.handler);
    const { onSelect, activeSelectionOption } = this.handler;
    const target = opt.target;
    if (target && target.type === "activeSelection") {
      target.set({
        ...activeSelectionOption,
      });
    }
    if (onSelect) {
      onSelect(target);
    }
  };
}

export default EventHandler;
