import { ACTION_TYPE, APPLICATION_CONSTANTS } from "../../Services/index";
export const initialState = {
  plan: [],
};

export const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.TEMPLATE_GET_ADMIN:
      return {
        ...state,
        plan: action.payload.data,
      };

    default:
      return state;
  }
};
