import React, { useEffect } from "react";
import { Button, Form, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import "./content.css";
import { mp4Thumbnail } from "../../../Services/Constant/index";
import { modalAction } from "../../../ReduxStore/Actions/index";
import { useDispatch, useSelector } from "react-redux";
import TemplateImage1 from "../../../Images/commonImages/TemplateImage1.png";
import { ContentAction, ScreenAction } from "../../../ReduxStore/Actions";
import { Grid, IconButton } from "@material-ui/core";
import docImage from "../../../Images/commonImages/docImage.png";
import firebase from "../../../Services/Util/firebase";
function ModalContent(props) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.authReducer.data);
  const contentGet = useSelector((state) => state.contentReducer);
  const code = useSelector((state) => state.screenReducer.code);
  useEffect(() => {
    (async () => {
      let params = { userId: data };
      const dummy = data
        ? await dispatch(ContentAction.getContent(params))
        : null;
    })();
  }, []);

  let k = 0;
  const showPreview = async (e) => {
    let params = { userId: data };

    const form_Data = new FormData();
    form_Data.append("data", e.target.files[0]);
    form_Data.append("userId", data);
    await dispatch(ContentAction.postContent(form_Data));
    await dispatch(ContentAction.getContent(params));
  };

  const handleClick = async (e, media) => {
    console.log("media :>> ", media);
    const { id, name, url } = media;
    const type = "content";
    const userId = data;
    let params;
    console.log("clicked");
    params = {
      name: name,
      uploadDate: "30 march 2021",
    };
    // let code = Math.floor(Math.random() * 28);
    // let code = "5d14e1";

    const db = firebase.database();
    const screensRef = db.ref("screens");
    const mediaRef = db.ref("media");
    const codesRef = db.ref("codes");

    const mediaPush = await mediaRef.push({
      id,
      type,
      url,
    });

    const firebase_media_id = mediaPush.key;

    const screenPush = await screensRef.push({
      userId,
      type,
      media: {
        [firebase_media_id]: true,
      },
      active: true,
    });

    const firebase_screen_id = screenPush.key;

    codesRef.child(code).update({
      // active: true,
      screen: firebase_screen_id,
    });

    let apiParams = {
      userId,
      type,
      mediaId: id,
      mediaType: type,
      firebase_screen_id,
      firebase_media_id,
      code,
    };

    await dispatch(ScreenAction.addScreen(apiParams));

    let getParams = {
      userId,
    };
    dispatch(ScreenAction.getScreen(getParams));
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="content-modal-1"
          id="contained-modal-title-vcenter"
        >
          Content
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="content-modal-body contentmodal contentmodalunique">
        <Tabs defaultActiveKey="All" id="uncontrolled-tab-example">
          <Tab className="tabb" eventKey="All" title="All" selected>
            <div className="content-tab mt-2">
              <div className="d-flex">
                <Grid className="data ml-2" container item xs={12} spacing={3}>
                  {contentGet?.data?.map((user) => (
                    <div
                      className="card mt-5"
                      name="content"
                      onClick={(e) => {
                        props.onMediaSelect(e, user, "content");
                        props.onHide();
                      }}
                    >
                      {user.type === "video/mp4" ? (
                        <img src={mp4Thumbnail} />
                      ) : null}

                      {user.type === "image/jpeg" ||
                      user.type === "image/png" ? (
                        <img src={user.url} />
                      ) : null}

                      <div className="">
                        <div className=" row11 text-left">
                          <p className="cardName">{user.name}</p>
                          <p className="cardDate d-block">
                            {user.created_ts.split("T")[0]}
                          </p>
                          {/* <DeleteIcon /> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </Grid>
              </div>
            </div>
          </Tab>
          <Tab eventKey="Video" title="Video">
            <div className="content-tab">
              <div className="d-flex">
                <Grid className="data" container item xs={12} spacing={3}>
                  {contentGet?.data?.map((user) =>
                    user.type === "video/mp4" ? (
                      <div
                        className="card mt-5"
                        onClick={(e) => {
                          props.onMediaSelect(e, user);
                          props.onHide();
                        }}
                      >
                        <img src={mp4Thumbnail} />

                        <div className="">
                          <div className=" row11 text-left">
                            <p className="cardName">{user.name}</p>
                            <p className="cardDate d-block">
                              {user.created_ts.split("T")[0]}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                </Grid>
              </div>
            </div>
          </Tab>
          <Tab eventKey="Images" title="Images">
            <div className="content-tab">
              <div className="d-flex">
                <Grid className="data" container item xs={12} spacing={3}>
                  {contentGet?.data?.map((user) =>
                    user.type === "image/jpeg" || user.type === "image/png" ? (
                      <div
                        className="card mt-5"
                        onClick={(e) => {
                          props.onMediaSelect(e, user);
                          props.onHide();
                        }}
                      >
                        <img src={user.url} />

                        <div className="">
                          <div className=" row11 text-left">
                            <p className="cardName">{user.name}</p>
                            <p className="cardDate d-block">
                              {user.created_ts.split("T")[0]}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                </Grid>
              </div>
            </div>
          </Tab>
          {/* <Tab eventKey="Docs" title="Docs">
            <div className="content-tab">
              <Grid className="data mt-2" container item xs={12} spacing={3}>
                {contentGet?.data?.map((user) =>
                  user.type === "application/pdf" ||
                  user.type ===
                    "application/vnd.openxmlformats-officedocument" ? (
                    <div
                      className="card"
                      onClick={(e) => {
                        props.onMediaSelect(e, user);

                        props.onHide();
                      }}
                    >
                      <img
                        src={docImage}
                       
                      />

                      <div className="">
                        <div className=" row11 text-left">
                          <p className="cardName">{user.name}</p>
                          <p className="cardDate d-block">{user.created_ts}</p>
                        </div>
                      </div>
                    </div>
                  ) : null
                )}
              </Grid>
            </div>
          </Tab> */}
        </Tabs>
      </Modal.Body>

      {/* <Modal.Footer>
        <div className="text-right col-md-6 ">
          <Button className="button-confirm-content">Confirm</Button>
        </div>
      </Modal.Footer> */}
    </Modal>
  );
}
export default ModalContent;
