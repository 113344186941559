import React, { useEffect, useState } from "react";
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";
// import "./AddScreen.css";
import Content from "../../modal/content/content";
import Template from "../../modal/Template/template";
import PlaylistModal from "../../modal/playlistModal/PlaylistModal";
import { useSelector, useDispatch } from "react-redux";
import {
	ScreenAction,
	ScheduleAction,
} from "../../../ReduxStore/Actions/index";

// import { ScreenAction } from "../../ReduxStore/Actions";
// import firebase from "../../Services/Util/firebase";

const EditScreenModal = (props) => {
	console.log("editScreenModal props :>> ", props);
	const dispatch = useDispatch();
	const [state, setState] = React.useState();
	const { id: userId } = useSelector((state) => state.authReducer.authData);

	const [showModal, setModal] = React.useState({
		Template: false,
		Content: false,
		Playlist: false,
	});
	const [typeName, setTypeName] = React.useState();
	const [media, setMedia] = useState();
	const [mediaType, setMediaType] = useState();
	const data = useSelector((state) => state.modalReducer.data);
	const code = useSelector((state) => state.screenReducer.code);
	const scheduleList = useSelector((state) => state.scheduleReducer.list);
	const dataId = useSelector((state) => state.authReducer.data);
	const [scheduleSelected, setScheduleSelected] = useState();
	const [mediaName, setMediaName] = useState("");
	useEffect(() => {
		setTypeName(data.name);
	}, [data]);

	useEffect(() => {
		setMediaName(props.selected);
	}, [props.selected]);

	//To close the  child modal popup
	const handleChangeModal = (e, name) => {
		setModal({
			[name]: false,
		});
	};

	// to open the child modal
	const handleClick = (e, name) => {
		setModal({
			...showModal,
			[name]: true,
		});
	};
	//to Set the state accordingto the change in type input and display respective modal
	const handleChange = (e) => {
		setMediaType(e.target.value);
		console.log("inHandleChnagee :>> ", [e.target.name], e.target.value);
		if (e.target.value === "Schedule") {
			dispatch(ScheduleAction.getSchedule({ userId: dataId }));
		}
		setState({
			[e.target.name]: e.target.value,
		});
		let name = "show" + e.target.value;
		setMediaName("");
	};

	//   const onMediaSelect = (e, selectedMedia) => {
	//     e.preventDefault();
	//     e.stopPropagation();
	//     console.log("media :>> ", selectedMedia);

	//     setMedia(selectedMedia);
	//   };
	// console.log(props, props.selected, state, "lop");
	const handleChangeSchedule = (e) => {
		let nodeId;
		for (let node of e.target.children) {
			if (node.value === e.target.value) {
				console.log(
					"node.getAttribute(-id) :>> ",
					node.getAttribute("data-id")
				);
				nodeId = node.getAttribute("data-id");
				// this.setState({
				//   selected: node.getAttribute('data-id')
				// });
			}
		}
		console.log("in handleChangeschedule", e);
		let params = {
			// userId: data,
			id: nodeId,
			// mediaType: "schedule",
			// screenName,
		};
		console.log(params, "<::params");
		// setScheduleSelected(e.target.value);
		// setMedia(params);
		// let params = {
		//   userId: dataId,
		//   mediaId: e.target.value,
		//   mediaType: "Schedule",
		//   screenName,
		// };
		setScheduleSelected(e.target.value);
		props.onMediaSelect(e, params, "schedule");
	};

	const handleDone = () => {
		setState();
		props.onHide();
	};

	return (
		<div className="addScreen-modal">
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header className="col-md-12 " closeButton>
					<Modal.Title
						className="Addscreen-modal-1"
						id="contained-modal-title-vcenter"
					>
						Edit Screen
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="Addscreen-modal-body">
					<div className="d-flex flex-column">
						<div className="d-flex flex-row  align-items-center1 pairingScreenFields">
							<label>Type*</label>
							<select
								name="Type"
								id="type"
								className="Addscreen-modal-input"
								placeholder="Type"
								onChange={(e) => handleChange(e)}
							>
								<option value="" disabled selected>
									Type
								</option>
								<option className="content-option" value="Content">
									Content
								</option>
								<option value="Playlist">Playlist</option>
								<option value="Template">Template</option>
								{/* <option value="Schedule">Schedule</option> */}
							</select>
						</div>
						{state?.Type?.length > 0 ? (
							<div className="d-flex flex-row align-items-center1 pairingScreenFields">
								<label>Selected {state.Type}*</label>
								{
									state.Type === "Schedule" ? (
										<select
											name="Type"
											id="type"
											className="Addscreen-modal-input"
											// placeholder="Type"
											// value={scheduleSelected?.name}
											onChange={handleChangeSchedule}
										>
											{scheduleList.length > 0 ? (
												scheduleList.map((item) => {
													return (
														<option
															value={item.id}
															key={item.id}
															data-id={item.id}
														>
															{item.name}
														</option>
													);
												})
											) : (
												<option
												// value={item.}
												// key={item.id}
												// data-id={item.id}
												>
													No schedule to show
												</option>
											)}
										</select>
									) : (
										<InputGroup
											className="mb-3"
											className="Addscreen-modal-input"
											style={{ padding: "0px" }}
											name={state.Type}
										>
											<FormControl
												// value={props?.selected}
												value={mediaName}
												aria-describedby="basic-addon2"
											/>
											{/* <InputGroup.Append> */}
											<Button
												name={state.Type}
												variant="outline-secondary"
												onClick={(e) => handleClick(e, e.target.name)}
											>
												Change
											</Button>
											{/* </InputGroup.Append> */}
										</InputGroup>
									)
									// </div>)
								}
							</div>
						) : null}
					</div>
				</Modal.Body>

				<Modal.Footer>
					<div className="d-flex Addscreen-button">
						<div className="col-sm-12 text-center">
							<Content
								className="modal-class"
								show={showModal.Content}
								onHide={(e) => handleChangeModal(e, "Content")}
								onMediaSelect={props.onMediaSelect}
							/>
							<Template
								className="modal-class"
								show={showModal.Template}
								onHide={(e) => handleChangeModal(e, "Template")}
								onMediaSelect={props.onMediaSelect}
							/>
							<PlaylistModal
								className="modal-class"
								show={showModal.Playlist}
								onHide={(e) => handleChangeModal(e, "Playlist")}
								onMediaSelect={props.onMediaSelect}
							/>

							<Button className="addScreenButton4" onClick={handleDone}>
								Done
							</Button>
						</div>
					</div>
				</Modal.Footer>
			</Modal>
		</div>
	);
};
export default EditScreenModal;
