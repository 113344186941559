import { Button } from "@material-ui/core";
import { ContactSupportOutlined } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { BrandingAction } from "../../../ReduxStore/Actions";
import { ROUTES } from "../../../Services";

import Branding from "../Branding";

const BrandingSave = (props) => {
  const {
    boxes,
    handleTextWidth,
    fontText,
    rect,
    bgColor,
    fontColor,
    family,
    colorSecondary,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector((state) => state.authReducer.data);

  const canvas = useRef(null);
  const bgCanvas = useRef(null);
  const finalCanvas = useRef(null);
  const image = useRef();
  const paddingCanvas = 0;
  const defaultText = "Enter Text";
  const [imageUrl, setImageUrl] = useState();
  // let ctx = null;
  // console.log("outside return", ctx);
  // let bgCtx = null;

  // const [boxes, setBoxes] = useState([]);

  // const boxes = [
  // { x: 200, y: 220, w: 100, h: 50, text: },
  // { x: 100, y: 120, w: 100, h: 50 },
  // ];
  let isDown = false;
  let dragTarget = null;
  let startX = null;
  let startY = null;
  useEffect(() => {
    // const canvas = this.refs.canvas

    // const { text } = fontStyles;
    console.log("in Init useeffect", rect);
    const finalCtx = finalCanvas.current.getContext("2d");
    const bgCtx = bgCanvas.current.getContext("2d");
    const ctx = canvas.current.getContext("2d");
    const img = image.current;

    const canvasEle = canvas.current;
    canvasEle.width = canvasEle.clientWidth;
    canvasEle.height = canvasEle.clientHeight;
    const logo = new Image();
    logo.src = boxes[4] && boxes[4].logoUrl;
    logo.crossOrigin = "Anonymous";
    logo.onload = () => {
      setImageUrl(logo);
      draw(ctx);
    };
    // img.onload = () => {
    // ctx.drawImage(img, 0, 0);
    // bgCtx.drawImage(img, 0, 0);

    // draw(ctx);

    // // let width = ctx.measureText(fontText).width;
    // // setBoxes([...boxes, { x: 200, y: 220, w: width, h: 50, text: fontText }]);
    // boxes.map((info) => drawFillRect(ctx, info));
    // drawFillRectangle(ctx, colorSecondary);
    // // [...boxes, { x: 200, y: 220, w: width, h: 50 }].map((info) =>
    // // drawFillRect(ctx, info)
    // // );
    // };
    bgCtx.fillStyle = bgColor;

    bgCtx.fillRect(0, 0, 340, 225);
  }, []);

  useEffect(() => {
    console.log("boxes :>> ", boxes);
    const logo = new Image();
    logo.src = boxes[4]?.logoUrl;
    logo.crossOrigin = "Anonymous";
    logo.onload = () => {
      // handleBoxChange(logo, 1);
      setImageUrl(logo);

      // draw(canvas.current.getContext("2d"));
    };
    draw(canvas.current.getContext("2d"));
  }, [boxes]);
  useEffect(() => {
    // console.log("content image", contentImage);
    // handleBoxChange(logoUrl, 1);
    draw(canvas.current.getContext("2d"));
  }, [imageUrl]);
  // useEffect(() => {
  // drawFillRectangle(canvas.current.getContext("2d"), colorSecondary);
  // }, [colorSecondary]);
  const draw = (ctx) => {
    // console.log(
    // "draw :>> ",
    // canvas.current.clientWidth,
    // canvas.current.clientHeight
    // );
    console.log("ctx :>> ", ctx);
    console.log("dragTarget :>> ", dragTarget);
    ctx.clearRect(
      0,
      0,
      canvas.current.clientWidth,
      canvas.current.clientHeight
    );
    console.log("boxes in draw :>> ", boxes);
    // drawFillRectangle(ctx, colorSecondary);
    boxes.map((info) => drawFillRect(ctx, info));
  };

  const drawFillRect = (ctx, info) => {
    console.log("ctx in drawfillrect :>> ", ctx);
    const { xPos, yPos, width, height } = info;
    let font = info.fontSize + " " + info.fontFamily;
    console.log(font, "info :>> ", info);
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = font;
    if (info.componentType == "background") {
    } else if (info.componentType == "title") {
      console.log("inBody", info);
      ctx.fillStyle = info.color;
      ctx.fillText(info.text, xPos, yPos);

      var maxWidth = 300 * 0.8;
      // var lineHeight = 30;
      // wrapText(ctx, info.text, xPos, yPos, maxWidth, lineHeight);
    } else if (info.componentType == "body") {
      console.log("inBody", info);
      ctx.fillStyle = info.color;

      ctx.fillText(info.text, xPos, yPos);
    } else if (info.componentType == "divider") {
      ctx.fillStyle = info.color;
      ctx.fillRect(20, 165, 300, 10);
    } else if (info.componentType == "logo" && imageUrl) {
      ctx.drawImage(imageUrl, xPos, yPos, width, height);
    }
  };
  const wrapText = (context, text, xPos, yPos, maxWidth, lineHeight) => {
    console.log("text :>> ", text);
    var words = text.split(" ");
    var line = "";

    for (var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + " ";
      console.log("testLine :>> ", testLine);
      var metrics = context.measureText(testLine);
      var testWidth = metrics.width;
      console.log("testWidth :>> ", testWidth);
      if (testWidth > maxWidth && n > 0) {
        context.fillText(line, xPos, yPos);
        line = words[n] + " ";
        yPos += lineHeight;
      } else {
        line = testLine;
      }
    }
    context.fillText(line, xPos, yPos);
  };
  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };
  console.log(boxes);
  useEffect(() => {
    // const { text } = fontStyles;
    console.log("in use effect of text", fontText.body);
    console.log("boxes :>> ", boxes);
    const bgCtx = bgCanvas.current.getContext("2d");

    let width = canvas.current.getContext("2d").measureText(fontText.body)
      .width;
    // handleTextWidth(width);
    bgCtx.fillStyle = boxes && boxes[0] && boxes[0].color;
    bgCtx.fillRect(0, 0, 340, 225);
  }, [fontText, bgColor, fontColor, colorSecondary, family]);

  const handleClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    finalCanvas.current.getContext("2d").drawImage(bgCanvas.current, 0, 0);
    finalCanvas.current.getContext("2d").drawImage(canvas.current, 0, 0);

    var image = finalCanvas.current.toDataURL();

    const form_Data = new FormData();
    const file = await DataURIToBlob(image);
    console.log("props.logo :>> ", props.logo);
    form_Data.append("data", file);
    form_Data.append("logo", props.logo);
    form_Data.append("userId", userId);
    form_Data.append("name", props.brandingName);
    console.log("boxes before append", boxes);
    form_Data.append("components", JSON.stringify(boxes));

    console.log(form_Data, "jjjj");
    await dispatch(BrandingAction.addBranding(form_Data));
    props.hide();
  };
  return (
    <div className="d-flex">
      <div className="d-flex " style={{ marginTop: "300px" }}>
        <canvas
          ref={finalCanvas}
          width={340}
          height={225}
          style={{ marginLeft: "20px" }}
        />

        <canvas
          ref={bgCanvas}
          width={340}
          height={225}
          style={{ marginLeft: "20px" }}
        />
        <canvas
          ref={canvas}
          width={340}
          height={225}
          style={{ marginLeft: "20px" }}
        />
        {/* <img
ref={image}
// src={color}
// style={{ backgroundColor: bgColor }}
hidden
/> */}
      </div>
      <div className="col-sm-12">
      <div
        className="d-flex "
        style={{
          marginTop: "525px",
          marginLeft: "-122px",
          zIndex: "9",
        }}
      >
        <div className="row d-flex">
          <div className="">
            <Button
              className="ButtonEtemp"
              style={{ backgroundColor: "white" }}
            >
              Cancel
            </Button>
            <Button
              className="ButtonEtempsave ml-4 "
              onClick={(e) => handleClick(e)}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default BrandingSave;
