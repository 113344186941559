import React, { useEffect } from "react";
import "./App.css";
import Routing from "../src/AuthRouter/PublicRoutes";
import { AuthAction } from "./ReduxStore/Actions/index";
import { useDispatch } from "react-redux";
import { APPLICATION_CONSTANTS, ROUTES } from "./Services";
import { useHistory } from "react-router-dom";
import firebase from "firebase";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (
        localStorage.getItem(APPLICATION_CONSTANTS.TOKEN) &&
        localStorage.getItem(APPLICATION_CONSTANTS.USER_DATA) &&
        localStorage.getItem(APPLICATION_CONSTANTS.ID_TOKEN)
      ) {
        firebase.auth().onAuthStateChanged(function (user) {
          console.log("user in AuthStateChanged :>> ", user);
          if (user) {
            user.getIdToken().then(function (idToken) {
              console.log(idToken);
              localStorage.setItem(APPLICATION_CONSTANTS.ID_TOKEN, idToken);
              dispatch(AuthAction.matchToken()).then((status) => {
                if (status === false) {
                  dispatch({ type: "LOGOUT_USER" });
                }
              });
            });
          } else dispatch({ type: "LOGOUT_USER" });
        });
      } else dispatch(AuthAction.logOut());
    })();
  }, []);

  return (
    <div className="App">
      <Routing />
    </div>
  );
}

export default App;
