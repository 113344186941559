import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./BrandingModal.css";
import { Divider } from "@material-ui/core";
import { HexColorPicker } from "react-colorful";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import BrandingSave from "./brandingSave";
import imageBlue from "../../../Images/commonImages/imageBlue.jpg";
import { BrandingAction } from "../../../ReduxStore/Actions";
import { useDispatch, useSelector } from "react-redux";
const BrandingModal = (props) => {
  const dispatch = useDispatch();
  const uploadedImage = React.useRef(null);
  const [color, setColor] = React.useState("#aabbcc");
  const [isDisplay, setDisplay] = React.useState("none");
  const [colorSecondary, setColorSecondary] = React.useState("#fdc400");
  const [isDisplaySecond, setDisplaySecond] = React.useState("none");
  const [colorFont, setColorFont] = React.useState("#000000");
  const [selectedFile, setSelectedFile] = useState();
  const [brandingName, setBrandingName] = useState();
  const [displayFont, setDisplayFont] = React.useState("none");
  const [text, setText] = useState({
    name: "The title",
    body: "This is Body",
  });
  const data = useSelector((state) => state.authReducer.data);
  const brandingId = useSelector(
    (state) => state.brandingReducer.databyid.data
  );

  const [family, setfamily] = useState("Courier");
  const [font, setFont] = React.useState({
    fontFamilyName: [],
    fontFamilyWeight: [],
  });
  const [boxes, setBoxes] = useState([
    {
      componentType: "background", //make component camelCase and value ==background
      xPos: 0,
      yPos: 0,
      width: 340,
      height: 225,
      color: "#aabbcc", //color
      text: null,
      fontFamily: null,
      fontSize: null,
      logoUrl: null,
    },
    {
      componentType: "divider", // dividerColor
      xPos: 250,
      yPos: 50,
      width: 300,
      height: 15,
      color: "#fdc400",
      text: null,
      fontFamily: null,
      fontSize: null,
      logoUrl: null,
    },
    {
      componentType: "body",
      xPos: 130,
      yPos: 130,
      width: 200,
      height: 50,
      color: "#000000", //color
      text: "The is Body",
      fontFamily: "poppins", //fontfamily
      fontSize: "24px",
      logoUrl: null,
    },
    {
      componentType: "title",
      xPos: 200,
      yPos: 60,
      width: 80,
      height: 50,
      color: "#000000", // color
      text: "The Title",
      fontFamily: "poppins",
      fontSize: "30px",

      logoUrl: null,
    },
    {
      componentType: "logo",
      xPos: 5,
      yPos: 7,
      width: 85,
      height: 85,
      color: null,
      text: null,
      fontFamily: null,
      fontSize: null,
      logoUrl: imageBlue,
    },
  ]);
  useEffect(() => {
    (async () => {
      let params = { userId: data, brandingId: props.category };

      await dispatch(BrandingAction.getBrandingById(params));
    })();
  }, [props.category]);
  const showPreview = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    setSelectedFile(e.target.files[0]);
  };
  useEffect(() => {
    console.log("selectedContentFile in useeffect :>> ", typeof selectedFile);
    if (!selectedFile) {
      return;
    }
    if (typeof selectedFile == "undefine") return;
    const objectUrl = URL.createObjectURL(selectedFile);
    const newIds = boxes.slice(); //copy the array
    newIds[4]["logoUrl"] = objectUrl; //execute the manipulations

    setBoxes(newIds);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleClick = () => {
    isDisplay == "none" ? setDisplay("flex") : setDisplay("none");
  };
  const handleClickSecond = () => {
    isDisplaySecond == "none"
      ? setDisplaySecond("flex")
      : setDisplaySecond("none");
  };
  const handleClickFont = () => {
    displayFont == "none" ? setDisplayFont("flex") : setDisplayFont("none");
  };
  const handleChangeText = (e) => {
    const newIds = [...boxes];
    const newId = e.target.name === "name" ? 3 : 2;
    newIds[newId]["text"] = e.target.value;
    setBoxes(newIds);
  };
  const handleColor = (e, name) => {
    const newIds = [...boxes];
    const x =
      name === "bgColor"
        ? ((newIds[0]["color"] = e), setColor(e))
        : name === "bgSecondaryColor"
        ? ((newIds[1]["color"] = e), setColorSecondary(e))
        : name === "fontColor"
        ? ((newIds[2]["color"] = e), (newIds[3]["color"] = e), setColorFont(e))
        : null;
    setBoxes(newIds);
  };
  const familyChange = (e, name) => {
    const newIds = [...boxes];
    console.log(name, "name", e.target.value);

    newIds[2]["fontFamily"] = e.target.value;
    newIds[3]["fontFamily"] = e.target.value;

    setBoxes(newIds);
  };
  const handleChangeName = (e) => {
    setBrandingName(e.target.value);
  };
  const handleHide = () => {
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="branding1"
          // id="contained-modal-title-vcenter"
        >
          {props.category === 0 ? "ADD" : "EDIT"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="content-modal-body col-md-12 text-center">
        <div className="branding-body row ">
          <div className="col-md-5 col-sm-5 d-flex flex-column mt-1 ml-3">
            <label>Branding name* </label>

            <input
              type="text"
              placeholder="Name here...."
              value={brandingName}
              name="name"
              onChange={(e) => handleChangeName(e)}
            />
            <label>Title* </label>

            <input
              type="text"
              placeholder="Title here...."
              value={boxes[3]["text"]}
              name="name"
              onChange={(e) => handleChangeText(e)}
            />
            <label>Branding Body* </label>

            <input
              type="text"
              placeholder="Body"
              name="body"
              value={boxes[2]["text"]}
              onChange={(e) => handleChangeText(e)}
            />
            <div className="s3">
              <label>Primary background color*</label>
              <div className="d-flex s4">
                <input
                  type="text"
                  className="mr-3"
                  placeholder="name"
                  placeholder="color"
                  value={color}
                  // value={}
                />
                <ColorLensIcon onClick={handleClick} />
              </div>

              <HexColorPicker
                style={{ display: isDisplay }}
                color={color}
                onChange={(e) => handleColor(e, "bgColor")}
              />
            </div>
            <div className="s3">
              <label>Secondary background color*</label>
              <div className="d-flex s4 ">
                <input
                  type="text"
                  className="mr-3"
                  placeholder="name"
                  value={colorSecondary}
                  // value={}
                />
                <ColorLensIcon onClick={handleClickSecond} />
              </div>
              <HexColorPicker
                style={{ display: isDisplaySecond }}
                color={colorSecondary}
                // onChange={setColorSecondary}
                onChange={(e) => handleColor(e, "bgSecondaryColor")}
              />
            </div>
            <div className="s3">
              <label>Text colour*</label>
              <div className="d-flex s4 ">
                <input
                  type="text"
                  className="mr-3"
                  placeholder="color"
                  value={colorFont}
                  // value={}
                />
                <ColorLensIcon onClick={handleClickFont} />
              </div>
              <HexColorPicker
                style={{ display: displayFont }}
                color={colorFont}
                onChange={(e) => handleColor(e, "fontColor")}
              />
            </div>

            <label>Font family*</label>
            <select
              name="Text font family"
              onChange={(e) => familyChange(e, "theText")}
            >
              <option value="emoji">Emoji</option>
              <option value="math">Math</option>
              <option value="monospace">Monospace</option>
              <option value="fangsong">fangsong</option>
              <option value="fantasy">Fantasy</option>
              <option value="poppins">Poppins</option>
              <option value="cursive">cursive</option>
              <option value="Courier">Courier</option>
              <option value="ui-serif">Ui-serif</option>
              <option value="sans-serif">sans-serif</option>
              <option value="Verdana">Verdana</option>
              <option value="Tahoma">Tahoma</option>
              <option value="revert">Revert</option>
            </select>
            <input
              className="content-text"
              id="files"
              type="file"
              onChange={showPreview}
            />
            <label className="logoLabel mr-5" for="files">
              upload logo
            </label>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className=" col-md-6 col-sm-5">
            <BrandingSave
              fontText={{ body: text.body, type: "body" }}
              // handleTextWidth={handleTextWidth}
              boxes={boxes}
              logo={selectedFile}
              bgColor={color}
              hide={handleHide}
              brandingName={brandingName}
              fontColor={colorFont}
              colorSecondary={colorSecondary}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default BrandingModal;
