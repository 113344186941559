import { Divider, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import Header from "../../component/headerBar/Header";
import imageBlue from "../../Images/commonImages/imageBlue.jpg";
import BrandingModal from "./component/BrandingModal";
import "./Branding.css";
import { useDispatch, useSelector } from "react-redux";
import bimg from "../../Images/commonImages/bimg.png";
import { BrandingAction } from "../../ReduxStore/Actions";
const Branding = () => {
  const dispatch = useDispatch();
  const [brandingModal, setBrandingModal] = React.useState(false);
  const userId = useSelector((state) => state.authReducer.data);
  const brandingData = useSelector((state) => state.brandingReducer.data.data);
  const [category, SetCategory] = useState(0);
  console.log(brandingData, "bdat");
  useEffect(() => {
    (async () => {
      await dispatch(BrandingAction.getBranding({ userId }));
    })();
  }, [userId]);
  useEffect(() => {
    (async () => {
      await dispatch(BrandingAction.getBranding({ userId }));
    })();
  }, [brandingModal]);

  const arraylength = brandingData ? Object.keys(brandingData) : null;
  const handleClick = (e, id) => {};
  const handleButton = (e) => {
    setBrandingModal(true);
  };

  return (
    <div className="Branding ">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-10 ">
          <Header
            title="Branding"
            placeholder="Search Branding"
            buttonName="New Branding"
            handleChange={(e) => handleButton(e)}
          />

          <Divider className="mt-3" />
          <BrandingModal
            className="brandModal"
            show={brandingModal}
            category={category}
            onHide={() => setBrandingModal(false)}
          />

          <div className="brandingBody">
            <div className="lop text-center">
              {arraylength?.length == 0 ? <img src={bimg} alt="ii" /> : null}
            </div>
            <Grid className="data ml-2" container item xs={12} spacing={3}>
              {arraylength?.map((card) => (
                <Card
                  onClick={(e) => {
                    handleClick(e, brandingData[card]);
                  }}
                >
                  <img
                    // variant="top"
                    
                    src={brandingData[card][0].url}
                    alt="blue color"
                  />
                  {console.log(
                    brandingData[card][0].url,
                    brandingData[card][0].name,
                    brandingData[card][0],

                    "url"
                  )}
                  <Card.Body className="branding-body">
                    <h5 className="text-center mt-0 branding-title">
                      {brandingData[card][0].name}
                    </h5>
                  </Card.Body>
                </Card>
              ))}
            </Grid>

            {/* {brandingData?.length === 0 ? (
<img src={imageBlue} src="blue" />
) : null} */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Branding;
