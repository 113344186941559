import { Divider } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Toast } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../component/header/Header";
import { AuthAction } from "../../ReduxStore/Actions/index";
import "./Login.css";
import Footer from "../../component/footer/Footer";
import { ValidateLoginForm } from "./Validation";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Services/Constant/index";
import CryptoJS from "react-native-crypto-js";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { APPLICATION_CONSTANTS } from "../../Services/index";
import { useHistory } from "react-router";
import Loader from "../../Services/Util/loader";
const Login = () => {
  const dispatch = useDispatch();
  // const topRef = useRef();
  const history = useHistory();
  const [state, setState] = React.useState();
  const [errorData, setError] = React.useState();

  const [checked, setChecked] = React.useState();
  // const [toast, setToast] = useState(false);

  // const [toastMessage, setToastMessage] = useState();
  // const [toastError, setToastError] = useState();

  const [isShowPassword, setPasswordType] = React.useState(false);
  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const errorStatus = useSelector((state) => state.commonReducer.status);
  const loading = useSelector((state) => state.commonReducer.loading);
  const [toast, setToast] = React.useState("false");
  // console.log(errorMessage, errorStatus, "llop");
  // errorStatus = errorStatus === null ? false : errorStatus;
  React.useEffect(() => {
    (async () => {
      let rememberme = await localStorage.getItem(
        APPLICATION_CONSTANTS.REMEMBER_ME
      );
      console.log("in mount login");
      // topRef.current.scrollTo(0, 0);
      if (rememberme) {
        setChecked(true);
        rememberme = CryptoJS.AES.decrypt(
          rememberme,
          APPLICATION_CONSTANTS.REMEMBER_ME_SECRETE_KEY
        );
        rememberme = rememberme.toString(CryptoJS.enc.Utf8);
        rememberme = JSON.parse(rememberme);
        setState(rememberme);
        // setUserData(rememberme);
      } else {
        setChecked(false);
      }
    })();
  }, []);
  useEffect(() => {
    setToast(false);
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  async function onSubmitForm(event) {
    console.log(event, "opp");
    event.preventDefault();
    const result = await ValidateLoginForm(state);

    if (result?.isFormValid) {
      setError({});

      if (checked) {
        const rememberme = CryptoJS.AES.encrypt(
          JSON.stringify(state),
          APPLICATION_CONSTANTS.REMEMBER_ME_SECRETE_KEY
        ).toString();
        localStorage.setItem(APPLICATION_CONSTANTS.REMEMBER_ME, rememberme);
      } else {
        localStorage.removeItem(APPLICATION_CONSTANTS.REMEMBER_ME);
      }
      const status = await dispatch(AuthAction.login(state));
      // setToastMessage(status.message);
      // setToastError(status.status);
      // setToast(true);
      if (status) {
        history.push("/screen");
      }
    } else if (result?.isFormValid === false) {
      setError(result?.error);
      return;
    }
    setToast(true);
  }
  return (
    <div className="login">
      <Header trans="white" color="black" boxShadow="0px 0px 6px 2px #d9cbcb" />
      {(errorStatus === false || errorStatus === true) && errorMessage ? (
        <Toast
          className="toastLogin mt-2"
          // show={errorStatus === false ? true : false}
          show={toast}
          onClose={() => setToast(false)}
          delay={6000}
          autohide
        >
          <Toast.Header className="errorMessageLogin">
            <strong className="mr-auto">{errorMessage}</strong>
          </Toast.Header>
        </Toast>
      ) : null}

      <div className="login-body">
        <div className="login-head">
          <h2 className="pl-5 pb-1 pt-3">LOG IN</h2>
        </div>

        <Divider variant="fullWidth" />
        <div className="login-body-inner px-5 mt-2">
          <div className="row">
            <div className="col-md-12">
              <Form.Label className="mb-0">Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={state?.email}
                placeholder="Enter email"
                onChange={(e) => handleChange(e)}
              />
              <p className="errorData">
                {" "}
                {errorData?.email && errorData.email[0]}
              </p>
            </div>
            <div className="col-md-12 mt-3 ">
              <Form.Label className="mb-0">Password</Form.Label>
              <div className="relative">
                <Form.Control
                  type={isShowPassword == true ? "text" : "password"}
                  name="password"
                  className="password"
                  placeholder="Enter password"
                  value={state?.password}
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => {
                    return e.key === "Enter" ? onSubmitForm(e) : null;
                  }}
                />
                {isShowPassword === true ? (
                  <a className="eyeicon" onClick={() => setPasswordType(false)}>
                    <VisibilityIcon />
                  </a>
                ) : (
                  <a className="eyeicon" onClick={() => setPasswordType(true)}>
                    <VisibilityOffIcon />
                  </a>
                )}
                <p className="errorData">
                  {" "}
                  {errorData?.password && errorData.password[0]}
                </p>
              </div>
            </div>
          </div>
          <div className="remember d-flex flex-row mt-3">
            {/* <input
              type="checkbox"
              checked={checked}
              onChange={(e) => setChecked(!checked)}
            />
            <label className="ml-2">Remember me</label> */}
            <h4 className="">Forgot Password?</h4>
          </div>
          <div className="text-center mt-4">
            <Button
              className="LoginButton"
              onClick={(e) => onSubmitForm(e)}
              type="submit"
            >
              Login{"  "} <Loader loading={loading}></Loader>
            </Button>
          </div>

          <div class="haveaccount text-center py-2">
            <p className="mb-0">
              Don't have an account?{" "}
              <Link to={ROUTES.Signup}>
                <a>Sign up</a>
              </Link>
            </p>
          </div>
          <div className="private-policy pb-3 text-center">
            <p>
              By logging in, you agree to our<br></br>
              {/* <Link to={ROUTES.PRIVATE_POLICY}> */}
              Privacy Policy {/* </Link> */}
              and {/* <Link to={ROUTES.TERMS_AND_CONDITION}> */}
              Terms Of Use
              {/* </Link>. */}
            </p>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
};
export default Login;
