import React from "react";
import { Modal, Button } from "react-bootstrap";
const ModalToast = (props) => {
  console.log("hitted");
  return (
    <Modal
      // className={{display: }}
      {...props}
      onHide={() => props.setModal(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="screen-modal-1"
          id="contained-modal-title-vcenter"
          style={{ width: "100%", textAlign: "center" }}
        >
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="screen-modal-body">
        <h4
          className="addscreenhead"
          style={{ lineHeight: 1.7, color: "#6b6550" }}
        >
          {props.message}
        </h4>
      </Modal.Body>

      <Modal.Footer className="text-center">
        <Button
          className="button31 addscreenbtn"
          onClick={(e) => props.handleClickModal(e)}
        >
          {props.buttonName}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalToast;
