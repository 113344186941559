import Api from "../../Api";

import {
  ACTION_TYPE,
  APPLICATION_CONSTANTS,
  ENDPOINTS,
} from "../../Services/index";
import { AuthAction } from "./index";

export function getStripeSession(body) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });

      const res = await Api.post(ENDPOINTS.stripe_recurring_session, body);

      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      //   dispatch(handleError(error));
    }
  };
}
export function getStripePlan() {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });

      const res = await Api.post(ENDPOINTS.stripe_recurring_plan);
      dispatch({
        type: ACTION_TYPE.STRIPE_PLAN,
        payload: { data: res.data },
      });

      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      //   dispatch(handleError(error));
    }
  };
}

export const getUpcomingInvoice = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });

    Api.post(ENDPOINTS.STRIPE_RETRIEVE_UPCOMING_INVOICE, params)
      .then((res) => {
        dispatch({ type: "LOADING_COMPLETED" });
        resolve(res.data);
      })
      .catch((error) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(error);
      });
  });
};

export const updateSubscription = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });

    Api.post(ENDPOINTS.STRIPE_UPDATE_SUBSCRIPTION, params)
      .then((res) => {
        console.log("res after update :>> ", res);
        dispatch(AuthAction.matchToken());
        dispatch({ type: "LOADING_COMPLETED" });
        resolve(res.data);
      })
      .catch((error) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(error);
      });
  });
};

export function getStripeCustomerPortal(body) {
  return async function (dispatch) {
    try {
      dispatch({ type: "LOADING_STARTED" });

      const res = await Api.post(
        ENDPOINTS.stripe_recurring_customer_portal,
        body
      );

      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch({ type: "LOADING_FAILURE" });
      //   dispatch(handleError(error));
    }
  };
}
