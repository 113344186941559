import { ACTION_TYPE, APPLICATION_CONSTANTS } from "../../Services/index";
export const initialState = {
  data: [],
  dataById: [],
  dataUser: [],
  subNavValue: "",
  boxesValue: [],
  userDataById: [],
};

export const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.TEMPLATE_GET_ADMIN:
      return {
        ...state,
        data: action.payload,
      };
    case ACTION_TYPE.TEMPLATE_GET_USER:
      return {
        ...state,
        dataUser: action.payload,
      };
    case ACTION_TYPE.TEMPLATE_GET_ADMIN_BY_ID:
      return {
        ...state,
        dataById: action.payload.data.data,
      };
    case ACTION_TYPE.TEMPLATE_GET_USER_BY_ID:
      return {
        ...state,
        userDataById: action.payload,
      };
    case "SUB_NAV_VALUE":
      return {
        ...state,
        subNavValue: action.payload,
      };
    case ACTION_TYPE.BOXESVALUE:
      // console.log("in temp", action.payload);
      return {
        ...state,
        boxesValue: action.payload,
      };
    default:
      return state;
  }
};
