import React, { useEffect, useState } from "react";
import "./index.css";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import { loadStripe } from "@stripe/stripe-js";
import {
	BookAction,
	StripeAction,
	SubscriptionAction,
} from "../../ReduxStore/Actions/index";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ROUTES } from "../../Services";
import axios from "axios";
import { Toast } from "react-bootstrap";

import PriceCard from "../../component/priceCard/priceCard";
// import ModalToast from "../../component/modal/modalToast";
import ModalToast from "../../component/toastModal/modalToast";

// import Toast from "../../component/toast/toast";
const stripePromise = loadStripe(
	"pk_test_51IWLgGFYqkzSNbUwkKneusnaEwtsJtno2POHQX9zOtYZAo1vE7yeW9T5OhzQmFg284BZNiCpo2mkxYKBlK6sn3On00VBkZAt2x"
);
const Pricing = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const email = useSelector((state) => state.authReducer.authData);
	const authData = useSelector((state) => state.authReducer.authData);
	const data = useSelector((state) => state.authReducer.data);
	// const {  authData } = useSelector(
	//   (state) => state.authReducer.authData
	// );
	// console.log(payment, "paymaent");
	const [toast, setToast] = useState(false);
	const [plan, setPlan] = useState();
	const [toastMessage, setToastMessage] = useState("");
	const [modal, setModal] = useState(false);
	useEffect(() => {
		(async () => {
			const res = await dispatch(SubscriptionAction.getStripePlan());
			setPlan(res.data);
		})();
	}, []);
	const handleClick = async (e, amount, PriceId, plan) => {
		e.preventDefault();
		e.stopPropagation();
		console.log("save hitted", authData["payment"], PriceId, plan);

		if (!authData["id"]) {
			// history.push(ROUTES.Login);
			setToastMessage("Please Login First!!");
			setToast(true);
			console.log("save hitted", "login now");
		} else {
			// if (payment == undefined) {
			//   history.push(ROUTES.Login);
			// }
			if (plan === "Basic") {
				return;
			}
			// console.log(payment[0]["plan"], plan, "plan");
			else if (
				authData &&
				authData["payment"] === plan
				//  ||
				// (payment[0]["plan"] === "Premium " && plan === "Premium ")
			) {
				console.log("same plan");
				setToast(true);
				setToastMessage("You have already subscribed to this plan");
			} else if (
				authData &&
				authData["payment"].trim() === "Standard" &&
				plan.trim() === "Premium"
			) {
				console.log("standard to premium");

				let upcomingInvoiceParams = {
					newPriceId: PriceId,
					customerId: authData["customer_id"],
					subscriptionId: authData["subscription_id"],
				};
				dispatch(
					SubscriptionAction.getUpcomingInvoice(upcomingInvoiceParams)
				).then(async (resp) => {
					console.log("resp upcoming :>> ", resp);
					// setModal(true);
					// let params = {
					//   userId: data,
					//   formDate: "",
					//   toDate: "",
					//   totalAmount: resp.invoice["amount_due"] / 100,
					//   additionalInformation: PriceId,
					// };
					// let billingId1 = await dispatch(
					//   BookAction.addBillingInformation(params)
					// );
					// console.log(billingId1, "bill");
					// if (billingId1 === true) {
					// history.push(ROUTES.Billing);
					history.push({
						pathname: ROUTES.Billing,
						// search: '?query=abc',
						state: {
							PriceId: PriceId,
							email: email?.email,
							plan: plan,
							amount: resp.invoice["amount_due"] / 100,
							purpose: "upgrade",
							subscription: resp.invoice["subscription"],
							subscription_proration_date:
								resp.invoice["subscription_proration_date"],
						},
					});
					// }
				});
			} else if (
				authData &&
				authData["payment"].trim() === "Premium" &&
				plan.trim() === "Standard"
			) {
				console.log("premium to standard ");
				setToast(true);
				setToastMessage("You are already subscribed to Premium plan");
			} else {
				let params = {
					userId: data,
					formDate: "",
					toDate: "",
					totalAmount: amount,
					additionalInformation: PriceId,
				};
				let billingId1 = await dispatch(
					BookAction.addBillingInformation(params)
				);
				if (billingId1 === true) {
					history.push({
						pathname: ROUTES.Billing,
						state: {
							PriceId: PriceId,
							email: email?.email,
							plan: plan,
							amount,
							purpose: "new",
						},
					});
				}
			}
		}
	};
	const handleClickModal = async (e) => {
		setModal(false);
		const res = await dispatch(
			SubscriptionAction.getStripeCustomerPortal({
				customer_id: authData && authData["customer_id"],
			})
		);

		window.location.assign(res.data);
	};
	return (
		<div className="pricing">
			<div>
				<Header
					trans="white"
					color="black"
					boxShadow="2px 0px 6px 2px #d9cbcb"
				/>
				<ModalToast
					show={modal}
					title="Upgrade Subscription"
					setModal={setModal}
					message="Your will be upgraded to premium plan"
					buttonName="continue"
					handleClickModal={handleClickModal}
				/>
			</div>
			<Toast
				className="ToastComponent"
				show={toast}
				onClose={() => setToast(false)}
				delay={5000}
				autohide
			>
				<Toast.Header className="ToastBodyComponent">
					<strong className="mr-auto">{toastMessage}</strong>
				</Toast.Header>
			</Toast>
			<div className="bodyPricing">
				<h2>CHOOSE YOUR PLAN</h2>
				<h4>
					By clicking on the plan you will be taken to the payment gateway
				</h4>
				{/* {toastMessage} */}
			</div>
			<div className="pricingcard">
				{plan &&
					plan.map((item) => {
						// if (Object.keys(item.metadata).length != 0) {
						return (
							console.log("hitted", item, item.id),
							(
								<PriceCard
									title={item.name}
									amount={
										item.name === "Basic"
											? "Free"
											: "$" + (item.amount / 100).toFixed(2)
									}
									priceId={item.id}
									handleClick={handleClick}
								/>
							)
						);
						// }
					})}
			</div>
			<div className="pFooter">
				<Footer />
			</div>
		</div>
	);
};
export default Pricing;
