import Api from "../../Api";
import { handleError } from "./commonAction";

import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";
//post content
export const createPlaylist = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.PLAYLISTCREATE, params)
      .then((res) => {
        dispatch({
          type: ACTION_TYPE.PLAYLIST_CREATE,
          payload: res.data.data,
        });
        resolve(res?.data);
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
export const getPlaylist = (id) => async (dispatch) => {
  try {
    // dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.PLAYLISTGET, id);
    dispatch({
      type: ACTION_TYPE.PLAYLIST_GET,
      payload: { data: res.data },
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError(error));
  }
};
export const getPlaylistById = (id) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.GETPLAYLISTBYID, id);
    dispatch({
      type: ACTION_TYPE.PLAYLIST_GET_ID,
      payload: { data: res.data },
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError(error));
  }
};
export const deleteFromPlaylist = (params) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.DELETEFROMPLAYLIST, params);
    dispatch({
      type: ACTION_TYPE.PLAYLIST_REMOVE,
      payload: { data: res.data },
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError(error));
  }
};
export const addToPlaylist = (params) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.ADDTOPLAYLIST, params);

    dispatch({
      type: ACTION_TYPE.PLAYLIST_ADD,
      payload: { data: res.data },
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError(error));
  }
};
export const deletePlaylist = (params) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.DELETEPLAYLIST, params);
    console.log(res, "re:>>");

    dispatch({
      type: ACTION_TYPE.PLAYLIST_DELETE,
      payload: { data: res.data },
    });
    if (res?.data) {
      return res?.data;
    }
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    dispatch(handleError(error));
  }
};
// export const deletePlaylist = (params) => async (dispatch) => {
//   return new Promise((resolve, reject) => {
//     dispatch({ type: "LOADING_STARTED" });
//     Api.post(ENDPOINTS.DELETEPLAYLIST, params)
//       .then((res) => {
//         console.log(res, "resin action");
//         dispatch({
//           type: ACTION_TYPE.PLAYLIST_DELETE,
//           payload: res.data,
//         });
//         resolve(res?.data.status);
//         dispatch({ type: "LOADING_COMPLETED" });
//       })
//       .catch((err) => {
//         dispatch({ type: "LOADING_FAILURE" });
//         dispatch(handleError(err));
//         console.log("err>>", err);
//         // reject("false");
//       });
//   });
// };
