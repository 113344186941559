import React, { useEffect } from "react";
import Header from "../../component/headerBar/Header";
import "./Playlist.css";
import Playlist from "../../Images/commonImages/Playlist.png";
import play from "../../Images/commonImages/play.jpg";
import { Card, Divider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PlaylistAction } from "../../ReduxStore/Actions";
import { Button } from "react-bootstrap";
const Playlists = () => {
	const history = useHistory();
	const data = useSelector((state) => state.authReducer.data);
	const playlist = useSelector((state) => state.playlistReducer?.data?.data);
	const [playlistData, setPlaylistData] = React.useState();
	const dispatch = useDispatch();

	useEffect(() => {
		console.log("data in playlist :>> ", data);
		if (data != "") dispatch(PlaylistAction.getPlaylist({ userId: data }));
	}, [data]);

	useEffect(() => {
		setPlaylistData(playlist);
	}, [playlist]);

	const handleRoute = async (e, id) => {
		e.preventDefault();
		e.stopPropagation();
		history.push(`/playlist/edit/${id}`);
	};

	const handleNewPlaylist = async () => {
		history.push("/playlist/new");
	};
	// to delete the playlist button
	const handleDeletePlaylist = async (e, id) => {
		let params = {
			userId: data,
			playlistId: id,
		};
		dispatch(PlaylistAction.deletePlaylist(params));
		dispatch(PlaylistAction.getPlaylist({ userId: data }));
	};

	const handleChangeList = (data) => {
		setPlaylistData(data);
	};
	const clear = () => {
		setPlaylistData(playlist);
	};
	return (
		<div className="playlist">
			<div className="row">
				<div className="col-md-2 col-sm-2 col-lg-2"></div>
				<div className="col-md-12 col-sm-10 col-lg-10">
					<div className="playlistHeader mt-3 mb-3">
						<Header
							title="Playlists"
							buttonName="New Playlist"
							placeholder=" Search Playlist"
							handleChange={handleNewPlaylist}
							dataList={playlistData}
							handleChangeList={handleChangeList}
							clear={clear}
						/>
					</div>
					<Divider variant="fullWidth" />

					{playlistData
						? playlistData.map((user, i) => (
								<div className="playist-Screen-body mt-2 mr-3 position-relative">
									<Card className="d-flex flex-row row  playlistList ml-2">
										<div
											className=" col-md-4 d-flex divvv"
											style={{ paddingLeft: "0px" }}
										>
											{console.log(user, "user")}
											<img src={play} alt="logo" />
											<div className="flex-column flex-center-vertical ml-4">
												<div className="plistflex playlistchange d-flex flex-row mt-2">
													{user.mediaName}
												</div>

												<p className="kkk"> Total items: {user.totalItems}</p>
											</div>
										</div>

										<div className=" col-md-3 flex-center-vertical text-right divvv">
											<div className="d-flex flex-row ">
												<p className="mr-2">Published</p>
												<Button
													className="editPlaylst"
													onClick={(e) => handleRoute(e, user.playlistId)}
												>
													Edit
												</Button>
												<div className="settingIcon">
													{/* <SettingsIcon /> */}

													<div
														className="popnew"
														anchorOrigin={{
															vertical: "bottom",
															horizontal: "center",
														}}
														transformOrigin={{
															vertical: "top",
															horizontal: "center",
														}}
													>
														<ul className="popupsetPlaylist px-3 py-2">
															{/* {console.log(user, "/", user.playlist_id)} */}

															<li
																onClick={(e) => handleRoute(e, user.playlistId)}
															>
																Edit
															</li>

															<li>Set to screen</li>
															<li>Duplicate</li>
														</ul>
														<ul
															style={{
																padding: "0px",
																paddingBottom: "3px",
																marginBottom: "0px",
															}}
														>
															<li
																style={{
																	color: "red",
																	textAlign: "center",
																	listStyle: "none",
																}}
																onClick={(e) =>
																	handleDeletePlaylist(e, user.playlistId)
																}
															>
																Delete
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</Card>
								</div>
						  ))
						: null}
					{playlistData && playlistData.length == 0 ? (
						<div className="playlistBody col-md-12 text-center mt-5 mb-3">
							<h2>Organize your content.</h2>
							<h5>Maintain your content with your playlist funtionality.</h5>
							<img
								className="playlistImg mt-5"
								src={Playlist}
								alt="content img"
							/>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};
export default Playlists;
