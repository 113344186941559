import React, { useEffect } from "react";
import { Form, Button, Toast } from "react-bootstrap";
import Footer from "../../component/footer/Footer";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Header from "../../component/header/Header";
import "./Signup.css";
import { ValidateSignUpForm } from "./Validation";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Services/Constant/index";
import { AuthAction } from "../../ReduxStore/Actions/index";
import { Divider } from "@material-ui/core";
import { useHistory } from "react-router";
import Loader from "../../Services/Util/loader";
const Signup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = React.useState();
  const [selectedFile, setSelectedFile] = React.useState();
  const [errorData, setError] = React.useState();
  const [isShowConfirmPassword, setConfirmPasswordType] = React.useState(false);
  const [isShowPassword, setPasswordType] = React.useState(false);
  const [toast, setToast] = React.useState(false);
  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);
  const errr = useSelector((state) => state.commonReducer.status);
  const authData = useSelector((state) => state.authReducer.signupData);
  const loading = useSelector((state) => state.commonReducer.loading);
  console.log(authData, "authdat");
  let errorStatus = errr;
  // const insertId = useSelector((state) => state.authReducer);
  // const loading = useSelector((state) => state.commonReducer.loading);

  useEffect(() => {
    setToast(false);
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    // console.log({ name, value });
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const onSubmitForm = async (event) => {
    event.preventDefault();
    console.log("in submit form");
    const result = await ValidateSignUpForm(state);
    console.log(result, "result");
    if (!result?.isFormValid) {
      setError(result?.error);
      return;
    }
    setError(result?.error);
    let formData = new FormData();
    for (let key in state) {
      formData.append(key, state[key]);
    }
    // console.log("formData", formData, state);
    let params = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      phNo: state.phNo,
      password: state.password,
      confirmPassword: state.confirmPassword,
    };
    const status = await dispatch(AuthAction.signUp(params));
    console.log(status, "status");
    if (status) {
      history.push(ROUTES.Login);
    }
    setToast(true);

    if (errr === false) {
      setToast(true);
    }
  };
  console.log(errr, errorMessage, toast, "oppp", errorData);

  return (
    <div className="signup">
      <Header trans="white" color="black" boxShadow="2px 0px 6px 2px #d9cbcb" />
      <div className="signupcllass">
        {(errorStatus === false || errorStatus === true) && errorMessage ? (
          <div className="">
            <Toast
              // onClose={() => (errorStatus = true)}
              className="toastSignup  "
              onClose={() => setToast(false)}
              show={toast}
              // show={errorStatus === false ? true : false}
              delay={6000}
              autohide={true}
            >
              <Toast.Header className="errorMessageSignup ">
                <strong className="mr-auto">{errorMessage}</strong>
              </Toast.Header>
            </Toast>
          </div>
        ) : null}

        <div className="row signupstart">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="signup-body mb-5">
              <h2 className="sigupTitle px-4">SIGN UP</h2>
              <Divider variant="fullWidth" />
              <div className="signup-form  px-4">
                <form>
                  <div className="row">
                    <div
                      className="col-md-6 mt-2 errsignup"
                      controlId="formGridEmail"
                    >
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        placeholder="Enter First Name"
                        onChange={(e) => handleChange(e)}
                      />
                      {errorData?.firstName[0] ? (
                        <p>{errorData?.firstName[0]}</p>
                      ) : null}
                    </div>

                    <div
                      className="col-md-6 mt-2 errsignup"
                      controlId="formGridPassword"
                    >
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="type"
                        name="lastName"
                        placeholder="Enter Last Name"
                        onChange={(e) => handleChange(e)}
                      />
                      {errorData?.lastName[0] ? (
                        <p>{errorData?.lastName[0]}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row mt-4 errsignup">
                    <div className="col-md-6" controlId="formGridEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        onChange={(e) => handleChange(e)}
                      />
                      {errorData?.email[0] ? (
                        <p>{errorData?.email[0]}</p>
                      ) : null}
                    </div>

                    <div
                      className="col-md-6 errsignup"
                      controlId="formGridPassword"
                    >
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        name="phNo"
                        placeholder="Phone No"
                        onChange={(e) => handleChange(e)}
                      />
                      {errorData?.phone[0] ? (
                        <p>{errorData?.phone[0]}</p>
                      ) : null}
                    </div>
                  </div>{" "}
                  <div className="row mt-4">
                    <div
                      className="col-md-6 signuppassword"
                      controlId="formGridPassword"
                    >
                      <Form.Label>Password</Form.Label>

                      <div className="d-felx flex-column errsignup">
                        <div className="relative">
                          <Form.Control
                            type={isShowPassword == true ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            onChange={(e) => handleChange(e)}
                          />{" "}
                          {isShowPassword === true ? (
                            <a onClick={() => setPasswordType(false)}>
                              <VisibilityIcon />
                            </a>
                          ) : (
                            <a onClick={() => setPasswordType(true)}>
                              <VisibilityOffIcon />
                            </a>
                          )}
                          {errorData?.password[0] ? (
                            <p style={{ bottom: "-36px" }}>
                              {errorData?.password[0]}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-md-6 signupConfirmpassword errsignup"
                      controlId="formGridPassword"
                    >
                      <label>Confirm Password</label>
                      <div className="relative">
                        <Form.Control
                          type={
                            isShowConfirmPassword == true ? "text" : "password"
                          }
                          name="confirmPassword"
                          placeholder=" Confirm Password"
                          onChange={(e) => handleChange(e)}
                        />
                        {isShowConfirmPassword === true ? (
                          <a onClick={() => setConfirmPasswordType(false)}>
                            <VisibilityIcon />
                          </a>
                        ) : (
                          <a onClick={() => setConfirmPasswordType(true)}>
                            <VisibilityOffIcon />
                          </a>
                        )}
                        {errorData?.confirmPassword[0] ? (
                          <p style={{ bottom: "-36px" }}>
                            {errorData?.confirmPassword[0]}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-5">
                    <Button
                      variant="primary"
                      className="signupButton"
                      type="submit"
                      onClick={onSubmitForm}
                    >
                      Signup <Loader loading={loading} />
                    </Button>
                  </div>
                  <div class="haveaccount text-center py-2">
                    <p className="mb-0">
                      Already have an account?{" "}
                      <Link to={ROUTES.Login}>
                        <a>Log In</a>
                      </Link>
                    </p>
                  </div>
                  <div className="private-policy mt-2 text-center">
                    <p>
                      By logging in, you agree to our<br></br>
                      Privacy Policy {/* </Link>  */}
                      and {/* <Link to={ROUTES.TERMS_AND_CONDITION}> */}
                      Terms Of Use
                      {/* </Link>. */}
                    </p>
                  </div>
                </form>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Signup;
