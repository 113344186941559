import React, { useEffect, useState } from "react";
import "./index.css";
import screenimg from "../../Images/commonImages/screenimg.png";
import Modal from "../modal/modal";
import Header from "../../component/headerBar/Header";
import { useSelector, useDispatch } from "react-redux";
import { ScreenAction } from "../../ReduxStore/Actions";
import { Button, Toast } from "react-bootstrap";
import { Divider } from "@material-ui/core";
import Img from "../../Images/commonImages/content.png";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Delete";
import ShareIcon from "@material-ui/icons/EditTwoTone";
import ListIcon from "@material-ui/icons/List";
import AppsIcon from "@material-ui/icons/Apps";
import Loader from "../../Services/Util/loader";
import AlertDialog from "../../component/AlertDialog/alertDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const Screens = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  let screens;

  const [modalShow, setModalShow] = useState(false);
  const [toggle, setToggle] = useState("grid");
  const [code, setCode] = useState("");
  const [screenList, setScreenList] = useState();
  const [delOpen, setDelOpen] = useState({
    state: false,
    user: "none",
  });
  const [errorMessage, setErrorMessage] = useState();
  const [toast, setToast] = useState("false");
  const [screenToggle, setScreenToggle] = useState(false);
  const loading = useSelector((state) => state.commonReducer.loading);
  const { id } = useSelector((state) => state.authReducer.authData);
  const auth = useSelector((state) => state.authReducer.authData);

  const [selectedScreen, setSelectedScreen] = useState();
  useSelector((state) => (screens = state.screenReducer.codeScreens));
  // const screensConsole = useSelector((state) => {
  //   // setScreenList( state.screenReducer.codeScreens)
  // });
  const screenChange = useSelector((state) => state.screenReducer.screenChange);

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  useEffect(() => {
    // console.log("screens in useEffect :>> ", screens);
    setScreenList(screens);
  }, [screens]);

  useEffect(() => {
    console.log("screens in useEffect - screenChange :>> ", screens);
  }, [screenChange]);

  useEffect(() => {
    if (typeof id !== "undefined") {
      let params = {
        userId: id,
      };
      dispatch(ScreenAction.getScreen(params));
    }
  }, [id]);

  const handleChangeList = (list) => {
    setScreenList(list);
  };

  const clear = () => {
    setScreenList(screens);
  };

  const handleChangeToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggle == "grid" ? setToggle("list") : setToggle("grid");
  };
  const handleDelete = (e, screen) => {
    e.preventDefault();
    e.stopPropagation();
    setDelOpen({
      state: false,
      user: "none",
    });
    setSelectedScreen(screen.id);
    let deleteParams = {
      screenDetails: screen,
    };

    dispatch(ScreenAction.deleteScreen(deleteParams)).then(() => {
      let getParams = {
        userId: id,
      };
      dispatch(ScreenAction.getScreen(getParams));
    });
  };
  return (
    <div className="screen ">
      {toast === true && errorMessage ? (
        <Toast
          className="toastPair mt-2"
          // show={errorStatus === false ? true : false}
          show={toast}
          onClose={() => {
            setToast(false);
            setErrorMessage();
          }}
          delay={6000}
          autohide
        >
          <Toast.Body className="errorMessageToast">{errorMessage}</Toast.Body>
        </Toast>
      ) : null}
      <div className="row">
        <div className="hidden-md col-lg-2 "> {/* <SideNav /> */}</div>
        <div className="col-md-12 col-lg-10">
          <div className="screenstart cardscreen mt-4">
            <Header
              title="Screens"
              dataList={screens}
              handleChangeList={handleChangeList}
              buttonName="New Screen"
              placeholder=" Search Screen"
              handleChange={() => {
                if (
                  (auth["role"] !== 2 &&
                    auth["payment"].trim() === "Basic" &&
                    screens?.length >= 1) ||
                  (auth["role"] !== 2 &&
                    auth["payment"].trim() === "Standard" &&
                    screens?.length >= 5)
                ) {
                  setErrorMessage(
                    "Please upgrade your plan to create more screens"
                  );
                  setToast(true);
                } else setModalShow(true);
              }}
              clear={clear}
              // handleError={(message) => {
              //   setErrorMessage(message);
              //   setToast(true);
              // }}
            />
            <Modal
              className="screen-modal1"
              show={modalShow}
              onHide={() => {
                setCode("");
                setModalShow(false);
              }}
              code={code}
              handleCodeChange={handleCodeChange}
              handleError={(message) => {
                setErrorMessage(message);
                setToast(true);
              }}
            />
            <AlertDialog
              open={delOpen}
              handleClose={() =>
                setDelOpen({
                  state: false,
                  user: "none",
                })
              }
              message="Are you sure you want to Delete?"
              button1="Yes"
              button2="No"
              handleDelete={handleDelete}
            />
            <Divider className="mt-3 " variant="fullWidth" />
            <div className="text-right mt-1 mr-5">
              <Button
                className="toggleButton"
                onClick={(e) => handleChangeToggle(e)}
              >
                {toggle === "list" ? <ListIcon /> : <AppsIcon />}
              </Button>
            </div>

            <div
              className={
                toggle == "grid" ? "cardParent d-flex" : "d-flex flex-column"
              }
            >
              {screenList?.length > 0 ? (
                toggle == "grid" ? (
                  screenList.map((screen, i) => {
                    return (
                      <Card className={classes.root} key={i}>
                        <CardHeader
                          action={
                            <IconButton
                              aria-label="settings"
                              // style={{ display: "none" }}
                            >
                              {loading && screen.id === selectedScreen ? (
                                <Loader loading={loading} />
                              ) : (
                                <FavoriteIcon
                                  style={{ color: "red" }}
                                  onClick={() => {
                                    setDelOpen({
                                      state: true,
                                      user: screen,
                                    });
                                  }}
                                />
                              )}
                            </IconButton>
                          }
                          title={screen.name}
                          // subheader={screen.created_ts.split("T")[0]}
                          subheader={screen.deviceId}
                        />
                        <CardMedia
                          className={classes.media}
                          image={Img}
                          title={screen.created_ts}
                        />

                        <CardActions className="justify-content-between screenStatus">
                          <IconButton
                            aria-label="share"
                            onClick={() => {
                              props.history.push(`/screen/edit/${screen.id}`);
                            }}
                          >
                            <ShareIcon />
                          </IconButton>

                          <span
                            className={
                              screen.active == true
                                ? screen.screenStatus
                                : "disabled"
                            }
                          >
                            {screen.active == true
                              ? screen.screenStatus === "active"
                                ? "Live"
                                : "Offline"
                              : "disabled"}
                          </span>
                        </CardActions>
                      </Card>
                    );
                  })
                ) : (
                  screenList.map((screen, i) => {
                    return (
                      <div
                        className="row mb-3 ml-3 mr-4 mt-4  screenCard"
                        key={i}
                      >
                        <div className="col-md-2 img ">
                          <img src={Img} />
                          <div></div>
                        </div>
                        <div className="col-md-2 mt-5 ml-2">
                          <label>NAME : </label> {screen.name}{" "}
                        </div>
                        <div className="col-md-4 mt-5 ml-3">
                          <label>DeviceId : </label> {screen.deviceId}
                        </div>
                        <div className="col-md-3 text-right mt-4 pt-2 screenStatus">
                          <span
                            className={
                              screen.active == true
                                ? screen.screenStatus
                                : "disabled"
                            }
                          >
                            {screen.active == true
                              ? screen.screenStatus === "active"
                                ? "Live"
                                : "Offline"
                              : "disabled"}
                          </span>{" "}
                          {/* {screen.active == true && ( */}
                          <IconButton
                            aria-label="share"
                            onClick={() => {
                              props.history.push(`/screen/edit/${screen.id}`);
                            }}
                          >
                            <ShareIcon />
                          </IconButton>
                          <IconButton>
                            {loading && screen.id === selectedScreen ? (
                              <Loader loading={loading} />
                            ) : (
                              <FavoriteIcon
                                style={{ color: "red" }}
                                onClick={() => {
                                  setSelectedScreen(screen.id);
                                  let deleteParams = {
                                    screenDetails: screen,
                                  };

                                  dispatch(
                                    ScreenAction.deleteScreen(deleteParams)
                                  ).then(() => {
                                    let getParams = {
                                      userId: id,
                                    };
                                    dispatch(ScreenAction.getScreen(getParams));
                                  });
                                }}
                              />
                            )}
                          </IconButton>
                          {/* )} */}
                        </div>
                        <Divider />
                      </div>
                    );
                  })
                )
              ) : (
                <div className="screen-content col-md-12 text-center mt-5 ">
                  <h2> How to add a screen? </h2>
                  <h5>Set up your digital signage in just 3 simple steps.</h5>
                  <div className="col-md-12 text-center mt-5">
                    <img className="screen-img1" src={screenimg} alt="" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Screens;
