import Api from "../../Api";
import {
  ENDPOINTS,
  ACTION_TYPE,
  APPLICATION_CONSTANTS,
} from "../../Services/index";
export const addBillingInformation = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });

    const res = await Api.post(ENDPOINTS.ADD_BILLING_INFO, body);
    console.log(res, "res in booking Action");
    dispatch({
      type: ACTION_TYPE.ADD_BILLING_INFO,
      payload: { data: res.data },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    if (res.data && res.data.status) {
      //   toast.success(res.data.message);
      return res.data.status;
    }
    // console.log("BILLING RESPONSE",res);
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    //   dispatch(handleError(error));
  }
};
export const orderDetails = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await Api.post(ENDPOINTS.GET_ORDER_DETAILS, body);
    // console.log("orderDetails",orderDetails)
    dispatch({
      type: ACTION_TYPE.ORDER_DETAILS,
      payload: { data: res.data.data },
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError(error));
  }
};
