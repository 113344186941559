import { Button } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ImageBlue from "../../../Images/commonImages/imageBlue.jpg";
import { TemplateAction } from "../../../ReduxStore/Actions";
import { ROUTES } from "../../../Services";
import "./editTemplate.css";

const PremEditTemplate = (props) => {
  const {
    imgBg,
    boxes,
    handleBoxChange,
    handleTextWidth,
    handleContentWidth,
    fontText,
    contentText,
    // textStyle,
    // fontSize,
    // family,
    // name,
    // bold,
    // color,
    // align,
    handleLogoDbl,
    handleContentLogoDbl,
    templateType,
    contentType,
    userId,
    state,
    templateName,
    handleChangeName,
    handleNoTemplateName,
    error,
    handleExit,
  } = props;
  const dispatch = useDispatch();
  const [dimension, setDimension] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [contentImage, setContentImage] = useState();
  const canvas = useRef(null);
  const bgCanvas = useRef(null);
  const finalCanvas = useRef(null);
  const image = useRef();
  const logoImg = useRef();
  const paddingCanvas = 0;
  const defaultText = "Enter Text";

  // let ctx = null;
  // console.log("outside return", ctx);
  // let bgCtx = null;

  // const [boxes, setBoxes] = useState([]);

  // const boxes = [
  // { x: 200, y: 220, w: 100, h: 50, text: },
  // { x: 100, y: 120, w: 100, h: 50 },
  // ];
  let isDown = false;
  let dragTarget = null;
  let startX = null;
  let startY = null;
  // const logo = new Image();
  // logo.src = "https://mdn.mozillademos.org/files/5395/backdrop.png";

  useEffect(() => {
    // const canvas = this.refs.canvas

    // const { text } = fontStyles;
    console.log("in Init useeffect", fontText);
    const finalCtx = finalCanvas.current?.getContext("2d");
    const bgCtxCurrent = bgCanvas.current;
    const bgCtx = bgCtxCurrent.getContext("2d");
    const ctxCurrent = canvas.current;
    const ctx = ctxCurrent.getContext("2d");
    const img = image.current;
    // const logo = logoImg.current;

    // img.crossOrigin = "Anonymous";
    console.log(img, "img");

    img.onload = ({ target: img }) => {
      console.log("image loaded :>> ");
      // setDimension({ height: img.height, width: img.width });
      ctx.beginPath();
      bgCtx.beginPath();
      ctx.drawImage(img, 0, 0);
      // ctx.drawImage(logo, 0, 0, 20, 20);
      bgCtx.drawImage(img, 0, 0);

      const logo = new Image();
      logo.src = boxes[1].url;
      logo.crossOrigin = "Anonymous";
      logo.onload = () => {
        setLogoUrl(logo);
        draw(ctx);
      };

      // const contentLogo = new Image();
      // contentLogo.src = boxes[2].url;
      // contentLogo.onload = () => {
      // setContentImage(contentLogo);
      // draw(ctx);
      // };

      // let width = ctx.measureText(fontText).width;
      // setBoxes([...boxes, { x: 200, y: 220, w: width, h: 50, text: fontText }]);
      console.log("templateType :>> ", templateType);
      boxes.map((info) => drawFillRect(ctx, info));
      // [...boxes, { x: 200, y: 220, w: width, h: 50 }].map((info) =>
      // drawFillRect(ctx, info)
      // );

      // ctx.canvas.height = window.innerHeight;
      // ctx.canvas.width = window.innerWidth;
    };
    // const handleResize = (e) => {
    //   setDimension({
    //     height: window.innerHeight,
    //     width: window.innerWidth,
    //   });
    // };
    // handleResize();
    // window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    console.log("boxes :>> ", boxes);
    const logo = new Image();
    logo.src = boxes[1].url;
    logo.crossOrigin = "Anonymous";
    logo.onload = () => {
      // handleBoxChange(logo, 1);
      setLogoUrl(logo);

      // draw(canvas.current.getContext("2d"));
    };
    // if (boxes[2]) {
    if (boxes[2].url) {
      console.log("in box useffect :>> ", boxes);
      const img = new Image();
      img.src = boxes[2].url;
      img.crossOrigin = "Anonymous";
      img.onload = () => {
        console.log("contentImage loaded :>> ");
        // handleBoxChange(img, 2);
        setContentImage(img);
        // draw(canvas.current.getContext("2d"));
      };
    } else if (boxes[2].text) {
    }

    draw(canvas.current.getContext("2d"));

    // }
  }, [boxes]);

  useEffect(() => {
    // console.log("content image", contentImage);
    // handleBoxChange(logoUrl, 1);
    draw(canvas.current?.getContext("2d"));
  }, [logoUrl]);

  useEffect(() => {
    console.log("in contentImage useeffect :>> ", boxes);
    // handleBoxChange(contentImage, 2);
    draw(canvas.current?.getContext("2d"));
  }, [contentImage]);

  // const addNewBox = (e) => {
  // e.preventDefault();
  // e.stopPropagation();
  // canvas.current.getContext("2d").font = "40px Courier";
  // let width = canvas.current.getContext("2d").measureText(defaultText).width;
  // console.log("widthhh", width);
  // setBoxes([...boxes, { x: 200, y: 220, w: width, h: 50 }]);
  // [...boxes, { x: 200, y: 220, w: width, h: 50 }].map((info) =>
  // drawFillRect(canvas.current.getContext("2d"), info)
  // );
  // };

  // draw rectangle
  const draw = () => {
    // requestAnimationFrame(draw);
    canvas.current
      ?.getContext("2d")
      ?.clearRect(
        0,
        0,
        canvas.current.clientWidth,
        canvas.current.clientHeight
      );

    // ctx.clearRect(100, 100, 100, 100);
    // console.log("boxes in draw :>> ", boxes);
    let font =
      state.fontStyle +
      " " +
      state.fontWeight +
      " " +
      state.fontSize +
      " " +
      state.fontFamily;

    let contentFont =
      state.contentFontStyle +
      " " +
      state.contentFontWeight +
      " " +
      state.contentFontSize +
      " " +
      state.contentFontFamily;
    // console.log(font, "state", state.fontWeight);

    boxes?.map((info) =>
      drawFillRect(canvas.current?.getContext("2d"), info, font, contentFont)
    );
  };

  // draw rectangle with background
  // useEffect(()=>{
  // boxes.map((info) => drawFillRect(ctxSaved, info));

  // },[])
  const drawFillRect = (ctx, info, font, contentFont, style = {}) => {
    const ctxCurrent = canvas.current;
    ctx = ctxCurrent.getContext("2d");
    // console.log("ctx in drawfillrect :>> ", ctx);
    console.log("info :>> ", info); // info.type = contentText or title
    const { x, y, w, h } = info;
    const { backgroundColor = "transparent" } = style;

    ctx?.beginPath();
    ctx.fillStyle = backgroundColor;
    // ctx.strokeStyle = "white";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    ctx.fillRect(x, y, w, h);

    if (info.type === "contentText") {
      ctx.font = contentFont;
      ctx.fillStyle = state.contentFontColor;
    } else if (info.type === "title") {
      ctx.font = font;
      ctx.fillStyle = state.fontColor;
    }
    // ctx.strokeStyle = "black";
    // ctx.strokeText("Sample Text", x, y);
    // ctx.lineWidth = 2;
    // ctx.textAlign = "center";
    // ctx.textBaseline = "middle";
    // if (info.text == undefined) info.text = "Enter text";
    if (info.type === "contentImage") {
      console.log("info for content", info);
      // console.log("drawFillRect :>> ", drawFillRect);
      // const logo = new Image();
      // logo.src = info.url;
      // logo.onload = () => {
      // console.log("contentImage and logoUrl", contentImage, logoUrl);
      // if (logoUrl) ctx.drawImage(logoUrl, x, y, w, h);
      // else
      if (contentImage) {
        ctx.drawImage(contentImage, x, y, w, h);
      }
      // };
    } else if (info.type === "logo") {
      if (logoUrl) ctx.drawImage(logoUrl, x, y, w, h);
    } else {
      var maxWidth = 626 * 0.8;
      var lineHeight = 30;
      if (info.text) {
        console.log("x,y,w,h :>> ", info.text, x, y, w, h);
        wrapText(
          ctx,
          info.text,
          x + w / 2,
          y + h / 2,
          maxWidth,
          lineHeight,
          w,
          h
        );
      }
    }
    // ctx.fillText(info.text, x + w / 2, y + h / 2); // WRAP TEXT HERE

    // let message = "Sample Text";
    // console.log("text width", ctx.measureText(message));
  };
  console.log(boxes, "boxes");

  const wrapText = (context, text, x, y, maxWidth, lineHeight, w, h) => {
    console.log("text :>> ", text);
    var words = text.split(" ");
    var line = "";

    for (var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + " ";
      console.log("testLine :>> ", testLine);
      var metrics = context.measureText(testLine);
      var testWidth = metrics.width;
      console.log("testWidth :>> ", testWidth);
      if (testWidth > maxWidth && n > 0) {
        context.fillText(line, x, y);
        line = words[n] + " ";
        y += lineHeight;
      } else {
        line = testLine;
      }
    }
    context.fillText(line, x, y);
  };

  // identify the click event in the rectangle
  const hitBox = (x, y) => {
    let isTarget = null;
    for (let i = 0; i < boxes.length; i++) {
      const box = boxes[i];
      if (
        x >= box.x &&
        x <= box.x + box.w &&
        y >= box.y &&
        y <= box.y + box.h
      ) {
        console.log(box, x, y, "wityyy");

        dragTarget = box;
        isTarget = true;
        break;
      }
      console.log(box, x, y, "wityyy");
    }
    return isTarget;
  };
  const handleMouseDown = (e) => {
    console.log(
      "canvas.current.clientLeft, canvas.current.clientTop :>> ",
      canvas.current.clientLeft,
      canvas.current.clientTop
    );
    startX = parseInt(e.nativeEvent.offsetX - canvas.current.clientLeft);
    startY = parseInt(e.nativeEvent.offsetY - canvas.current.clientTop);
    isDown = hitBox(startX, startY);
  };
  const handleMouseMove = (e) => {
    if (!isDown) return;

    const mouseX = parseInt(e.nativeEvent.offsetX - canvas.current.clientLeft);
    const mouseY = parseInt(e.nativeEvent.offsetY - canvas.current.clientTop);
    const dx = mouseX - startX;
    const dy = mouseY - startY;
    startX = mouseX;
    startY = mouseY;
    dragTarget.x += dx;
    dragTarget.y += dy;
    // requestAnimationFrame(draw);
    draw(canvas.current?.getContext("2d"));
  };
  const handleMouseUp = (e) => {
    dragTarget = null;
    isDown = false;
  };
  const handleMouseOut = (e) => {
    handleMouseUp(e);
  };

  const handleDoubleClick = (e) => {
    console.log("Double click :>> ", e);

    startX = parseInt(e.nativeEvent.offsetX - canvas.current.clientLeft);
    startY = parseInt(e.nativeEvent.offsetY - canvas.current.clientTop);

    if (
      startX >= boxes[1].x &&
      startX <= boxes[1].x + boxes[1].w &&
      startY >= boxes[1].y &&
      startY <= boxes[1].y + boxes[1].h
    ) {
      console.log("dbl on logo");
      handleLogoDbl();
    } else if (
      startX >= boxes[2].x &&
      startX <= boxes[2].x + boxes[2].w &&
      startY >= boxes[2].y &&
      startY <= boxes[2].y + boxes[2].h
    ) {
      if (contentType === "image") {
        console.log("dbl on logo");

        handleContentLogoDbl();
      }
    } else console.log("not dbl on logo");
  };

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    console.log("file in blob", splitDataURI, byteString);
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  useEffect(() => {
    // const { text } = fontStyles;
    console.log("in use effect of text", fontText);
    // console.log("boxes :>> ", boxes);

    let width = canvas.current?.getContext("2d").measureText(fontText).width;
    handleTextWidth(width);

    if (contentType === "text") {
      let contentWidth = canvas.current
        .getContext("2d")
        .measureText(contentText).width;

      handleContentWidth(contentWidth, contentType);
    }

    // const newIds = boxes.slice(); //copy the array
    // newIds[0]["text"] = fontText; //execute the manipulations
    // newIds[0]["w"] = width;
    // this.setState({ids: newIds}) //set the new state

    // setBoxes(newIds);
  }, [fontText, contentText, state]);
  //handleClickSave
  const handleClickSave = async () => {
    if (templateName == "") {
      props.handleNoTemplateName();
      return;
    }
    console.log("in handleClick save");
    handleExit();
    finalCanvas.current.getContext("2d").drawImage(bgCanvas.current, 0, 0);
    finalCanvas.current.getContext("2d").drawImage(canvas.current, 0, 0);
    var image = finalCanvas.current.toDataURL();
    const form_Data = new FormData();
    const file = await DataURIToBlob(image);
    console.log("file in blob", file);
    form_Data.append("data", file);
    form_Data.append("userId", userId);
    form_Data.append("templateName", templateName);
    // form_Data.append("name", name);

    await dispatch(TemplateAction.saveTemplate(form_Data));
    console.log("finishSave");
    props.finishSave();
    // console.log("props after save", props.finishSave);
  };

  // useEffect(() => {}, [imgBg]);
  return (
    <div className=" canvasWrapper premEditTemplate">
      <div className="d-flex  ">
        <canvas ref={finalCanvas} width="626" height="417" />
        <canvas ref={bgCanvas} width="626" height="417" />
        <canvas
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseOut={handleMouseOut}
          onDoubleClick={handleDoubleClick}
          ref={canvas}
          width="626"
          height="417"
        />

        <img ref={image} src={imgBg} crossorigin="anonymous" hidden />
      </div>
      <div className="d-flex btnDiv justify-content-center ">
        <div className=" d-flex inputDivEditTemp">
          <div className="d-flex">
            <input
              className="tInput mt-3"
              type="text"
              onChange={handleChangeName}
              placeholder="Enter Template Name"
              value={templateName}
            />
            {/* <Link to={ROUTES.Template}>
              {" "} */}
            <Button
              className="ButtonEtemp"
              onClick={() => {
                props.handleExit();
                props.handleCancel();
              }}
            >
              Cancel
            </Button>
            {/* </Link> */}

            <Button className="ButtonEtempsave" onClick={handleClickSave}>
              Save
            </Button>
          </div>
          <div style={{ textAlign: "center", color: "red", fontSize: "17px" }}>
            {" "}
            {error}
          </div>
        </div>
      </div>

      {/* <button
// onClick={addNewBox}
// onClick={(e) => {

// // draw(canvas.current.getContext("2d"));
// // console.log("dataurl", dataURL);
// }}
>
{/ Add box /}
{/ </button> /}
{/ <div> /}
{/* {boxes.map((box, i) => {
return (
<div>
<div>Enter Text</div>
<input type="text" onChange={(e) => handleTextChange(e, i)} />
</div>
);
})} */}

      {/* <button
className="addBox"

>
Generate URL
</button> */}
    </div>
  );
};

export default PremEditTemplate;
