import Api from "../../Api";
// import { handleError } from "./commonAction";
import {
  ENDPOINTS,
  ACTION_TYPE,
  //   APPLICATION_CONSTANTS,
} from "../../Services/index";

export const Modal = (body) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    // console.log("hittt", body);
    // const res = await Api.post(ENDPOINTS.LOGIN, body);
    // console.log("RES", res);
    await dispatch({
      type: "MODAL",
      payload: body,
    });

    // if (res?.data?.status) {
    //   return res?.data?.status;
    // }
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
    // dispatch(handleError(error));
  }
};
export const Edit = (id) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    await dispatch({
      type: "EDIT",
      payload: id,
    });
    dispatch({ type: "LOADING_COMPLETED" });
  } catch (error) {
    dispatch({ type: "LOADING_FAILURE" });
  }
};
