import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

import DialogTitle from "@material-ui/core/DialogTitle";

export default function AlertDialog(props) {
  return (
    <div>
      <Dialog
        open={props.open.state}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        // aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"> {props.message}</DialogTitle>

        <DialogActions>
          <Button
            onClick={(e) => props.handleDelete(e, props.open.user)}
            color="primary"
          >
            {props.button1}
          </Button>
          <Button onClick={props.handleClose} color="primary" autoFocus>
            {props.button2}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
