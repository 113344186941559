// export const BASE_URL = "http://localhost:5000";
// export const BASE_URL = "https://signagecmsbackend-w3ptgbe6oa-uc.a.run.app";

export const BASE_URL =
  "https://ek31f0u706.execute-api.us-east-2.amazonaws.com/development";

//PROD
export const STRIPE_PUBLISHABLE_KEY =
  "pk_test_51IxBoTIPdocFCXqqqsQ0eZrGjXR0vIIf9FfPAGa0sufKjgh89iWAZDLSdJEBR7rJCG58OO9WyQehoWtIHaoVezwh00BsD8MnGF";

//DEV
// export const STRIPE_PUBLISHABLE_KEY =
// "pk_test_51HLiN0H7qZWET0ScWakrADq3P9n6DKVvQJTR2PtWny3Ndx66ZSajTpwbLUfT7OSCJF4R5WRlEQylAgLVrwii5tXJ00QhTp51BJ";

// https://signagecmsdev.s3.amazonaws.com/website/thumbnails/video.png
// https://savvsignagefiles.s3.us-east-2.amazonaws.com/website/thumbnails/video.png
export const playlistThumbnail =
  "https://savvsignagefiles.s3.us-east-2.amazonaws.com/website/thumbnails/playlist.jpg";
export const mp4Thumbnail =
  "https://savvsignagefiles.s3.us-east-2.amazonaws.com/website/thumbnails/video.png";
export const scheduleThumbnail =
  "https://savvsignagefiles.s3.us-east-2.amazonaws.com/website/thumbnails/schedule.png";

export const ENDPOINTS = {
  SIGNUP: "/userAuth/signup",
  LOGIN: "/userAuth/login",
  MATCH_TOKEN: "/userAuth/matchToken",
  POSTCONTENT: "/content/post",
  GETCONTENT: "/content/get",
  PLAYLISTGET: "/playlist/get",
  PLAYLISTCREATE: "/playlist/create",
  GETPLAYLISTBYID: "/playlist/get/byId",
  DELETEFROMPLAYLIST: "/playlist/remove",
  DELETEPLAYLIST: "/playlist/delete",
  ADDTOPLAYLIST: "/playlist/add",
  GETADMINTEMPLATE: "/template/getAdminTemplates",
  GETUSERTEMPLATE: "/template/getUserTemplates",
  GETUSERTEMPLATEBYID: "/template/getAdminTemplateById",
  SAVETEMPLATE: "/template/post",
  GETTEMPLATEBYID: "/template/getUserTemplatesById",
  SAVECOMPONENTTEMPLATE: "/template/saveComponentTemplate",
  SAVEWIDGET: "/template/postWidget",
  DELETETEMPLATE: "/template/deleteUserTemplate",
  ADDSCREEN: "/screens/add",
  ADDSCREENNEW: "/screens/addNew",
  GETSCREEN: "/screens/get",
  UPDATETEMPLATE: "/template/edit",
  GETSCREENBYID: "/screens/getById",
  EDITSCREEN: "/screens/edit",
  DELETESCREEN: "/screens/delete",
  GETLIST: "/userAuth/userList",
  USER_LIST_BY_ID: "/admin/userById",
  BOXESVALUE: "/template/boxesValue",
  ADD_BILLING_INFO: "/billing/billing-user-info",
  GET_STRIPE: "/stripe/create-checkout-session",
  ADDBRANDING: "/branding/post",
  EDITBRANDING: "/branding/edit",
  GETBRANDING: "branding/get",
  GETBRANDINGBYID: "/branding/getbyid",
  GET_ORDER_DETAILS: "/billing/getDetails",
  CONFIRM_STRIPE: "stripe/confirm",
  DELETE_CONTENT: "content/delete",
  SCHEDULEGET: "/schedule/get",
  SCHEDULEPOST: "/schedule/post",

  SCHEDULEGETBYID: "/schedule/getById",
  SCHEDULEUPDATE: "/schedule/update",
  ///
  stripe_recurring_session: "/recurringStripe/session",
  stripe_recurring_plan: "/recurringStripe/getPlan",
  stripe_recurring_customer_portal: "/recurringStripe/customerportal",
  STRIPE_RETRIEVE_UPCOMING_INVOICE: "/recurringStripe/retrieveUpcoming",
  STRIPE_UPDATE_SUBSCRIPTION: "/recurringStripe/updateSubscription",
  ContactUs: "/userAuth/contactUs",
};

export const ROUTES = {
  Screen: "/screen",
  Scheduling: "/scheduling",
  AddEvent: "/scheduling/add-event",
  Content: "/content",
  // CreateSchedule: "/create-schedule",
  finalScreen: "/final-screen",
  Template: "/template",
  PreviewTemplate: "/preview-template",
  ContactUs: "/contact-us",
  Playlists: "/playlist",
  Branding: "/branding",
  EditTemplate: "/template/edit/:id",
  EditScreen: "/screen/edit/:id",
  SideNav: "/side-nav",
  Signup: "/signup",
  Login: "/login",
  EditPlaylist: "/playlist/edit/:id",
  NewPlaylist: "/playlist/new",
  Profile: "/profile",
  NewDesign: "/design",
  EditDesign: "/design/:id",
  Home: "/home",
  Home: "/",
  BrandingEdit: "/editbranding",
  ViewUser: "/viewuser",
  UserProfile: "/userProfile/:id",
  Pricing: "/pricing",
  Billing: "/billing",
  Cancel: "/cancel/:id",
  Editbranding: "/editbranding/:id",
  //admin paneel
  // viewUser: "/viewuser",
  Confirmation: "/confirm/:orderNumber",
  UpgradeConfirmation: "/confirmation/upgrade",
  MyDesign: "/mydesigns",
  EditSchedule: "/schedule/edit/:id",
  Widgets: "/widgets",
  ContactUs: "/contact-us",
};
