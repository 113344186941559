import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { modalAction } from "../../../ReduxStore/Actions/index";
import { useDispatch, useSelector } from "react-redux";
import { TemplateAction } from "../../../ReduxStore/Actions";
import { Grid } from "@material-ui/core";
import "./template.css";
function ModalContent(props) {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.authReducer.data);
	const template = useSelector((state) => state.templateReducer);
	let params = {};
	const [state, setState] = React.useState();
	const handleChange = async (e, name) => {
		params = {
			name: name,
			uploadDate: "17 march 2021",
		};
		const status = await dispatch(modalAction.Modal(params));
		props.onHide();
	};

	useEffect(() => {
		dispatch(TemplateAction.getUserTemplate({ userId: data }));
	}, [data]);
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title
					className="Template-modal-1"
					id="contained-modal-title-vcenter"
				>
					Template
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="templateScreen-modal-body">
				<div className="col-md-12 d-flex">
					<Grid
						className="data pairingTemplateModal"
						container
						item
						xs={12}
						md={12}
						sm={12}
						// spacing={2}
					>
						{template?.dataUser?.data?.length > 0 &&
							template?.dataUser?.data.map((user) => (
								<div
									className="card "
									name="template"
									onClick={(e) => {
										props.onMediaSelect(e, user, "template");
										props.onHide();
									}}
								>
									<img
										src={user.url}
										alt="John"
										style={{ width: "100%" }}
										// className="modalImg"
										// onClick={() => {
										//   setTemp(user.url);
										//   setTemplate(true);
										//   setTempPath(`/template/edit/${user.id}`);
										// }}
									/>
									<div className="templateName123 text-center">
										{" "}
										{user.name}
									</div>
									{/* <div className="px-2 py-2 text-right">
                  <Link
                    to={{
                      pathname: `/template/edit/${user.id}`,
                      state: { url: user.url, width: 600, height: 400 },
                    }}
                  >
                    {" "}
                    {/* <EditIcon /> */}
									{/* </Link> */}
									{/* </div> */}
								</div>
							))}
					</Grid>
				</div>
			</Modal.Body>
		</Modal>
	);
}
export default ModalContent;
