import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLocation, withRouter } from "react-router";
import { ROUTES } from "../../Services/Constant/index";
import logo1 from "../../Images/HomePage/logo1.png";
import logoWhite from "../../Images/HomePage/logoWhite.png";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@material-ui/icons/Menu";
import "./Header.css";

const Header = (props) => {
  let location = useLocation();
  useEffect(() => {
    // let id = window.location.pathname;
  });
  const [isExpanded, handleToggle] = React.useState(false);
  const dispatch = useDispatch();
  // const location = useLocation();
  const authData = useSelector((state) => state.authReducer.authData);
  return (
    // <IonContent>
    <div
      className="fixed-top headerWeb"
      style={{ backgroundColor: props.trans, color: props.color }}
    >
      {/* <div className="hdr"> */}
      <div className="mainHeaderWeb" style={{ boxShadow: props.boxShadow }}>
        <div className="container1 row">
          <div className="logoTitle col-md-2 col-sm-2 mt-3 py-2">
            {location.pathname === "/home" || location.pathname === "/" ? (
              <img
                src={logoWhite}
                alt="logo"
                style={{ width: "130px" }}
                onClick={() => props.history.push(ROUTES.Home)}
              />
            ) : (
              <img
                src={logo1}
                alt="logo"
                style={{ width: "130px" }}
                onClick={() => props.history.push(ROUTES.Home)}
              />
            )}
          </div>

          <nav className="main-nav1 col-md-10 mt-3 d-lg-block">
            <Icon
              className="navbar-toggle hdriconbar"
              id="js-navbar-toggle"
              onClick={(e) => handleToggle(!isExpanded)}
            >
              menu
            </Icon>
            <ul
              className={`collapsed ${
                isExpanded ? "mainNav is-expanded main-nav" : "mainNav main-nav"
              }`}
            >
              <div className="mainNav1 mt-2 ">
                <li
                  onClick={() => {
                    handleToggle(false);
                  }}
                  // className={location.pathname === ROUTES.Home ? "active" : ""}
                >
                  <Link to={ROUTES.Home}>Home</Link>
                </li>
                {/* <li */}
                {/* // onClick={() => {
                  //   handleToggle(false);
                  // }}
                  // className={
                  //   location.pathname === ROUTES.Search ? "active" : ""
                  // }
                // > */}
                {/* Features */}
                {/* <Link to={ROUTES.Search}>Marketplace</Link>{" "} */}
                {/* </li> */}
                {/* <li          
                > */}
                {/* Features */}
                {/* <Link to={ROUTES.Search}>Marketplace</Link>{" "} */}
                {/* </li> */}

                <li
                  onClick={() => {
                    handleToggle(false);
                  }}
                  // className={
                  //   location.pathname === ROUTES.ContactUs ? "active" : ""
                  // }
                >
                  <NavLink
                    to={ROUTES.Pricing}
                    activeStyle={{
                      color: "#fdc400",
                      borderBottom: "3px solid #fdc400",
                    }}
                  >
                    Pricing
                  </NavLink>

                  {/* <Link to={ROUTES.Home}>About Us</Link>{" "} */}
                </li>
                {/* <li>About Us</li> */}

                <li
                  onClick={() => {
                    handleToggle(false);
                  }}
                >
                  <NavLink
                    to={ROUTES.ContactUs}
                    activeStyle={{
                      color: "#fdc400",
                      borderBottom: "3px solid #fdc400",
                    }}
                  >
                    Contact Us
                  </NavLink>{" "}
                </li>

                {localStorage.getItem("token") &&
                localStorage.getItem("userData") ? (
                  <div>
                    <li
                      className="Account d-flex flex-row"
                      onClick={() => {
                        handleToggle(false);
                      }}
                    >
                      <Link to={ROUTES.Screen} style={{ padding: "0px 0px" }}>
                        <button
                          style={{
                            background: "#ffffff",
                            borderColor: "#fff",
                            border: "1px solid #fdc400",
                            color: "#000",
                            borderRadius: "10px",
                            width: "200px",
                            height: "38px",
                            fontWeight: 500,
                            marginTop: "-10px",

                            // marginTop: "30px",
                          }}
                        >
                          Go To CMS
                        </button>
                      </Link>
                      <Link
                        onClick={async () => {
                          dispatch({ type: "LOGOUT_USER" });

                          // await history.push("/");
                        }}
                        style={{ padding: "0px 0px" }}
                      >
                        <button
                          className="ml-3 logoutheader"
                          style={{
                            width: "135px",
                            height: "38px",
                            background: "#fdc400",
                            borderColor: "#fdc400",
                            color: "#000",
                            borderRadius: "10px",
                            fontWeight: "500",
                            marginTop: "-10px",
                          }}
                        >
                          Logout
                        </button>
                      </Link>
                    </li>
                    {authData && authData["role"] === 2 && (
                      <Link
                        to={ROUTES.ViewUser}
                        style={{
                          padding: "0px 0px",
                          float: "none",
                          marginTop: "5px",
                        }}
                      >
                        <button
                          style={{
                            background: "#ffffff",
                            borderColor: "#fff",
                            border: "1px solid #fdc400",
                            color: "#000",
                            borderRadius: "10px",
                            width: "200px",
                            height: "38px",
                            fontWeight: 500,
                            marginTop: "-10px",

                            // marginTop: "30px",
                          }}
                        >
                          Admin panel
                        </button>
                      </Link>
                    )}
                  </div>
                ) : (
                  <li
                    onClick={() => {
                      handleToggle(false);
                    }}
                    className="Account d-flex flex-row"
                  >
                    <Link to={ROUTES.Login} style={{ padding: "0px 0px" }}>
                      <button
                        style={{
                          background: "#ffffff",
                          borderColor: "#fff",
                          border: "1px solid #fdc400",
                          color: "#000",
                          borderRadius: "10px",
                          width: "134px",
                          height: "38px",
                          fontWeight: 500,
                          marginTop: "-10px",

                          // marginTop: "30px",
                        }}
                      >
                        Log In
                      </button>
                    </Link>
                    <Link to={ROUTES.Signup}>
                      <button
                        className="ml-3"
                        style={{
                          width: "135px",
                          height: "38px",
                          background: "#fdc400",
                          borderColor: "#fdc400",
                          color: "#000",
                          borderRadius: "10px",
                          fontWeight: "500",
                          marginTop: "-10px",
                        }}
                      >
                        Sign Up
                      </button>
                    </Link>{" "}
                  </li>
                )}
              </div>
            </ul>
          </nav>
        </div>
      </div>
      {/* </div> */}
    </div>
    // </IonContent>
  );
};

export default withRouter(Header);
