import axios from "axios";
import "./billing.css";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory } from "react-router";
import { SubscriptionAction } from "../../ReduxStore/Actions";
import { ROUTES, STRIPE_PUBLISHABLE_KEY } from "../../Services/Constant";
const stripePromise = loadStripe(
  // "pk_test_51IvDnoJJDZbeFMzLjLQn7lhKE2nVEM8fFIOaeMMqVaQCtPPz0kQKN8X9TswClfSrY4t5o88pJoazXsWL7qTCz1ij00Przytj8x"
  STRIPE_PUBLISHABLE_KEY
);

const Checkout = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const billingId = useSelector(
    (state) => state.bookReducer.data?.data?.data?.billingId
  );
  const data = useSelector((state) => state.authReducer.authData);
  const onPayment = async (e, type) => {
    e.preventDefault();
    e.stopPropagation();

    if (type === "create") {
      const stripe = await stripePromise;

      let params = {
        priceId: props.location.state.PriceId,
        email: data.email,
        billingId: billingId,
      };
      const res = await dispatch(SubscriptionAction.getStripeSession(params));
      const result = await stripe.redirectToCheckout({
        sessionId: res?.data.sessionId,
      });
    } else if (type === "update") {
      let params = {
        newPriceId: props.location.state.PriceId,
        subscriptionId: props.location.state.subscription,
        subscription_proration_date:
          props.location.state.subscription_proration_date,
      };

      dispatch(SubscriptionAction.updateSubscription(params)).then(() => {
        history.push({
          pathname: ROUTES.UpgradeConfirmation,
        });
      });
    }
  };

  if (props.location.state.purpose === "new")
    return (
      <div className="checkoutopt">
        <div className="container">
          <h2>Checkout</h2>

          <div className="gradientBox gradientBox2">
            <div className="col-sm-12">
              <div className="commonTopicStepsDiv text-center">
                {/* <h4 style={{ float: "left" }}>Step 4 of 4 </h4> */}
                <h4>Review your Booking</h4>
              </div>
              <div className="grayborder">
                <h4></h4>

                <div className="oclear">
                  <div className="col-sm-6 cardsDiv1 leftdiv col">
                    <div className="flex">
                      <h5>Name:</h5>
                      <h5>
                        {data?.firstName} {data?.lastName}
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-6 cardsDiv1 rightdiv col">
                    <div className="">
                      <h5>Plan Duration:</h5>
                      <h5> One month</h5>
                    </div>
                  </div>
                </div>
                <div className="oclear">
                  <div className="col-sm-6 cardsDiv1 leftdiv col">
                    <div className="flex">
                      <h5>Email:</h5>
                      <h5>{data?.email}</h5>
                    </div>
                  </div>
                  <div className="col-sm-6 cardsDiv1 rightdiv col">
                    <div className="flex">
                      {/* <h5>No of Guards:</h5>
                    <h5>{state?.noOfGuard}</h5> */}
                    </div>
                  </div>
                </div>
                <div className="oclear">
                  <div className="col-sm-6 cardsDiv1 leftdiv col">
                    <div className="flex">
                      <h5>Phone:</h5>
                      <h5>{data?.phone}</h5>
                    </div>
                  </div>
                  <div className="col-sm-6 cardsDiv1 rightdiv col">
                    <div className="flex">
                      <h5>Total Amount : </h5>
                      <h5>{props?.location?.state?.amount} </h5>
                    </div>
                  </div>
                </div>
                <div className="oclear">
                  <div className="col-sm-6 cardsDiv1 leftdiv col">
                    <div className="flex flex-column">
                      <h5>Plan: </h5>
                      <h5>{props?.location?.state?.plan}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grayborder oclear">
              <div className="cardsDiv col-md-12">
                <form className="text-center">
                  <a className="pwrdbystripe">
                    <font>Powered by</font> stripe
                  </a>
                  <div className="text-center">
                    {/* <button
                    className="submitPaymnt"
                    onClick={() => props.stepInfo("ONE")}
                  >
                    Edit Details
                  </button> */}
                    <button
                      className="submitPaymnt"
                      onClick={(e) => onPayment(e, "create")}
                    >
                      Make Payment
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else if (props.location.state.purpose === "upgrade") {
    return (
      <div className="checkoutopt">
        <div className="container">
          <h2>Upgrade</h2>

          <div className="gradientBox gradientBox2">
            <div className="col-sm-12">
              <div className="commonTopicStepsDiv text-center">
                {/* <h4 style={{ float: "left" }}>Step 4 of 4 </h4> */}
                <h4>Review your Booking</h4>
              </div>
              <div className="grayborder">
                <h4></h4>

                <div className="oclear">
                  <div className="col-sm-6 cardsDiv1 leftdiv col">
                    <div className="flex">
                      <h5>Name:</h5>
                      <h5>
                        {data?.firstName} {data?.lastName}
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-6 cardsDiv1 rightdiv col">
                    <div className="">
                      <h5>Plan Duration:</h5>
                      <h5> One month</h5>
                    </div>
                  </div>
                </div>
                <div className="oclear">
                  <div className="col-sm-6 cardsDiv1 leftdiv col">
                    <div className="flex">
                      <h5>Email:</h5>
                      <h5>{data?.email}</h5>
                    </div>
                  </div>
                  <div className="col-sm-6 cardsDiv1 rightdiv col">
                    <div className="flex">
                      {/* <h5>No of Guards:</h5>
                    <h5>{state?.noOfGuard}</h5> */}
                    </div>
                  </div>
                </div>
                <div className="oclear">
                  <div className="col-sm-6 cardsDiv1 leftdiv col">
                    <div className="flex">
                      <h5>Phone:</h5>
                      <h5>{data?.phone}</h5>
                    </div>
                  </div>
                  <div className="col-sm-6 cardsDiv1 rightdiv col">
                    <div className="flex">
                      <h5>Final Adjusted Amount:</h5>
                      <h5>{props?.location?.state?.amount} </h5>
                    </div>
                  </div>
                </div>
                <div className="oclear">
                  <div className="col-sm-6 cardsDiv1 leftdiv col">
                    <div className="flex flex-column">
                      <h5>Plan: </h5>
                      <h5>{props?.location?.state?.plan}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grayborder oclear">
              <div className="cardsDiv col-md-12">
                <form className="text-center">
                  <a className="pwrdbystripe">
                    <font>Powered by</font> stripe
                  </a>
                  <div className="text-center">
                    {/* <button
                    className="submitPaymnt"
                    onClick={() => props.stepInfo("ONE")}
                  >
                    Edit Details
                  </button> */}
                    <button
                      className="submitPaymnt"
                      onClick={(e) => onPayment(e, "update")}
                    >
                      Upgrade
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Checkout;
