import React from "react";
import { ROUTES } from "../Services/Constant";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import WebsiteModule from "../WebsiteModule/WebsiteModules";
import Login from "../WebsiteModule/Login/Login";
import Signup from "../WebsiteModule/signup/Signup";
import Home from "../WebsiteModule/home/Home";
import PrivateRoute from "./PrivateRoutes";
import ViewUser from "../AdminPanel/viewUser";
import UserProfile from "../AdminPanel/userProfile";
import Pricing from "../DashboardModule/pricing/index";
import Billing from "../DashboardModule/pricing/billing";
import Confirmation from "../DashboardModule/pricing/component/confirmation";
import UpgradeConfirmation from "../DashboardModule/pricing/component/upgradeConfirmation";
import Screen from "../DashboardModule/Screens/index";
import Cancel from "../DashboardModule/pricing/component/cancel";
import Content from "../DashboardModule/Content/Content";
import Template from "../DashboardModule/Template/Template";
import Playlist from "../DashboardModule/Playlist/Playlist";
// import Scheduling from "../DashboardModule/Scheduling/Scheduling";
import Branding from "../DashboardModule/Branding/Branding";
import Profile from "../DashboardModule/profile/profile";
import EditPlaylist from "../DashboardModule/Playlist/component/Editplaylist";
import EditTemplateNew from "../DashboardModule/Template/editTemplate/editTemplateNew";
// import AddEvent from "../DashboardModule/Scheduling/AddEvent";
import Widgets from "../DashboardModule/Widgets";
import ContactUs from "../WebsiteModule/contactPage/ContactUs";
import ScrollToTop from "../Hooks/ScrollToTop";

const PublicRoutes = () => {
  return (
    <Router>
      <ScrollToTop></ScrollToTop>
      <Switch>
        <PrivateRoute path={ROUTES.Signup} component={Signup} exact />
        <PrivateRoute path={ROUTES.Login} component={Login} exact />
        <Route path={ROUTES.Home} component={Home} exact />
        <Route path={ROUTES.ContactUs} component={ContactUs} exact />
        <PrivateRoute path={ROUTES.ViewUser} component={ViewUser} />
        <PrivateRoute path={ROUTES.UserProfile} component={UserProfile} />
        <Route path={ROUTES.Pricing} component={Pricing} exact />
        <Route path={ROUTES.Confirmation} component={Confirmation} exact />
        <Route
          path={ROUTES.UpgradeConfirmation}
          component={UpgradeConfirmation}
          exact
        />
        <Route path={ROUTES.Cancel} component={Cancel} exact />
        <PrivateRoute path={ROUTES.Billing} component={Billing} exact />
        <PrivateRoute path={ROUTES.Screen} component={Screen} exact />
        <PrivateRoute path={ROUTES.Content} component={Content} exact />
        <PrivateRoute path={ROUTES.Playlists} component={Playlist} exact />
        <PrivateRoute path={ROUTES.Template} component={Template} exact />
        {/* <PrivateRoute path={ROUTES.Scheduling} component={Scheduling} exact /> */}
        <PrivateRoute path={ROUTES.Branding} component={Branding} />
        <PrivateRoute path={ROUTES.Profile} component={Profile} />
        <PrivateRoute path={ROUTES.Branding} component={Branding} />
        {/* <PrivateRoute path={ROUTES.AddEvent} component={AddEvent} /> */}
        <PrivateRoute path={ROUTES.Widgets} component={Widgets} />
        {/* <PrivateRoute
          path={ROUTES.EditPlaylist}
          component={EditPlaylist}
          exact
        /> */}
        {/* <PrivateRoute
          path={ROUTES.EditTemplate}
          component={EditTemplateNew}
          exact
        /> */}
        <PrivateRoute
          path={ROUTES.NewPlaylist}
          component={EditPlaylist}
          exact
        />
        <WebsiteModule />;
      </Switch>
    </Router>
  );
};
export default PublicRoutes;
