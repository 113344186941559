import Api from "../../Api";
import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";
import { screenRef } from "../../Services/Util/firebase";
import firebase from "firebase";
//post content
export const setCode = (params) => async (dispatch) => {
  console.log("setCode type hittt", params);

  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });

    dispatch({
      type: ACTION_TYPE.SET_CODE,
      payload: params,
    });
    resolve();
    // console.log(res.data, "kk");
    dispatch({ type: "LOADING_COMPLETED" });
  });
};

export const addScreen = (params) => async (dispatch) => {
  console.log("createScreen type hittt", params);

  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.ADDSCREENNEW, params)
      .then((res) => {
        //   console.log("profile ", res);
        //   console.log(res, params, "res in contentpost");

        //   dispatch({
        //     type: ACTION_TYPE.PLAYLIST_CREATE,
        //     payload: res.data.data,
        //   });
        dispatch({ type: "LOADING_COMPLETED" });
        resolve(res?.data);
        //   console.log(res.data, "kk");
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};

export const editScreen = (params) => async (dispatch) => {
  console.log("EDIT SCREEN type hittt", params);

  return new Promise((resolve, reject) => {
    // dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.EDITSCREEN, params)
      .then((res) => {
        //   console.log("profile ", res);
        //   console.log(res, params, "res in contentpost");

        //   dispatch({
        //     type: ACTION_TYPE.PLAYLIST_CREATE,
        //     payload: res.data.data,
        //   });
        dispatch({ type: "LOADING_COMPLETED" });
        resolve(res?.data);
        //   console.log(res.data, "kk");
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};

export const deleteScreen = (params) => async (dispatch) => {
  console.log("EDIT SCREEN type hittt", params);

  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.DELETESCREEN, params)
      .then((res) => {
        //   console.log("profile ", res);
        //   console.log(res, params, "res in contentpost");

        //   dispatch({
        //     type: ACTION_TYPE.PLAYLIST_CREATE,
        //     payload: res.data.data,
        //   });
        dispatch({ type: "LOADING_COMPLETED" });
        resolve(res?.data);
        //   console.log(res.data, "kk");
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};

export const getScreen = (params) => async (dispatch) => {
  console.log("createScreen type hittt", params);

  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.GETSCREEN, params)
      .then((res) => {
        console.log("GETSCREEN ", res);
        //   console.log(res, params, "res in contentpost");
        if (res.data.data.length > 0)
          listenToChanges(res?.data?.data, dispatch);
        else
          dispatch({
            type: ACTION_TYPE.SCREEN_GET,
            payload: res.data,
          });

        // firebase.auth().signOut();
        dispatch({ type: "LOADING_COMPLETED" });
        // resolve(res?.data);
        //   console.log(res.data, "kk");
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};

export const getScreenById = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.GETSCREENBYID, params)
      .then((res) => {
        console.log(res, "in temp");
        dispatch({
          type: ACTION_TYPE.SCREEN_GET_BY_ID,
          payload: res,
        });
        resolve(res?.data);
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};

const listenToChanges = (screens, dispatch) => {
  for (let i = 0; i < screens.length; i++) {
    screenRef.child(screens[i]["firebase_screen_id"]).on("value", (snap) => {
      if (snap.exists()) {
        let data = snap.val();
        const { screenStatus, active } = data;
        console.log(
          "screenStatus, screens[i]. :>> ",
          screenStatus,
          screens[i]["firebase_screen_id"]
        );

        screens[i]["screenStatus"] = screenStatus;
        screens[i]["active"] = active;

        dispatch({
          type: ACTION_TYPE.SCREEN_GET,
          payload: screens,
        });
      }
    });
  }
};
