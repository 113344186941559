import axios from "axios";
import { BASE_URL } from "./Services/index";
import { APPLICATION_CONSTANTS } from "./Services/String";
import firebase from "firebase";

const axiosInstance = axios.create({ baseURL: BASE_URL });

const errHandling = (error) => Promise.reject(error);

const reqHandling = async (config) => {
  const obj = { ...config };
  const token = localStorage.getItem("token");
  console.log("firebase.auth().currentUser :>> ", firebase.auth());
  let fbToken;
  // if (firebase.auth().currentUser)
  //   fbToken = await firebase.auth().currentUser.getIdToken();

  const fbToken1 = localStorage.getItem(APPLICATION_CONSTANTS.ID_TOKEN);
  if (fbToken1 && firebase.auth().currentUser)
    fbToken = await firebase.auth().currentUser.getIdToken();

  if (fbToken) obj.headers["Authorization"] = `Bearer ${fbToken}`;
  return obj;
};

const resHandling = (response) => response;
axiosInstance.interceptors.request.use(reqHandling, errHandling);
axiosInstance.interceptors.response.use(resHandling, errHandling);

export default axiosInstance;
