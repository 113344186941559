import React, { useEffect } from "react";
import Footer from "../component/footer/Footer";
import "./viewUser.css";
import { Button, Typography, Divider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AdminAction } from "../ReduxStore/Actions";
import { Link } from "react-router-dom";
import SideBar from "./Sidebar/sideBar";
const ViewUser = () => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.adminReducer.data);
  console.log(list, "list");
  useEffect(() => {
    (async () => {
      await dispatch(AdminAction.getList());
    })();
  }, []);

  return (
    <div>
      {/* <div className="headeruser">
        <Header trans="white" color="black" />
      </div> */}
      <div className="row">
        <div className="col-md-3">
          <SideBar />
        </div>
        <div className="col-md-9">
          <div className="bodyadmin1 ">
            <h3 style={{ textAlign: "center" }}>User Management</h3>
            <ul style={{ listStyle: "none", marginTop: "50px" }}>
              {list?.map((user, i) => {
                return (
                  <li className="vendor-Card">
                    <div className="d-flex">
                      <h5>{i + 1}.</h5>
                      <Typography
                        className="content2"
                        variant="h6"
                        gutterBottom
                      >
                        {/* <span
                          style={{
                            color: "#fdc400",
                            fontWeight: "700",
                            fontSize: "25px",
                          }}
                        >
                          {" "}
                          User Name:{" "}
                        </span> */}
                        {`${user.firstName} ${user.lastName}`}
                      </Typography>
                      <Link
                        to={{
                          pathname: `/userProfile/${user.id}`,
                          state: { user: user },
                        }}
                      >
                        <Button className="text-right Viewbtn">
                          View User
                        </Button>
                      </Link>
                    </div>
                    <Typography
                      className="content1"
                      variant="body1"
                      gutterBottom
                    ></Typography>
                    <Divider variant="middle" />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewUser;
