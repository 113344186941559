import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import logoWhite from "../../Images/HomePage/logoWhite.png";
import { ROUTES } from "../../Services";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Divider from "@material-ui/core/Divider";

import "./Footer.css";

const Footer = (props) => {
  return (
    <footer>
      <div className="InFoot" id="footer">
        <div className="container">
          <div className="footIn newFooterFlex">
            <div className="newContactUsImage">
              <img
                src={logoWhite}
                alt="logo"
                style={{ maxWidth: "145px", cursor: "pointer" }}
                onClick={() => props.history.push(ROUTES.Home)}
              />
            </div>
            <div>
              <div className="displayList ">
                <ul className="newsList newFooterList">
                  <li>
                    <Link to={ROUTES.Home}>Home</Link>
                  </li>
                  <li>
                    <FiberManualRecordIcon
                      style={{
                        color: "#F8C446",
                        fontSize: "10px",
                        marginTop: "10px",
                      }}
                    />
                  </li>
                  <li>
                    <Link to={ROUTES.Pricing}>Pricing</Link>
                  </li>
                  <li>
                    <FiberManualRecordIcon
                      style={{
                        color: "#F8C446",
                        fontSize: "10px",
                        marginTop: "10px",
                      }}
                    />
                  </li>
                  <li>
                    <Link to={ROUTES.ContactUs}>Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="newContactUsEmail">
              <p>info@savvsignage.com</p>
            </div>
            <Divider className="footerDivider" />

            {/*	<div className="in-block">
							<div className="col-md-5 col col-sm-8 ">
								<img src={logoWhite} alt="logo" style={{ maxWidth: "145px" }} />
							</div>
						</div>
						<div className="col-md-2 col-sm-2 col-xs-7 col relative newslttr mt-4 "></div>
						<div className="col-md-9 col-sm-9 col-xs-12 col relative newslttr ">
							<div className="displayList col-md-4 col-sm-6 col-xs-12 col ">
								<ul className="newsList">
									<li>
										<Link to={ROUTES.Home}>Home</Link>
									</li>
									<li>
										<Link to={ROUTES.Pricing}>Pricing</Link>
									</li>
									<li>
										<Link to={ROUTES.ContactUs}>Contact Us</Link>
									</li>
								</ul>
							</div> */}
            {/* <div
								className="col-md-4 col-sm-6 col-xs-12 col"
								style={{ float: "right" }}>
								<ul className="newsList">
									<lable className="lable-footer ">About Us</lable>
									<li>
										<Link to={ROUTES.ContactUs}>Contact Us</Link>
									</li>
								</ul>
							</div> */}
            {/* <div className="col-md-4 col-sm-6 col-xs-12 col">
								<ul className="newsList">
									<lable className="lable-footer ">Resources</lable>

									<li>
										<Link
										//  to={ROUTES.Home}
										>
											Support
										</Link>
									</li>
									<li>
										<Link
										//  to={ROUTES.Home}
										>
											FAQ
										</Link>
									</li>
								</ul>
							</div> */}
            {/* </div>
						<div className="copyRight">
							<p>Copyright © 2021. All Right Reserved </p>
						</div>*/}
            <div className="copyRight">
              <p>Copyright © 2021. All Right Reserved </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withRouter(Footer);
