import React, { useEffect, useState } from "react";
import "./profile.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Form, Button } from "react-bootstrap";
import { useHistory } from "react-router";
import axios from "axios";
import { Divider, Link } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { APPLICATION_CONSTANTS } from "../../Services/String";
import { loadStripe } from "@stripe/stripe-js";
import { Toast } from "react-bootstrap";
// import ModalToast from "../../component/modal/modalToast";
import ModalToast from "../../component/toastModal/modalToast";
import { ROUTES } from "../../Services";
import { AuthAction, SubscriptionAction } from "../../ReduxStore/Actions";
const stripePromise = loadStripe(
  "pk_test_51IWLgGFYqkzSNbUwkKneusnaEwtsJtno2POHQX9zOtYZAo1vE7yeW9T5OhzQmFg284BZNiCpo2mkxYKBlK6sn3On00VBkZAt2x"
);
const Profile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [cancelSub, setCancelSub] = useState(false);

  const authData = useSelector((state) => state.authReducer.authData);
  const data = useSelector((state) => state.authReducer.data);

  useEffect(() => {
    // dispatch(AuthAction.matchToken());
    console.log("In profile useEffect");
  }, []);

  useEffect(() => {
    setToast(false);
  }, [authData]);
  const handleClick = () => {
    console.log("clicked");
    history.push("/screen");
  };
  const handleUpdateSub = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModal(false);
    const res = await dispatch(
      SubscriptionAction.getStripeCustomerPortal({
        customer_id: authData && authData["customer_id"],
      })
    );
    console.log(res, "res");
    // await axios.post(
    //   "http://localhost:5000/recurringStripe/updateSub",
    //   {
    //     customer_id: authData && authData.payment[0]["customer_id"],
    //   }
    // );
    window.location.href = res.data;
  };

  return (
    <div className="row Profile row">
      <ModalToast
        show={modal}
        title="Manage your Subscriptions"
        setModal={setModal}
        message="You can manage your subscription and explore history here!"
        buttonName="continue"
        handleClickModal={handleUpdateSub}
      />
      <div className="col-md-2"></div>
      <div className="col-md-10 profileStart">
        <div className="profileHead ml-4 mt-4 mb-2 d-flex flex-row">
          <ArrowBackIosIcon onClick={handleClick} />
          <h5 onClick={handleClick}>Back to CMS</h5>
          <Button className="profileSignout mr-5 text-center">
            <Link
              onClick={async () => {
                dispatch(AuthAction.logOut());

                history.push("/");

                // await history.push("/");
              }}
            >
              Sign Out
            </Link>
          </Button>
        </div>
        <div className="profileForm  ml-4 ">
          <Toast
            className="ToastComponent"
            // show={errorStatus === false ? true : false}
            show={toast}
            // {...props}
            onClose={() => setToast(false)}
            delay={5000}
            autohide
          >
            <Toast.Header className="ToastBodyComponent">
              <strong className="mr-auto">{toastMessage}</strong>
            </Toast.Header>
          </Toast>
          <Form>
            <h3 className="profileh3">Basic Information:</h3>
            <Divider variant="fullWidth" />
            <Form.Row>
              <div className="col-md-6">
                <Form.Label>First Name :</Form.Label>

                {authData["firstName"]}
              </div>

              <div className="col-md-6">
                <Form.Label>Last Name :</Form.Label>

                {authData["lastName"]}
              </div>
            </Form.Row>
            <Form.Row>
              <div className="col-md-6 mb-5">
                <Form.Label>Email : </Form.Label>

                {authData["email"]}
              </div>

              <div className="col-md-6">
                <Form.Label>Phone No :</Form.Label>

                {authData["phone"]}
              </div>
            </Form.Row>

            <Divider variant="fullWidth" />
            <h3 className=" profileh3 mt-3">Subscription Plan</h3>

            <Divider variant="fullWidth" />

            {authData.payment === "Basic" ? (
              <div className="text-center">
                <h3 className="profileplan">Basic Plan</h3>
                <Button
                  className="profilebutton standard  mt-3"
                  type="submit"
                  onClick={(e) => {
                    history.push(`/pricing`);
                  }}
                >
                  Buy a plan
                </Button>
              </div>
            ) : authData && authData["payment"] === "Standard" ? (
              <div className="profileStandard  ">
                <h3 className="profileplan"> Standard</h3>
                <h5 className="line">
                  Try our Premium Plan for more additional benefits
                </h5>
                <div className="standard ">
                  <Button
                    hidden={cancelSub}
                    className="profilebutton  mt-3 m-2"
                    onClick={(e) => history.push(ROUTES.Pricing)}
                  >
                    Upgrade Your Plan
                  </Button>
                  <Button
                    hidden={cancelSub}
                    className="profilebutton  mt-3 m-2"
                    onClick={(e) => setModal(true)}
                  >
                    Manage your Payments
                  </Button>
                </div>
              </div>
            ) : authData && authData["payment"] === "Premium" ? (
              <div className="standard">
                <h3 className="profileplan"> Premium </h3>
                <Button
                  hidden={cancelSub}
                  className="profilebutton  mt-3"
                  onClick={(e) => setModal(true)}
                >
                  Manage your Payments
                </Button>
              </div>
            ) : null}
          </Form>
        </div>
      </div>
    </div>
  );
};
export default Profile;
