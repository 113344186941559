import React from "react";
import ClearIcon from "@material-ui/icons/Clear";

import "./Header.css";
import { Button, Form, FormControl } from "react-bootstrap";
const HearderBar = (props) => {
  const [searchName, setSearchName] = React.useState();
  const [showCross, setShowCross] = React.useState("none");
  const handleChangeText = (e) => {
    setSearchName(e.target.value);
  };
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("props.datalist", props.dataList, searchName);
    let list = props.dataList
      .filter((listItem) =>
        listItem.name
          ? listItem.name.toLowerCase().includes(searchName?.toLowerCase())
          : listItem.mediaName
          ? listItem.mediaName.toLowerCase().includes(searchName?.toLowerCase())
          : null
      )
      .map((listItem) => {
        return listItem;
      });

    props.handleChangeList(list);
    setShowCross("block");
  };

  const handleCLickCross = (e) => {
    setShowCross("none");
    props.clear();
    setSearchName("");
  };
  return (
    <div>
      <div className="d-flex flex-row mt-4 topnavdivlist newtopnavdivlist">
        {" "}
        <div className="headerTitle mt-1 col-md-3 col-sm-3 col-4">
          <h3 className="">{props.title}</h3>
        </div>
        {props.searchd === "none" ? null : (
          <Form className=" header90 mt-1 d-flex col-md-6 col-sm-6 col-8">
            <FormControl
              type="text"
              placeholder={props.placeholder}
              className="headerSearch"
              value={searchName}
              onChange={(e) => handleChangeText(e)}
            />
            <ClearIcon
              className="crossButton"
              style={{ display: showCross, marginTop: "5px" }}
              onClick={(e) => handleCLickCross(e)}
            />
            <Button
              className="headerSearchButton"
              onClick={(e) => handleClick(e)}
            >
              Search
            </Button>
          </Form>
        )}
        {props.buttonName && (
          <div
            className=" col-md-3 col-sm-3  thirdtopnav"
            style={{ display: props.display }}
          >
            <button className="headerButton" onClick={props.handleChange}>
              {props.buttonName}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default HearderBar;
