import React, { useState } from "react";
import { Divider, Grid } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { HexColorPicker } from "react-colorful";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import "./imageMap.css";
import ImageMapItems from "./ImageMapItems";

const ImageMapConfigurations = ({
  canvasRef,
  selectedItem,
  onChangeStyles,
  styles,
  onChange,
}) => {
  const [isContentColorDisplay, setContentColorDisplay] = useState("none");

  // set the style of color palet
  const handleContentColorClick = () => {
    isContentColorDisplay == "none"
      ? setContentColorDisplay("flex")
      : setContentColorDisplay("none");
  };

  const handleStyle = (e, name) => {
    // name == "fontStyle"
    // 	? state.fontStyle == "normal"
    // 		? setState({ ...state, [name]: "italic" })
    // 		: setState({ ...state, [name]: "normal" })
    // 	: state.fontWeight == "normal"
    // 	? setState({ ...state, [name]: "bold" })
    // 	: setState({ ...state, [name]: "normal" });
  };
  if (selectedItem == null)
    return (
      <div className=" rightNav imageMapConf">
        <div className="sideBar-wrapper">
          <ImageMapItems canvasRef={canvasRef} />
        </div>
        <h3 className=" headingEdit text-center ">Properties</h3>
        <div className="h">
          <div className="noType">
            Choose a component to show its properties
          </div>
        </div>
      </div>
    );
  else {
    const { type } = selectedItem;
    return (
      <div className=" rightNav imageMapConf">
        <div className="sideBar-wrapper">
          <ImageMapItems canvasRef={canvasRef} />
        </div>
        <h3 className=" headingEdit text-center ">Properties</h3>

        {type === "iText" && (
          <div className="h">
            {console.log("typee", selectedItem)}
            <div>
              <Grid container className="gridEdit edit__container">
                <Button
                  className={
                    selectedItem["fontWeight"] === "bold"
                      ? "btn1grid_active"
                      : "btn1grid"
                  }
                  onClick={(e) =>
                    onChange(selectedItem, {
                      fontWeight:
                        selectedItem["fontWeight"] === "bold"
                          ? "normal"
                          : "bold",
                    })
                  }
                >
                  <FormatBoldIcon
                  //  value={selectedItem["fontFamily"]}
                  />
                </Button>
                <Button
                  className={
                    selectedItem["fontStyle"] === "italic"
                      ? "btn2grid_active"
                      : "btn2grid"
                  }
                  onClick={(e) =>
                    onChange(selectedItem, {
                      fontStyle:
                        selectedItem["fontStyle"] === "italic"
                          ? "normal"
                          : "italic",
                    })
                  }
                >
                  <FormatItalicIcon
                  //    value={selectedItem["fontFamily"]}
                  />
                </Button>
              </Grid>
            </div>
            <div className="htitle titleip">
              <select
                name="Title font family"
                value={selectedItem["fontFamily"]}
                onChange={(e) =>
                  onChange(selectedItem, { fontFamily: e.target.value })
                }
              >
                <option value="arial">arial</option>
                <option value="emoji">Emoji</option>
                <option value="math">Math</option>
                <option value="monospace">Monospace</option>
                <option value="fangsong">fangsong</option>
                <option value="fantasy">Fantasy</option>
                <option value="inherit">Inherit</option>
                <option value="poppins">Poppins</option>
                <option value="cursive">cursive</option>
                <option value="Courier">Courier</option>
                <option value="unset">Unset</option>
                <option value="ui-serif">Ui-serif</option>
                <option value="sans-serif">sans-serif</option>
                <option value="Verdana">Verdana</option>
                <option value="Tahoma">Tahoma</option>
                <option value="revert">Revert</option>
              </select>
            </div>

            <div className="Tcolor halfdiv">
              <input
                type="text"
                value={selectedItem["fill"]}
                placeholder="Content Text color"
              />
              <ColorLensIcon onClick={handleContentColorClick} />
              <HexColorPicker
                style={{ display: isContentColorDisplay }}
                color={selectedItem["fill"]}
                onChange={(e) => onChange(selectedItem, { fill: e })}
              />
            </div>
            <Button
              color="danger"
              className="btn btn-danger"
              style={{ width: "100%" }}
              onClick={() => {
                console.log(
                  "canvasRef.current.handler :>> "
                  //   canvasRef.current.handler.onRemove("sa")
                );
                canvasRef.current.handler.onRemove(selectedItem);
              }}
            >
              Delete
            </Button>
          </div>
        )}
        {(type === "image" || type === "circle" || type === "rect") && (
          <div className="h">
            {(type === "circle" || type === "rect") && (
              <div className="Tcolor halfdiv">
                <input
                  type="text"
                  value={selectedItem["fill"]}
                  placeholder="Content Text color"
                />
                <ColorLensIcon onClick={handleContentColorClick} />
                <HexColorPicker
                  style={{ display: isContentColorDisplay }}
                  color={selectedItem["fill"]}
                  onChange={(e) => onChange(selectedItem, { fill: e })}
                />
              </div>
            )}
            <Button
              color="danger"
              className="btn btn-danger"
              style={{ width: "100%" }}
              onClick={() => {
                console.log(
                  "canvasRef.current.handler :>> ",
                  canvasRef.current.handler
                );
                canvasRef.current.handler.onRemove(selectedItem);
              }}
            >
              Delete
            </Button>
          </div>
        )}
      </div>
    );
  }
};

export default ImageMapConfigurations;
