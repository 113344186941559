import React, { useEffect } from "react";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import Icon from "@material-ui/icons/Menu";
import "./SideNav.css";
import { Divider, Popover } from "@material-ui/core";
import logoWhite from "../../Images/HomePage/logoWhite.png";
import Logo4 from "../../Images/icons/logo4.png";
import User from "../../Images/icons/user.png";
import Logo44 from "../../Images/icons/logo44.png";
import BurstModeIcon from "@material-ui/icons/BurstMode";
import User1 from "../../Images/icons/user1.png";
import CreateIcon from "@material-ui/icons/Create";
import { ROUTES } from "../../Services/Constant";
import { useDispatch, useSelector } from "react-redux";
import { APPLICATION_CONSTANTS } from "../../Services/String";
import ComputerIcon from "@material-ui/icons/Computer";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import FolderIcon from "@material-ui/icons/Folder";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const SideBar = (props) => {
	let location = useLocation();
	useEffect(() => {
		let id = window.location.pathname;
	});
	const [isExpanded, handleToggle] = React.useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	var classes = "sidenav";

	const authData = useSelector((state) => state.authReducer.authData);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// const handleToggleMenu = (e) => {
	// e.stopPropagation();
	// 	console.log("Menu icon", e.target.className);
	// 	handleToggle(!isExpanded);
	// 	if (!e.target.classList.contains("is-expanded") && handleToggle === true) {
	// 		return handleToggle(!isExpanded);
	// 	}
	// };

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	const match = useRouteMatch("/template/edit/:id");
	const match2 = useRouteMatch("/playlist/edit/:id");
	const firstName =
		localStorage.getItem("token") && localStorage.getItem("userData")
			? localStorage.getItem("userData")
			: null;
	console.log(match?.url, match, "pp");
	return (
		<div className="sidenav">
			<div className="row ">
				<div className="col-lg-2">
					<Icon
						className="navbar-toggle hdriconbar1"
						id="js-navbar-toggle"
						onClick={(e) => handleToggle(!isExpanded)}
					>
						menu
					</Icon>
					<div
						className={`collapsed ${
							isExpanded ? "msb mainNav is-expanded " : "msb mainNav main-nav"
						}`}
						id="msb"
					>
						<nav className="navbar navbar-default newNavbar" role="navigation">
							<div className="side-menu">
								<ul className="nav navbar-nav navblist">
									<div className="admin-list">
										<h2
											style={{
												textAlign: "center",
												color: "#fff",
												marginTop: "5px",
											}}
										>
											<Link to={ROUTES.Home}>
												<img src={logoWhite} alt="logo" />
											</Link>
										</h2>

										<li
											className={
												location.pathname === ROUTES.Screen
													? "num  t-0 active"
													: "num  t-0 "
											}
										>
											<Link to={ROUTES.Screen}>
												{location.pathname === ROUTES.Screen ? (
													<ComputerIcon alt="logo" className="icon sidelogo1" />
												) : (
													<ComputerIcon alt="logo" className="icon sidelogo1" />
												)}
												Screens
											</Link>
										</li>

										<li
											className={
												location.pathname === ROUTES.Content
													? "num mt-4 active"
													: "num  mt-4 "
											}
										>
											<Link to={ROUTES.Content}>
												{location.pathname === ROUTES.Content ? (
													<PermMediaIcon
														// src={Logo22}
														alt="logo"
														className="icon sidelogo1"
													/>
												) : (
													<PermMediaIcon
														// src={Logo2}
														alt="logo"
														className="icon sidelogo1"
													/>
												)}
												Contents
											</Link>
										</li>
										<li
											className={
												location.pathname === ROUTES.Playlists ||
												location.pathname === ROUTES.NewPlaylist ||
												location.pathname === match2?.url
													? "num mt-4 active"
													: "num  mt-4 "
											}
										>
											<Link to={ROUTES.Playlists}>
												{location.pathname === ROUTES.Playlists ||
												location.pathname === ROUTES.NewPlaylist ||
												// location.pathname === "/playlist/edit/2" ||
												location.pathname === match2?.url ? (
													<PlaylistPlayIcon
														// src={Logo44}
														alt="logo"
														className="icon sidelogo1"
													/>
												) : (
													<PlaylistPlayIcon
														// src={Logo4}
														alt="logo"
														className="icon sidelogo1"
													/>
												)}
												Playlists
											</Link>
										</li>
										<li
											className={
												location.pathname === ROUTES.Template
													? "num mt-4 active"
													: "num  mt-4 "
											}
										>
											{location.pathname === ROUTES.Template ? (
												<FolderIcon alt="logo" className="icon sidelogo1" />
											) : (
												<FolderIcon alt="logo" className="icon sidelogo1" />
											)}

											<Link to={ROUTES.Template}>Templates</Link>
										</li>

										<li
											className={
												location.pathname === ROUTES.NewDesign
													? "num mt-4 active"
													: "num  mt-4 "
											}
										>
											{location.pathname === ROUTES.NewDesign ? (
												<CreateIcon className="icon sidelogo1" />
											) : (
												<CreateIcon className="icon sidelogo1" />
											)}

											<Link to={ROUTES.NewDesign}>Editor</Link>
										</li>

										<li className="num  mt-4 mb-4" id="myDesign-li">
											<Link
												className="headerButtonSideNav"
												to={ROUTES.MyDesign}
											>
												<BurstModeIcon className="icon sidelogo1" />
												<span> My Designs</span>
											</Link>
										</li>

										<li
											className="num  mb-1 "
											style={{
												position: "absolute",
												bottom: "52px",
												color: "#fdc401",
											}}
										>
											{authData && authData["payment"]}
										</li>

										<li
											className={
												location.pathname === ROUTES.Profile
													? "num  mt-4  active adminlist"
													: "num  mt-4 adminlist "
											}
											onClick={(e) => handleClick(e)}
										>
											{location.pathname === ROUTES.Profile ? (
												<AccountCircleIcon className="icon sidelogo1" />
											) : (
												// <img src={User1} alt="logo" className="icon sidelogo" />
												// <img src={User} alt="logo" className="icon sidelogo" />
												<AccountCircleIcon className="icon sidelogo1" />
											)}
											{`${authData && authData["firstName"]}`}
										</li>
										<Popover
											id={id}
											open={open}
											className="popjhon"
											anchorEl={anchorEl}
											onClose={handleClose}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "center",
											}}
											transformOrigin={{
												vertical: "top",
												horizontal: "center",
											}}
										>
											<ul className="popupjohn">
												<Link to={ROUTES.Profile}>
													<li
														className={
															location.pathname === ROUTES.Profile
																? "active"
																: "  "
														}
													>
														My Account
													</li>
												</Link>
												<Divider variant="fullWidth" />
												<li
													className={
														location.pathname === ROUTES.Prof ? "active" : "  "
													}
												>
													<Link
														onClick={async () => {
															dispatch({ type: "LOGOUT_USER" });
															await localStorage.removeItem(
																APPLICATION_CONSTANTS.USER_DATA
															);
															await localStorage.removeItem(
																APPLICATION_CONSTANTS.TOKEN
															);
															await history.push("/");
														}}
													>
														{" "}
														Logout
													</Link>
												</li>
											</ul>
										</Popover>
									</div>
								</ul>
							</div>
						</nav>
					</div>
				</div>
				<div className="hidden-md col-lg-10"></div>
			</div>
		</div>
	);
};
export default SideBar;
