import React from "react";
import { Divider } from "@material-ui/core";
import { Card } from "react-bootstrap";
import "./priceCard.css";
const PriceCard = (props) => {
  console.log(props, "props hitted");
  return (
    <div className="priceCard">
      <div
        // style={{ width: "20rem", cursor: "pointer" }}
        className="cardp text-center"
        onClick={(e) =>
          props.handleClick(e, props.amount, props.priceId, props.title)
        }
      >
        <div className="Headerp">{props.title}</div>
        <Card.Body>
          <Card.Title>
            <Divider className="c2div" variant="middle" />
          </Card.Title>
          <Card.Text>
            {props.title.trim() === "Basic" && (
              <ul className="stylep">
                <li>Screen Management</li>
                <li>User Management </li>

                <li>Content Management </li>
                <li>Templates </li>
                <li>Support 24*7</li>
                <li>1 Screen</li>
              </ul>
            )}
            {props.title.trim() === "Standard" && (
              <ul className="stylep">
                <li>Screen Management</li>
                <li>User Management </li>

                <li>Content Management </li>
                <li>Templates </li>
                <li>Support 24*7</li>

                <li>5 Screens</li>
              </ul>
            )}
            {props.title.trim() === "Premium" && (
              <ul className="stylep">
                <li>Screen Management</li>
                <li>User Management </li>

                <li>Content Management </li>
                <li>Templates </li>
                <li>Support 24*7</li>
                <li>Unlimited Screens</li>
              </ul>
            )}
          </Card.Text>
          <Card.Title>
            <Divider className="c2div" variant="middle" />
          </Card.Title>
          <Card.Title className="c2Title2">
            <h1>{props.amount}</h1>
          </Card.Title>
          {/* <div style={{ color: "red" }}>{toastMessage}</div> */}
        </Card.Body>
      </div>
    </div>
  );
};
export default PriceCard;
