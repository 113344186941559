import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlaylistAction, ScreenAction } from "../../../ReduxStore/Actions";
import Header from "../../../component/headerBar/Header";
import { Card, Divider, Link, Popover } from "@material-ui/core";
import play from "../../../Images/commonImages/play.jpg";
import SettingsIcon from "@material-ui/icons/Edit";
import EditScreenModal from "../modals/EditScreenModal";
import firebase from "../../../Services/Util/firebase";
import { ROUTES } from "../../../Services";
import "./editScreen.css";
import { Button } from "react-bootstrap";
import {
	mp4Thumbnail,
	playlistThumbnail,
} from "../../../Services/Constant/index";
import { scheduleThumbnail } from "../../../Services/Constant/index";

import Preview from "./preview";
import EditInFirebase from "../../../Services/Util/editInFiebase";
const EditScreen = (props) => {
	const dispatch = useDispatch();
	const { id: userId } = useSelector((state) => state.authReducer.authData);
	const screenDetails = useSelector((state) => state.screenReducer.screenById);
	const [currentMedia, setCurrentMedia] = useState({});
	const [modalShow2, setModalShow2] = useState(false);
	const [mediaType, setMediaType] = useState();
	// const [playlistItem, setPlaylistItem] = useState();
	const [media, setMedia] = useState();
	const [selected, setSelected] = useState();
	const data = useSelector((state) => state.authReducer.data);
	const playlistData = useSelector(
		(state) => state.playlistReducer.playlistData.data
	);
	const playlist = playlistData ? playlistData[0] : null;

	useEffect(() => {
		if (userId) {
			let params = {
				userId,
				screenId: props.match.params.id,
			};
			dispatch(ScreenAction.getScreenById(params));
		}
	}, [userId]);

	useEffect(() => {
		console.log("screenDetails :>> ", screenDetails);
		setCurrentMedia(screenDetails);
		setMediaType(screenDetails.screenType?.toLowerCase());
	}, [screenDetails]);

	const onMediaSelect = (e, selectedMedia, type) => {
		e.preventDefault();
		e.stopPropagation();
		console.log("media :>> ", selectedMedia);
		console.log("type :>> ", type);
		if (type.toLowerCase() === "playlist") {
			setSelected(selectedMedia.mediaName);
		} else {
			setSelected(selectedMedia.name || type);
		}

		setCurrentMedia(selectedMedia);
		setMedia(selectedMedia);
		setMediaType(type);
		// }
	};

	const handleSave = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		console.log("media :>> ", media);
		console.log("mediaType :>> ", mediaType);
		console.log("iop", screenDetails);

		let mediaId;
		if (mediaType.toLowerCase() === "playlist") {
			mediaId = media.playlistId;
		} else mediaId = media.id;

		let params = {
			type: mediaType,
			typeId: mediaId,
			firebase_screen_id: screenDetails.firebase_screen_id,
			screenId: screenDetails.screenId,
		};
		await dispatch(ScreenAction.editScreen(params));

		let getParams = {
			userId,
		};
		dispatch(ScreenAction.getScreen(getParams));
		props.history.push(ROUTES.Screen);
	};
	// const handleChangeitem = (item) => {
	//   {playlistItem &&
	//     playlistItem.map((item) => {
	//       handleChangeitem(item);
	//     })}
	//   const t = item.duration * 1000;

	//   setTimeout(() => {

	//   }, [t]);
	// };
	console.log(currentMedia, "currentMedia");
	return (
		<div className="editscreen">
			<div className="row">
				<div className="col-md-2">{/* <SideNav /> */}</div>
				<div className="col-md-10 tabFullview">
					<div className="playlistHeader mt-3 mb-3">
						<Header
							title="Edit Screen"
							// buttonName="Save"
							searchd="none"
							placeholder=" Search Screen"
						/>
					</div>
					<EditScreenModal
						show={modalShow2}
						className="screen-modal2"
						onMediaSelect={onMediaSelect}
						selected={selected}
						onHide={() => {
							setModalShow2(false);
						}}
					/>
					<Divider variant="fullWidth" />

					{currentMedia ? (
						<div className="playist-Screen-body p-l mt-2 ml-2 mr-3">
							<Card className="d-flex flex-row row  playlistList">
								<div className=" col-md-6 d-flex  img">
									<img
										className="editscreenimg"
										// src={
										//   mediaType === "playlist"
										//     ? playlistThumbnail
										//     : mediaType === "Schedule"
										//     ? scheduleThumbnail
										//     : currentMedia.url
										// }
										src={
											mediaType === "playlist"
												? playlistThumbnail
												: mediaType === "Schedule"
												? scheduleThumbnail
												: currentMedia?.url?.split(".")[
														currentMedia?.url?.split(".").length - 1
												  ] === "mp4" ||
												  currentMedia?.url?.split(".")[
														currentMedia?.url?.split(".").length - 1
												  ] === "wav" ||
												  currentMedia?.url?.split(".")[
														currentMedia?.url?.split(".").length - 1
												  ] === "3gp"
												? mp4Thumbnail
												: currentMedia.url
										}
										alt="logo"
									/>
									<div className="flex-column ml-4">
										<div className=" playlistchange d-flex flex-column">
											{/* <input
                            type="text"
                            className="playlistName"
                            name="playlistName"
                            value={user.name}
                            disabled={!playlistDetails.editName}
                          /> screenName*/}
											<h3>{currentMedia.screenName}</h3>

											<h6>
												{mediaType === "playlist"
													? currentMedia.mediaName
													: currentMedia.name}
											</h6>
										</div>

										{/* <p className="ml-1">
                          {" "}
                          Total items: {user.media.length}
                        </p> */}
									</div>
								</div>

								<div className=" col-md-3 text-right mobileTopMargin ">
									<div className="d-flex flex-row ">
										<p className="mr-2">Published</p>
										<div className="editIcon">
											{/* <SettingsIcon /> */}
											<button
												className="editbtn"
												style={{ cursor: "pointer" }}
												onClick={(e) => setModalShow2(true)}
											>
												Edit
											</button>
										</div>
									</div>
								</div>
							</Card>
							<div className="editDiv d-flex editprevdiv">
								<Button className="m-2 " onClick={handleSave}>
									Save
								</Button>
								<Button
									className="m-2 cancel"
									onClick={() => {
										props.history.push(ROUTES.Screen);
									}}
								>
									Cancel
								</Button>
							</div>
							{/* <Preview
                currentMedia={currentMedia}
                playlistItem={playlistItem}
                playlist={playlist}
              /> */}
							{/* <div className="prviewSection">
                <h4>Preview</h4>
                <div className="display text-center">
                  {console.log(currentMedia, playlistData, "curr")}
                  {currentMedia?.url ? (
                    <img
                      style={{
                        width: "900px",
                        height: "300px",
                      }}
                      src={currentMedia?.url}
                      alt="logo"
                    />
                  ) : null}

                  <img
                    style={{
                      width: "900px",
                      height: "300px",
                    }}
                    // src={item?.url}
                    alt="logo"
                  />
                </div>
              </div> */}
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default EditScreen;
