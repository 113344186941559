import React, { useState } from "react";
import "./cardGrid.css";
import Loader from "../../Services/Util/loader";
import { useSelector } from "react-redux";
import { IconButton, Popover } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Delete";

import MoreVertIcon from "@material-ui/icons/MoreVert";
const CardGrid = (props) => {
  const [selectedId, setSelectedId] = useState();
  const loading = useSelector((state) => state.commonReducer.loading);
  const [anchorEl, setAnchorEl] = React.useState("none");

  const handleClick = (event) => {
    console.log(event, "event");
    setSelectedId(props.id);
    const a = anchorEl === "none" ? setAnchorEl("block") : setAnchorEl("none");
  };

  const handleClose = () => {
    setAnchorEl("none");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleSetting = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleClose();
    props.handleClickSetting(e, props.id);
  };
  return (
    // <div className="cardGrid">
    <div className=" cardGrid card hovereffectpop">
      <img src={props.url} className="imgCard" />
      <div className="overlay-content">
        <MoreVertIcon
          onClick={handleClick}
          style={{ color: "White" }}
          // className="setti/ngIcon"
        />
      </div>
      <div
        className="popDivcontent"
        style={
          props.id == selectedId ? { display: anchorEl } : { display: "none" }
        }
      >
        <ul className="popupcontent">
          <li onClick={(e) => handleSetting(e)}>Set to screen</li>
        </ul>
      </div>

      <div className="row11 text-left">
        <p className="cardName">{props.name}</p>
        <p className="cardDate d-block">
          {props.timeStam}

          <div className="loaderCardGrid">
            {selectedId != undefined &&
            selectedId === props.loadSpinnerfor &&
            loading ? (
              <Loader
                loading={loading}

                // style={{ color: "#000000", float: "right" }}
              />
            ) : (
              <IconButton
                aria-label="settings"
                // style={{ display: "none" }}
              >
                <FavoriteIcon
                  style={{ color: "red" }}
                  onClick={(e) => {
                    console.log("DELETED");
                    setSelectedId(props.id);
                    props.handleDel(e, props.id);
                  }}
                />
              </IconButton>
            )}
          </div>
        </p>
      </div>
    </div>
    // </div>
  );
};
export default CardGrid;
