import React, { useState, useEffect, Component } from "react";
import { fabric } from "fabric";
import { v4 } from "uuid";
import Handler from "../handlers/handler";
import { Button } from "react-bootstrap";
import "./canvas.css";
import { FileCopySharp } from "@material-ui/icons";

class Canvas extends Component {
  constructor(props) {
    super(props);
    this.handler = null;
    this.canvas = null;
    this.height = 0;
    this.width = 0;
    this.templateName = "";
    this.noTemplateName = null;

    this.state = {
      id: v4(),
      ref: this.props.ref,
    };
  }

  componentDidMount() {
    const { id } = this.state;

    const {
      minZoom,
      maxZoom,
      ref,
      designNo,
      userId,
      state,
      dimension,
      ...rest
    } = this.props;

    this.width = dimension.width;
    this.height = dimension.height;
    if (designNo === undefined) {
      this.canvas = new fabric.Canvas(`canvas_${id}`, {
        height: this.height,
        width: this.width,
        backgroundColor: "white",
      });
    } else if (designNo !== undefined && state?.type === "user") {
      this.setState({ templateName: state.name });
      this.canvas = new fabric.Canvas(`canvas_${id}`, {
        height: state.component.height,
        width: state.component.width,
      });
      this.canvas.clear();

      this.canvas.loadFromJSON(state.component, () => {
        this.canvas.renderAll();
      });
    } else if (designNo !== undefined && state?.type === "admin") {
      this.canvas = new fabric.Canvas(`canvas_${id}`, {
        // height: json.height,
        // width: json.width,
        height: "540",
        width: "960",
      });
      this.canvas.clear();

      this.canvas.loadFromJSON(state?.component, () => {
        this.canvas.renderAll();
      });
    }

    this.handler = new Handler({
      id,
      canvas: this.canvas,
      ...rest,
    });
  }

  componentDidUpdate(prevProps, props, prevState) {
    console.log("prevState :>> ", prevState);

    let dummyVariable = 0;
    let canvasObjectLength = this.canvas.getObjects().length;
    {
      this.props.designNo
        ? (dummyVariable = 1)
        : this.canvas
            .setWidth(this.props.dimension.width)
            .setHeight(this.props.dimension.height);
    }

    console.log("canvasObjectLength :>> ", canvasObjectLength);
  }

  DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    console.log("file in blob", splitDataURI);
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    console.log("file in blob", splitDataURI, new Blob([ia]));

    return new Blob([ia], { mimeString });
  };

  render() {
    const { id } = this.state;
    return (
      <div className="canvas-wrapper fabric " id={id}>
        <div className="bttnDiv">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              className="tInput"
              type="text"
              onChange={(e) => this.setState({ templateName: e.target.value })}
              placeholder="Enter Template Name"
              value={this.state.templateName}
            />
            {/* <Link to={ROUTES.Template}> */}{" "}
            {/* <Button className="ButtonEtemp">Cancel</Button> */}
            {/* </Link> */}
            {/* <Link> */}{" "}
            <Button
              className="ButtonEtempsave ml-4 "
              onClick={() => {
                if (
                  this.state.templateName == "" ||
                  typeof this.state.templateName == "undefined"
                ) {
                  this.setState({
                    noTemplateName: true,
                  });
                  return;
                }

                const { onSave, handleExit } = this.props;
                handleExit();
                let option = { name: "New Image", format: "png", quality: 1 };
                const dataUrl = this.canvas.toDataURL(option);
                let svgSimple = this.canvas.toJSON();

                svgSimple.objects.forEach((element, id) => {
                  const newId = v4();
                  element.id = newId;
                });
                svgSimple.height = this.props.dimension.height;
                svgSimple.width = this.props.dimension.width;

                const svgJson = JSON.stringify(svgSimple);

                if (dataUrl) {
                  const file = this.DataURIToBlob(dataUrl);

                  onSave(svgJson, file, this.state.templateName);

                  //   const anchorEl = document.createElement("a");
                  //   anchorEl.href = dataUrl;
                  //   anchorEl.download = `${option.name}.png`;
                  //   document.body.appendChild(anchorEl); // required for firefox
                  //   anchorEl.click();
                  //   anchorEl.remove();
                }
              }}
            >
              Save
            </Button>
            {/* </Link> */}
          </div>
          {this.state.noTemplateName === true && (
            <div style={{ textAlign: "center", color: "red" }}>
              Please Enter Template Name !
            </div>
          )}
          <div style={{ textAlign: "center", color: "red", fontSize: "20px" }}>
            {" "}
            {/* {error} */}
          </div>
        </div>
        {this.props.designNo ? null : (
          <div className="ratio">Ratio: {this.props.dimension.title}</div>
        )}

        <canvas id={`canvas_${id}`} />
      </div>
    );
  }
}

export default Canvas;
