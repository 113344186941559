export const ACTION_TYPE = {
  //auth
  SIGNUP: "SIGNUP",
  LOGIN: "LOGIN",
  LOGOUT_USER: "LOGOUT_USER",
  CONTENT_POST: "CONTENT_POST",
  CONTENT_GET: "CONTENT_GET",
  CONTENT_DELETE: "CONTENT_CONTENT",
  PLAYLIST_GET: "PLAYLIST_GET",
  PLAYLIST_CREATE: "PLAYLIST_CREATE",
  PLAYLIST_GET_ID: "PLAYLIST_GET_ID",
  PLAYLIST_REMOVE: "PLAYLIST_REMOVE",
  PLAYLIST_DELETE: "PLAYLIST_DELETE",
  PLAYLIST_ADD: "PLAYLIST_ADD",
  TEMPLATE_GET_ADMIN: "TEMPLATE_GET_ADMIN",
  TEMPLATE_GET_USER: "TEMPLATE_GET_USER",
  TEMPLATE_GET_ADMIN_BY_ID: "TEMPLATE_GET_ADMIN_BY_ID",
  DELETE_TEMPLATE: "DELETE_TEMPLATE",
  SET_CODE: "SET_CODE",
  SET_CODE_SCREEN: "SET_CODE_SCREEN",
  SCREEN_GET: "SCREEN_GET",
  SCREEN_GET_BY_ID: "SCREEN_GET_BY_ID",
  BOXESVALUE: "BOXESVALUE",
  ADD_BILLING_INFO: "ADD_BILLING_INFO",
  ADD_BRANDING: "ADD_BRANDING",
  EDIT_BRANDING: "EDITBRANDING",
  GET_BRANDING: "GETBRANDING",
  GET_BRANDING_ID: "GET_BRANDING_ID",
  TEMPLATE_GET_USER_BY_ID: "TEMPLATE_GET_USER_BY_ID",
  CONFIRM_STRIPE: "CONFIRM_STRIPE",
  ORDER_DETAILS: "ORDER_DETAILS",
  SCHEDULE_GET: "SCHEDULE_GET",
  SCHEDULE_POST: "SCHEDULE_POST",
  STRIPE_PLAN: "STRIPE_PLAN",
  SCHEDULE_GET_BY_ID: "SCHEDULE_GET_BY_ID",
};
