import React from "react";
import "./finalScreen.css";
import SideNav from "../sideNav/SideNav";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutline";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { Card } from "@material-ui/core";

const finalScreen = () => {
  return (
    <div className="final-screen">
      <SideNav />
      <div className="finalscreenstart">
        <div>
          <div className=" final-screen-header">
            <p>Screens</p>
            <input
              className="final-screen-search"
              type="text"
              placeholder="Search Screens"
            />
            <div className="final-screen-button">
              <button
                className="button-final-screen"
                // onClick={() => setModalShow(true)}
              >
                {" "}
                New Screen
              </button>
            </div>
          </div>
          <div className="final-screen-content">
            <h3> Screen 1 </h3>
            <br />
            <ul style={{ listStyle: "none" }}>
              <li>
                <Card className="d-flex card-screen">
                  <img src="" alt="logo" />
                  <div className="d-flex flex-column desc ml-5">
                    <h5>Porche.png</h5>
                    <h6>Upload:17 March2021</h6>
                  </div>
                  <div className="d-flex style1">
                    <EditIcon />
                    <DeleteOutlinedIcon />
                    <SettingsOutlinedIcon />
                  </div>
                </Card>
              </li>
            </ul>
            <h3>Preview</h3>
            {/* <img className="screen-img1" src={screenimg} alt="" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default finalScreen;
