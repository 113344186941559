import Api from "../../Api";
import { ENDPOINTS, ACTION_TYPE } from "../../Services/index";
//post content
export const getList = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.get(ENDPOINTS.GETLIST)
      .then((res) => {
        dispatch({
          type: "GET_LIST",
          payload: res.data,
        });
        resolve(res?.data);
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};

export const getUserById = (params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: "LOADING_STARTED" });
    Api.post(ENDPOINTS.USER_LIST_BY_ID, params)
      .then((res) => {
        dispatch({
          type: "GET_USER_DETAILS",
          payload: res.data,
        });
        resolve(res?.data);
        dispatch({ type: "LOADING_COMPLETED" });
      })
      .catch((err) => {
        dispatch({ type: "LOADING_FAILURE" });
        reject(err);
      });
  });
};
