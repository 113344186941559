import { ACTION_TYPE, APPLICATION_CONSTANTS } from "../../Services/index";
export const initialState = {
  data: [],
  DELETE: "",
  playlistData: [],
  add: "",
};

export const playlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.PLAYLIST_CREATE:
      return {
        ...state,
        // data: action.payload,
      };
    case ACTION_TYPE.PLAYLIST_GET:
      return {
        ...state,
        data: action.payload.data,
      };
    case ACTION_TYPE.PLAYLIST_GET_ID:
      return {
        ...state,
        playlistData: action.payload.data,
      };
    case ACTION_TYPE.PLAYLIST_REMOVE:
      return {
        ...state,
        DELETE: action.payload.data,
      };
    case ACTION_TYPE.PLAYLIST_REMOVE:
      return {
        ...state,
        add: action.payload.data,
      };
    case ACTION_TYPE.PLAYLIST_DELETE:
      return {
        ...state,
        add: action.payload.data,
      };
    default:
      return state;
  }
};
