import "./cancel.css";
import cross from "../../../Images/commonImages/cross.png";
import { useEffect } from "react";
// import { bookAction } from "../../ReduxStore/Actions/index";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
// import { APPLICATION_CONSTANTS } from "../../constants/appplication";
// import { FormatTimeSlot } from "../../helper/index";
const Cancel = (props) => {
  let dispatch = useDispatch();
  const data = useSelector((state) => state.authReducer.authData);
  console.log(data, "data");
  //   useEffect(() => {
  //     (async () => {
  //       await dispatch(
  //         StripeAction.confirmStripe({
  //           billingId: props.match.params.orderNumber,
  //         })
  //       );
  //     })();
  //   }, []);
  //   useEffect(() => {
  //     (async () => {
  //       await dispatch(
  //         bookAction.orderDetails({ orderNumber: props.match.params.orderNumber })
  //       );
  //       localStorage.removeItem(APPLICATION_CONSTANTS.BOOKING_DETAILS);
  //     })();
  //   }, []);
  //   const orderDetails = useSelector(
  //     (state) => state.bookNowReducer.orderDetails
  //   );
  return (
    <div>
      <section className="cancelpage p-t-90">
        <div className="container">
          <div className="gradientBox">
            <div className="tickdiv">
              <img src={cross} className="crossicon" alt="Tick" />
            </div>
            <h3>Order has been declined</h3>
            <div className="widthConf border1">
              <div className="">
                <div className="flex">
                  <h5>Order no :</h5>
                  <div>
                    <p>{props.match.params.id}</p>
                  </div>
                </div>
                <div className="flex">
                  <h5>Delivery for :</h5>
                  <div>
                    <p>
                      {data?.firstName} {data?.lastName}
                      <br /> Phone no. {data?.phone}
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <h5>Email :</h5>
                  <div>
                    <p>{data?.email}</p>
                  </div>
                </div>
                <div className="d-flex flex-row ">
                  <h4>Total :</h4>
                  <div>
                    <h5>$5.99</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className=" d-flex justify-content-center">
              <Button
                className="bckBtn  mt-2"
                onClick={() => {
                  props.history.push("/");
                }}
              >
                Back
              </Button>
            </div>
            <div className="widthConf"></div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Cancel;
