import React from "react";
import logoWhite from "../../Images/HomePage/logoWhite.png";

import "./sideNav.css";
import { ROUTES } from "../../Services/index";
// import { IonIcon } from "@ionic/react";
import { Edit } from "@material-ui/icons";
// import { caretBack, caretForwardCircleOutline } from "ionicons/icons";
import { NavLink, useLocation, Link } from "react-router-dom";

const SideBar = (props) => {
  //   const location = useLocation();
  return (
    <div className="msb1">
      <nav className="navbar navbar-default" role="navigation">
        <div className="side-menu">
          <Link to={ROUTES.Home}>
            <h2
              style={{
                textAlign: "center",
                color: "#fff",
                marginBottom: "40px",
              }}
            >
              <img
                src={logoWhite}
                alt="logo"
                style={{ maxWidth: "170px" }}
                // onClick={() => props.history.push(ROUTES.Home)}
              />
            </h2>
          </Link>
          <ul className="nav navbar-nav navblist">
            <div className="admin-list" style={{ paddingLeft: "30px" }}>
              <li className="num">
                <NavLink
                  to={ROUTES.Home}
                  activeStyle={{
                    color: "#fdc400",
                    // borderBottom: "3px solid #fdc400",
                  }}
                >
                  <a
                    className="list-value"
                    // className={
                    //   location.pathname === ROUTES.Home
                    //     ? "list-value active"
                    //     : "list-value"
                    // }
                  >
                    Home
                  </a>
                </NavLink>
              </li>
              <li className="num">
                <NavLink
                  to={ROUTES.ViewUser}
                  //    to={`/home`}
                  activeStyle={{
                    color: "#fdc400",
                    borderBottom: "3px solid #fdc400",
                  }}
                >
                  {" "}
                  <a className="list-value">View User</a>
                </NavLink>
              </li>
            </div>
          </ul>
        </div>
        {/* // <!-- /.navbar-collapse --> */}
      </nav>
    </div>
  );
};
export default SideBar;
