import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import Canvas from "./components/canvas";
import ImageMapItems from "./ImageMap/ImageMapItems";
import ImageMapConfigurations from "./ImageMap/ImageMapConfigurations";
import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { TemplateAction } from "../../ReduxStore/Actions";
import Header from "../../component/headerBar/Header";
import { ROUTES } from "../../Services";
import { Prompt } from "react-router";
import { Block } from "@material-ui/icons";

const NewDesign = (props) => {
  const canvasRef = useRef();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.authReducer.data);

  const [selectedItem, setSelectedItem] = useState(null);
  const [styles, setStyles] = useState({});
  const [finishStatus, setfinishStatus] = useState(false);
  const [setIsBackButtonClicked, isBackButtonClicked] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [dimension, setDimension] = useState({
    height: 540,
    width: 960,
    title: "16:9",
  });
  const [display, setDisplay] = useState({
    first: "Block",
    second: "none",
  });

  const [exit, setExit] = useState(true);
  // const onBackButtonEvent = (e) => {
  //   e.preventDefault();
  //   if (!finishStatus) {
  //     if (window.confirm("Do you want to go back ?")) {
  //       setfinishStatus(true);
  //       // your logic
  //       props.history.push(ROUTES.Screen);
  //     } else {
  //       // props.history.push(props.location.pathname);
  //       // window.history.pushState(null, null, props.location.pathname);
  //       setfinishStatus(false);
  //     }
  //   }

  // };

  const onBackButtonEvent = (e) => {
    e.preventDefault();

    if (!isBackButtonClicked) {
      if (window.confirm("Do you want to save your changes")) {
        setIsBackButtonClicked(true);
        // Your custom logic to page transition, like react-router-dom history.push()
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setIsBackButtonClicked(false);
      }
    }
  };
  const alertUser = (e) => {
    e.preventDefault();

    e.returnValue = "";
  };
  useEffect(() => {
    // (async () => {
    //   if (props?.match?.params?.id) {
    //     let params = {
    //       userId: userId,
    //       templateId: props?.match?.params?.id,
    //     };
    //     await dispatch(TemplateAction.getUserTemplateById(params)).then(
    //       (res) => {
    //         setUserData(res.data[0].component);
    //       }
    //     );
    //   }
    // })();
    // props.history.push(props.location.pathname);
    // window.history.pushState(null, null, props.location.pathname);
    window.addEventListener("beforeunload", alertUser);
    // window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  useEffect(() => {}, [styles]);

  const canvasHandlers = {
    onAdd: (target) => {
      canvasRef.current.handler.select(target);
    },
    onImage: (url) => {
      canvasRef.current.handler.onImage(url);
    },
    onBgImage: (url) => {
      canvasRef.current.handler.onBgImage(url);
    },
    onSelect: (target) => {
      // const { selectedItem } = this.state;

      // canvasRef.current.setActiveObject(target)

      if (target && target.id) {
        setSelectedItem(target);
        return;
      }
      setSelectedItem(null);
      return;
      // if (target && target.id && target.id !== 'workarea' && target.type !== 'activeSelection') {
      // 	if (selectedItem && target.id === selectedItem.id) {
      // 		return;
      // 	}
      // 	this.canvasRef.handler.getObjects().forEach(obj => {
      // 		if (obj) {
      // 			this.canvasRef.handler.animationHandler.resetAnimation(obj, true);
      // 		}
      // 	});
      // 	this.setState({
      // 		selectedItem: target,
      // 	});
      // 	return;
      // }
    },
    onModified: (key, value, target) => {
      //  setStyles({...styles, [key]:value})
      // this.setState({ styleValues });
    },
    onChange: (selectedItem, changedValues, allValues) => {
      // const { editing } = this.state;
      // if (!editing) {
      // 	this.changeEditing(true);
      // }
      const changedKey = Object.keys(changedValues)[0];
      const changedValue = changedValues[changedKey];
      handler.onChangeStyles(changedKey, changedValue);

      // if (changedKey === "fontWeight") {
      //   canvasRef.current.handler.set(changedKey, changedValue);
      //   return;
      // }
      // if (changedKey === "fontStyle") {
      //   canvasRef.current.handler.set(
      //     changedKey,
      //     changedValue ? "italic" : "normal"
      //   );
      //   return;
      // }
      if (changedKey === "textAlign") {
        canvasRef.current.handler.set(changedKey, Object.keys(changedValue)[0]);
        return;
      }
      canvasRef.current.handler.set(changedKey, changedValue);
    },
    onSave: (Data, file, templateName) => {
      setExit(false);

      const form_Data = new FormData();

      form_Data.append("data", file);
      form_Data.append("userId", userId);
      form_Data.append("templateName", templateName);
      form_Data.append("json", Data);

      const params = { Data: Data };
      if (props?.location?.state?.id === undefined) {
        dispatch(TemplateAction.saveTemplate(form_Data)).then((res) => {
          props.history.push(ROUTES.MyDesign);
        });
      } else if (props.location && props?.location?.state?.id !== undefined) {
        form_Data.append("id", props?.location?.state?.id);

        dispatch(TemplateAction.updateTemplate(form_Data)).then((res) => {
          props.history.push(ROUTES.MyDesign);
        });
      }
      // }
      // }, 2000);
    },
  };

  const handler = {
    onChangeStyles: (key, value) => {
      // if (!this.state.editing) {
      // 	this.changeEditing(true);
      // }
      setStyles({ ...styles, [key]: value });
    },
  };
  const handleDimension = (e, type) => {
    if (type === "16:9") {
      setDimension({
        height: 540,
        width: 960,
        title: "16:9",
      });
    } else if (type === "4:3") {
      setDimension({
        height: 619,
        width: 743,
        title: "4:3",
      });
    }
    setDisplay({
      first: "none",
      second: "block",
    });
  };
  const handlView = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    let height = dimension.height;
    let width = dimension.width;
    let title = dimension.title;
    if (type === "Portrait") {
      setDimension({
        height: width,
        width: height,
        title: title == "16:9" ? "9:16" : "3:4",
      });
    } else if (type === "Landscape") {
      setDimension({
        height: height,
        width: width,
        title: title == "9:16" || "16:9" ? "16:9" : "4:3",
      });
    }
    setShowModal(false);
  };
  return (
    <div className="d-flex align-items-center newDesign wrapper ">
      <Prompt when={exit} message="Changes you made may not be saved" />
      <Modal
        // className={{display: }}
        // {...props}
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="screen-modal-1"
            id="contained-modal-title-vcenter"
            style={{ width: "100%", textAlign: "center" }}
          >
            Editor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="screen-modal-body">
          {/* {props?.match?.params?.id ? (
         
          ) : (
            <h4
              className="addscreenhead"
              style={{ lineHeight: 1.7, color: "#6b6550" }}
            >
              Choose the aspect ratio and get started.
            </h4>
          )} */}
          {props?.match?.params?.id ? (
            <div>
              <h4
                className="addscreenhead"
                style={{ lineHeight: 1.7, color: "#6b6550" }}
              >
                {" "}
                Get started by choosing a component from the
                <br />
                right side menu.
              </h4>

              <Button
                className="button31 addscreenbtn"
                onClick={() => setShowModal(false)}
              >
                Get Started
              </Button>
            </div>
          ) : (
            <div>
              <div style={{ display: display.first }}>
                <h4
                  className="addscreenhead"
                  style={{ lineHeight: 1.7, color: "#6b6550" }}
                >
                  Choose the aspect ratio and get started.
                </h4>
                <div
                  className="d-flex"
                  style={{ width: "100%", justifyContent: "space-around" }}
                >
                  <Button
                    className="editorbutton"
                    onClick={(e) => handleDimension(e, "16:9")}
                  >
                    16:9
                  </Button>

                  <Button
                    className="editorbutton"
                    onClick={(e) => handleDimension(e, "4:3")}
                  >
                    4:3
                  </Button>
                </div>
              </div>
              <div style={{ display: display.second }}>
                <h4
                  className="addscreenhead"
                  style={{ lineHeight: 1.7, color: "#6b6550" }}
                >
                  Choose the view mode of the editor.
                </h4>
                <div
                  className="d-flex mt-4"
                  style={{ width: "100%", justifyContent: "space-around" }}
                >
                  <Button
                    className="editorbutton"
                    onClick={(e) => handlView(e, "landscape")}
                  >
                    Landscape
                  </Button>

                  <Button
                    className="editorbutton"
                    onClick={(e) => handlView(e, "Portrait")}
                  >
                    Portrait
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* <Header
        title="Branding"
        // placeholder="Search Branding"
        buttonName="Save"
        handleChange={() => canvasRef.current.handler.saveCanvasImage()}
      /> */}

      {/* <div className="sideBar-wrapper">
        <ImageMapItems canvasRef={canvasRef} />
      </div> */}
      <div className="canvas-wrapper">
        <Canvas
          ref={canvasRef}
          minZoom={30}
          maxZoom={500}
          dimension={dimension}
          designNo={props?.match?.params?.id}
          state={props?.location?.state}
          onSelect={canvasHandlers.onSelect}
          onModified={canvasHandlers.onModified}
          onSave={canvasHandlers.onSave}
          handleExit={() => setExit(false)}

          // onAdd={addElement}
        />
      </div>
      <div className="canvas-properties">
        <ImageMapConfigurations
          canvasRef={canvasRef}
          onChange={canvasHandlers.onChange}
          selectedItem={selectedItem}
          designNo={props?.match?.params?.id}
          dimension={dimension}
          // onChangeAnimations={onChangeAnimations}
          onChangeStyles={handler.onChangeStyles}
          // onChangeDataSources={onChangeDataSources}
          // animations={animations}
          styles={styles}
          // dataSources={dataSources}
        />
      </div>
      {/* <div>
        <Button onClick={() => canvasRef.current.handler.saveCanvasImage()}>
          Save
        </Button>
      </div> */}
    </div>
  );
};

export default NewDesign;
