export const validateContactForm = (state) => {
  console.log("state in validateEditProfileForm", state);
  let isFormValid = true;
  let error = {};

  if (!state["fullName"]) {
    isFormValid = false;
    error["fullName"] = "This Field cannot be empty!";
  } else if (typeof state["fullName"] != "undefined") {
    if (!state["fullName"].match(/\S/)) {
      isFormValid = false;
      error["fullName"] = "This field should contain only letters.";
    }
  }
  if (!state["email"]) {
    isFormValid = false;
    error["email"] = "This field cannot be empty";
  } else if (typeof state["email"] !== "undefined") {
    let lastAtPos = state["email"].lastIndexOf("@");
    let lastDotPos = state["email"].lastIndexOf(".");

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        state["email"].indexOf("@@") == -1 &&
        lastDotPos > 2 &&
        state["email"].length - lastDotPos > 2
      )
    ) {
      isFormValid = false;
      error["email"] = "Email is not valid";
    }
  }
  if (!state["message"]) {
    isFormValid = false;
    error["message"] = "This Field cannot be empty!";
  }
  console.log("err", error, state);
  // setError(error);
  return {
    isFormValid,
    error,
  };
};
