import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./previewTemplate.css";
function PreviewTemplate(props) {
  return (
    <Modal
      {...props}
      size="lg"
      className="privewTemp"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <img src={props?.template?.url} alt="template" />
    </Modal>
  );
}
export default PreviewTemplate;
