import { ACTION_TYPE, APPLICATION_CONSTANTS } from "../../Services/index";
export const initialState = {
  data: [],
  DELETE: "",
  playlistData: [],
  add: "",
  codeScreens: [],
  code: "",
  screenById: {},
  screenChange: 0,
};

export const screenReducer = (state = initialState, action) => {
  console.log("action :>> ", action);
  switch (action.type) {
    case ACTION_TYPE.SET_CODE:
      return {
        ...state,
        code: action.payload,
      };

    case ACTION_TYPE.SCREEN_GET_BY_ID:
      return {
        ...state,
        screenById: action.payload.data.data,
      };
    // case ACTION_TYPE.SET_CODE_SCREEN:
    //   return {
    //     ...state,
    //     codeScreens: [...state.codeScreens, action.payload.data],
    //   };

    case ACTION_TYPE.SCREEN_GET:
      return {
        ...state,
        codeScreens: action.payload,
        screenChange: state.screenChange + 1,
      };

    default:
      return state;
  }
};
