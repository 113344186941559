import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyA99cT4XkATFlp2rlZ5WvY5RMCGHigu0cY",
  authDomain: "organic-justice-317019.firebaseapp.com",
  projectId: "organic-justice-317019",
  storageBucket: "organic-justice-317019.appspot.com",
  messagingSenderId: "89448359008",
  appId: "1:89448359008:web:ae0ba0c695fa5626624f05",
};
// var firebaseConfig = {
//   apiKey: "AIzaSyBG9sSQO-Fku2N4daFotLA4hsPcjFrPaCA",
//   authDomain: "signagetest-66f2a.firebaseapp.com",
//   projectId: "signagetest-66f2a",
//   storageBucket: "signagetest-66f2a.appspot.com",
//   messagingSenderId: "672671131677",
//   appId: "1:672671131677:web:4d7937079ee8a10238bef4",
//   measurementId: "G-VWFYB5CHHB",
//   databaseURL: "https://signagetest-66f2a-default-rtdb.firebaseio.com",
// };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const databaseRef = firebase.database().ref();
export const codesRef = databaseRef.child("codes");
export const mediaRef = databaseRef.child("media");
export const screenRef = databaseRef.child("screens");

export default firebase;
