import React, { useEffect } from "react";
import "./Template.css";
import SubNav from "../subNav/subNav";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import { Divider, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { TemplateAction } from "../../ReduxStore/Actions";
import HearderBar from "../../component/headerBar/Header";
import { Toast } from "react-bootstrap";
import WidgetModal from "../modal/Widget/widgetModal";
import { ROUTES } from "../../Services";

const Template = (props) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = React.useState("All");
  const [toast, setToast] = React.useState(false);
  const [isModal, showModal] = React.useState();
  const template = useSelector((state) => state.templateReducer);
  const { id } = useSelector((state) => state.authReducer.authData);
  const errorMessage = useSelector((state) => state.commonReducer.errorMessage);

  useEffect(() => {
    if (typeof id !== "undefined") {
      dispatch(TemplateAction.getAdminTemplate());
    }
  }, [id]);

  const changeSelectedTab = (tab) => {
    setSelectedTab(tab);
  };

  const isSeason = (user) => {
    if (user.category === selectedTab) {
      return user;
    }
    return false;
  };
  return (
    <div className="template">
      <div className="row">
        <div className="col-md-2 col-sm-2 col-lg-2">{/* <SideNav /> */}</div>
        <div className="col-md-12 col-lg-10 templateTabView">
          <div>
            <HearderBar
              title="Templates"
              display="none"
              searchd="none"
              placeholder=" Search Template"
            />
          </div>
          <Divider className="mt-4" />
          <div
            className="row mt-3 templateTabRemoveHeight"
            style={{ minHeight: "80vh" }}
          >
            <div className="col-md-3 col-sm-2 subnavparent">
              <SubNav
                selectedTab={selectedTab}
                changeSelectedTab={changeSelectedTab}
              />
            </div>
            <div className="templateStart col-md-9 col-sm-12">
              <Divider orientation="vertical" flexItem />

              <div className="template-body">
                <Toast
                  className="TemplateToast"
                  // show={errorStatus === false ? true : false}
                  show={toast}
                  onClose={() => setToast(false)}
                  delay={6000}
                  autohide
                >
                  <Toast.Header className="TemplateToastBody">
                    <strong className="mr-auto">{errorMessage}</strong>
                  </Toast.Header>
                  {/* <Toast.Body className="ContentToastBody">
              </Toast.Body> */}
                </Toast>
                {/* <input
                  className="template-search"
                  type="text"
                  placeholder="Search Template"
                /> */}
                <h5>
                  Showing result for{" "}
                  {selectedTab === "allTemplates" ? "All" : selectedTab}{" "}
                  Templates
                </h5>

                <Grid
                  className="data ml-2 tabViewJustify"
                  container
                  item
                  xs={12}
                  spacing={3}
                >
                  {(selectedTab === "All" || selectedTab === "allTemplates") &&
                    template?.data.map((user) => (
                      <div
                        className="card1"
                        // onClick={() => setTemplate(true)}
                      >
                        <img
                          src={user.background}
                          alt="John"
                          className="myTempImg"
                          // style={{ width: "100%" }}
                          onClick={() => {
                            {
                            }
                            user.type === "widget"
                              ? showModal(true)
                              : user.type === "design"
                              ? props.history.push({
                                  pathname: `/design/${user.id}`,

                                  state: {
                                    type: "admin",
                                    component: JSON.parse(user?.component),
                                  },
                                })
                              : props.history.push(`/template/edit/${user.id}`);
                          }}
                        />

                        {/* <div className="templateName"> {user.name}</div> */}
                        <div className="px-2 py-2 d-flex templateDesc">
                          <span> {user.name}</span>

                          {user.type === "widget" ? (
                            // <Link
                            //   to={{
                            //     pathname: `/template/edit/${user.id}`,
                            //     state: { url: user.url, width: 600, height: 400 },
                            //   }}
                            // >
                            <EditIcon
                              className="editicon7"
                              onClick={() => showModal(true)}
                            />
                          ) : user.type === "design" ? (
                            <EditIcon
                              onClick={() => {
                                props.history.push({
                                  pathname: `/design/${user.id}`,

                                  state: {
                                    type: "admin",
                                    component: JSON.parse(user?.component),
                                  },
                                });
                              }}
                              className="editicon7"
                            />
                          ) : (
                            <Link
                              to={{
                                pathname: `/template/edit/${user.id}`,
                                state: {
                                  url: user.url,
                                  width: 600,
                                  height: 400,
                                },
                              }}
                            >
                              <EditIcon className="editicon7" />
                            </Link>
                          )}
                        </div>
                      </div>
                    ))}

                  {selectedTab === "Season" &&
                    template?.data
                      .filter((user) => isSeason(user))
                      .map((user) => (
                        <div
                          className="card1"
                          // onClick={() => setTemplate(true)}
                        >
                          <img
                            src={user.background}
                            alt="John"
                            className="myTempImg"
                            // style={{ width: "100%" }}
                            onClick={() => {
                              // setTemp(user.url);
                              // setTemplate(true);
                              // setTempPath(`/template/edit/${user.id}`);
                              props.history.push(`/template/edit/${user.id}`);
                            }}
                          />
                          {/* <div className="templateName"> {user.name}</div> */}
                          <div className="px-2 py-2 d-flex templateDesc">
                            <span> {user.name}</span>
                            <Link
                              to={{
                                pathname: `/template/edit/${user.id}`,
                                state: {
                                  url: user.url,
                                  width: 600,
                                  height: 400,
                                },
                              }}
                            >
                              <EditIcon className="editicon7" />
                            </Link>
                          </div>
                        </div>
                      ))}

                  {/* {selectedTab === "MyTemplate" &&
                    template?.dataUser?.data.length === 0 && (
                      // <div className="noCard">No User Templates</div>
                    )} */}
                  {selectedTab === "Covid" &&
                    template?.data
                      .filter((user) => isSeason(user))
                      .map((user) => (
                        <div
                          className="card1"
                          // onClick={() => setTemplate(true)}
                        >
                          <img
                            src={user.background}
                            alt="John"
                            className="myTempImg"
                            // style={{ width: "100%" }}
                            onClick={() => {
                              // setTemp(user.url);
                              // setTemplate(true);
                              // setTempPath(`/template/edit/${user.id}`);
                              props.history.push(`/template/edit/${user.id}`);
                            }}
                          />
                          {/* <div className="templateName"> {user.name}</div> */}
                          <div className="px-2 py-2 d-flex templateDesc">
                            <span> {user.name}</span>
                            <Link
                              to={{
                                pathname: `/template/edit/${user.id}`,
                                state: {
                                  url: user.url,
                                  width: 600,
                                  height: 400,
                                },
                              }}
                            >
                              <EditIcon className="editicon7" />
                            </Link>
                          </div>
                        </div>
                      ))}
                  {selectedTab === "Lifestyle" &&
                    template?.data
                      .filter((user) => isSeason(user))
                      .map((user) => (
                        <div
                          className="card1"
                          // onClick={() => setTemplate(true)}
                        >
                          <img
                            src={user.background}
                            alt="John"
                            className="myTempImg"
                            // style={{ width: "100%" }}
                            onClick={() => {
                              // setTemp(user.url);
                              // setTemplate(true);
                              // setTempPath(`/template/edit/${user.id}`);
                              props.history.push(`/template/edit/${user.id}`);
                            }}
                          />
                          {/* <div className="templateName"> {user.name}</div> */}
                          <div className="px-2 py-2 d-flex templateDesc">
                            <span> {user.name}</span>
                            <Link
                              to={{
                                pathname: `/template/edit/${user.id}`,
                                state: {
                                  url: user.url,
                                  width: 600,
                                  height: 400,
                                },
                              }}
                            >
                              <EditIcon className="editicon7" />
                            </Link>
                          </div>
                        </div>
                      ))}
                  {selectedTab == "Education" &&
                    template?.data
                      .filter((user) => isSeason(user))
                      .map((user) => (
                        <div
                          className="card1"
                          // onClick={() => setTemplate(true)}
                        >
                          <img
                            src={user.background}
                            alt="John"
                            className="myTempImg"
                            // style={{ width: "100%" }}
                            onClick={() => {
                              // setTemp(user.url);
                              // setTemplate(true);
                              // setTempPath(`/template/edit/${user.id}`);
                              props.history.push(`/template/edit/${user.id}`);
                            }}
                          />
                          {/* <div className="templateName"> {user.name}</div> */}
                          <div className="px-2 py-2 d-flex templateDesc">
                            <span> {user.name}</span>
                            <Link
                              to={{
                                pathname: `/template/edit/${user.id}`,
                                state: {
                                  url: user.url,
                                  width: 600,
                                  height: 400,
                                },
                              }}
                            >
                              <EditIcon className="editicon7" />
                            </Link>
                          </div>
                        </div>
                      ))}

                  {selectedTab == "Widgets" &&
                    template?.data
                      .filter((user) => isSeason(user))
                      .map((user) => (
                        <div
                          className="card1"
                          // onClick={() => setTemplate(true)}
                        >
                          <img
                            src={user.background}
                            alt="John"
                            className="myTempImg"
                            // style={{ width: "100%" }}
                            onClick={() => {
                              showModal(true);
                            }}
                          />
                          {/* <div className="templateName"> {user.name}</div> */}
                          <div className="px-2 py-2 d-flex templateDesc">
                            <span> {user.name}</span>
                            <EditIcon
                              className="editicon7"
                              onClick={() => showModal(true)}
                            />
                          </div>
                        </div>
                      ))}
                  {selectedTab == "Design" &&
                    template?.data
                      .filter((user) => isSeason(user))
                      .map((user) => (
                        <div
                          className="card1"
                          // onClick={() => setTemplate(true)}
                        >
                          <img
                            src={user.background}
                            alt="John"
                            className="myTempImg"
                            onClick={() => {
                              props.history.push({
                                pathname: `/design/${user.id}`,

                                state: {
                                  component: JSON.parse(user?.component),

                                  type: "admin",
                                },
                              });
                            }}
                          />
                          {/* <div className="templateName"> {user.name}</div> */}
                          <div className="px-2 py-2 d-flex templateDesc">
                            <span> {user.name}</span>

                            <EditIcon
                              className="editicon7"
                              onClick={() => {
                                props.history.push({
                                  pathname: `/design/${user.id}`,

                                  state: {
                                    type: "admin",
                                    component: JSON.parse(user?.component),
                                  },
                                });
                              }}
                              // onClick={() => {

                              //   props.history.push(`/design/${user.id}`);
                              // }}
                              // onClick={() => showModal(true)}
                            />
                          </div>
                        </div>
                      ))}
                </Grid>

                <WidgetModal
                  show={isModal}
                  onHide={() => showModal(false)}
                  onSave={() => props.history.push(ROUTES.MyDesign)}
                  // style={{ height: 88 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Template;
