import React from "react";
import { Button, Modal } from "react-bootstrap";
import BackupIcon from "@material-ui/icons/Backup";
import "./browseContent.css";
import { useDispatch, useSelector } from "react-redux";
import { ContentAction } from "../../ReduxStore/Actions/index";
import Loader from "../../Services/Util/loader";

import axios from "axios";
const ModalContent = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.commonReducer.loading);
  const data = useSelector((state) => state.authReducer.data);
  const handleUpload = async (e) => {
    const form_Data = new FormData();
    console.log("e.target.files[0]", e.target.files[0].name);
    const { name, type } = e.target.files[0];
    // form_Data.append("data", e.target.files[0]);
    // form_Data.append("userId", data);
    // let params = {
    //   name,
    //   type,
    // };
    // dispatch(ContentAction.postContent(params))
    axios
      .get(
        `https://bzda2ab95f.execute-api.us-east-2.amazonaws.com/dev/s3upload?object_key=content/${
          type.split("/")[1]
        }/${name}&bucket=savvsignagefiles`
      )
      .then((resp) => {
        console.log("resp :>> ", resp.data.presigned_url);

        var options = {
          headers: {
            "Content-Type": type,
          },
        };
        axios
          .put(resp.data.presigned_url, e.target.files[0], options)
          .then((resps, err) => {
            if (err) console.log("err :>> ", err);
            console.log("resps :>> ", resps);

            let params = {
              url: `https://savvsignagefiles.s3.us-east-2.amazonaws.com/${resp.data.key}`,
              type,
              name,
              userId: data,
            };

            dispatch(ContentAction.postContent(params)).then(() =>
              dispatch(ContentAction.getContent({ userId: data }))
            );
          });
      });
    // await dispatch(ContentAction.getContent({ userId: data }));

    props.onHide();
  };
  console.log(loading, "loading");
  return (
    <Modal
      {...props}
      size="lg"
      className="uploadontntmdl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          className="filebrowse content-modal0 text-center mt-2"
          id="contained-modal-title-vcenter"
        >
          {/* Drag and drop your files or browse */}
          Browse your File
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="content-modal-body col-md-12 text-center">
        <div className="content-body-0 text-center mt-5 ">
          <BackupIcon id="iconContntUpload" />
          <br />
          <input
            className="content-text"
            id="files"
            type="file"
            onChange={(e) => handleUpload(e)}
          />
          <label className=" labaelcontent text-center mt-4" for="files">
            {loading ? <Loader loading={loading} /> : "Browse"}
          </label>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ModalContent;
