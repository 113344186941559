import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ScreenAction } from "../../../ReduxStore/Actions/index";
import { ROUTES } from "../../../Services";
import "./selectScreen.css";

const SelectScreen = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const screenList = useSelector((state) => state.screenReducer.codeScreens);
  const [screenData, setScreenData] = useState();
  console.log("props", props);
  useEffect(() => {
    if (props?.screenModalData?.userId !== undefined)
      dispatch(
        ScreenAction.getScreen({ userId: props?.screenModalData?.userId })
      );
  }, [props?.screenModalData]);

  const handleChange = (e) => {
    setScreenData({
      id: e.target.value.split("+")[0],
      firebaseId: e.target.value.split("+")[1],
    });
  };

  const handleDone = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let params = {
      type: props.screenModalData.type,
      typeId: props.screenModalData.typeId,
      firebase_screen_id: screenData?.firebaseId,
      screenId: screenData?.id,
    };

    const status = await dispatch(ScreenAction.editScreen(params));
    if (status) history.push(ROUTES.Screen);
    // if (status) props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="col-md-12 " closeButton>
        <Modal.Title
          // className="Addscreen-modal-1"
          id="contained-modal-title-vcenter"
        >
          Select Screen
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="screenSelect">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row align-items-baseline justify-content-center mb-3">
            <label>Type* </label>
            <select
              // name="Type"
              className="selectScreeninput ml-3"
              // placeholder="Type"
              onChange={(e) => handleChange(e)}
            >
              <option value="" disabled selected>
                Type
              </option>
              {screenList.length > 0 &&
                screenList?.map((screen) => (
                  <option
                    id={screen.id}
                    value={screen.id + "+" + screen.firebase_screen_id}
                  >
                    {screen.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button className="selectScreenButton" onClick={handleDone}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default SelectScreen;
