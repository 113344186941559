import { ACTION_TYPE } from "../../Services";

export const initialState = {
  list: {},
  scheduleData: {},
};
export const scheduleReducer = (state = initialState, action) => {
  // console.log("action",action)
  switch (action.type) {
    case ACTION_TYPE.SCHEDULE_GET:
      return {
        ...state,
        list: action.payload.data,
      };
    case ACTION_TYPE.SCHEDULE_GET_BY_ID:
      return {
        ...state,
        scheduleData: action.payload.data,
      };
    default:
      return state;
  }
};
